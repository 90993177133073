import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  Button,
  FormControl,
  Box,
  TextField,
} from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
// import { Visibility, VisibilityOff } from "@mui/icons-material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LandImage from "../asset/loginMainImage.svg";
import configParam from "../Config";
import gqlQueries from "../Queries/Queries";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { user, menuName } from "../../src/recoil/atom";
import Logo from "../../src/asset/Logo.png";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import decode from "jwt-decode";
import CircularProgress from '@mui/material/CircularProgress';


const ApiURL = configParam.API_URL;

console.log("ApiURLApiURL", ApiURL)
const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [menu, setMenu] = useRecoilState(menuName);
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  const [, setLoading] = useState(false);
  const [, setUser] = useRecoilState(user);
  const navigate = useNavigate();
  const TokenValidate = configParam.CHECK_TOKEN;
  const [loader, setLoader] = useState(true);


  const goToReset = () => {
    navigate("/ResetPassword");
  };
  useEffect(() => {
    let url = window.location.href;
    const Response = decodeURIComponent(url);
    const myArray = Response.split("?");
    if (myArray.length > 1) {
      const queryParameters = new URLSearchParams(window.location.search)
      const UserDetails = queryParameters.get("UserData");
      const currentUserDetails = JSON.parse(UserDetails)
      if (currentUserDetails && Object.keys(currentUserDetails).length > 0 && currentUserDetails.id) {
        localStorage.setItem("user", JSON.stringify(currentUserDetails));
        console.log("currentUserDetails", currentUserDetails);
        setUser({
          sgid: currentUserDetails.sgid,
          name: currentUserDetails.first_name,
          lastName: currentUserDetails.last_name,
          id: currentUserDetails.id,
          zoneId: currentUserDetails.zone_id,
          roleId: currentUserDetails.role_id,
          role: currentUserDetails.role,
          zone: currentUserDetails.name,
        });
        localStorage.setItem("userId", currentUserDetails.id);
        localStorage.setItem("UserID", currentUserDetails.sgid);
        localStorage.setItem("zoneName", currentUserDetails.name);

        navigate(`/home`);
        setMenu("Trial Visits")
        setLoader(false);
      } else {
        localStorage.setItem("userDetails", "");
        localStorage.setItem("userId", "");
        setError(err ? err : "Access denied");
        setLoader(false);
      }
    }
  }, []);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const onHandleSSO = () => {
    const url = configParam.RUN_SSO_URL();
    console.log("url", url)
    window.location.href = url;
  }
  const queryParameters = new URLSearchParams(window.location.search)
  const err = queryParameters.get("err")
  const accessToken = queryParameters.get("UserData");
  return (
    <React.Fragment>
      {
        accessToken && !error ? (
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            background: "#0003",
          }}>
            <CircularProgress />
          </div>
        ) : (
          <Grid container style={{ overflowY: "hidden", fontWeight: 600 }}>
            <Grid item md={12} xl={12} sm={12}>
              <img
                src={LandImage}
                alt=""
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  left: 0,
                  top: 0
                }}
              />

              <div style={{
                width: "350px",
                backgroundColor: "#fff",
                fontSize: 18,
                color: "#1A1B1F",
                position: "absolute",
                bottom: "60px",
                right: "0",
                border: "none",
                margin: "auto",
                left: "0",
                top: 0,
                height: "300px",
                borderRadius: "10px",
                padding: "24px",
                boxShadow: "2px 7px 17px #313131",
                textAlign: "center"
              }}>
                <img src={Logo} style={{
                  width: "80%", marginTop: "20px"
                }} />
                <LoadingButton
                  // loading={loader}
                  loadingPosition="start"
                  variant="outlined"
                  style={{
                    width: "100%",
                    backgroundColor: "#fcc900",
                    fontSize: 16,
                    color: "#1A1B1F",
                    border: "none",
                    marginTop: "0px"
                  }}
                  size="small"
                  // onClick={handleLog}
                  onClick={onHandleSSO}
                >
                  Click Here to Login
                </LoadingButton>
                <Typography
                  variant="subtitle1"
                  style={{ color: "red", marginTop: "15px", fontSize: "14px", textAlign: "center" }}
                >
                  {error}
                </Typography>
              </div>

              <Typography
                variant="body1"
                style={{ position: "absolute", width: "100%", textAlign: "center", bottom: "20px", fontSize: 14 }}
              >
                Copyright © 2022 Saint-Gobain
              </Typography>
            </Grid>

          </Grid>
        )}

    </React.Fragment>
  );
};

export default Login;
