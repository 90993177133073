import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Box,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import ThumbDownRoundedIcon from "@mui/icons-material/ThumbDownRounded";
import DownloadingIcon from "@mui/icons-material/Downloading";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import UserAnalyticsChart from "./UserAnalyticsChart";
import axios from "axios";
import Logo from "../asset/Logo.png";
import emptyLogo from "../asset/empty-result.jpg";
import { styled } from "@mui/system";
import configParam from "../Config";
import { useReactToPrint } from "react-to-print";
import { Backpack } from "@mui/icons-material";
const Root = styled("div")({
  "@media print": {
    "& .printHeader": {
      display: "flex !important",
    },
  },
});
const UserAnalytics = (props) => {
  const [error, setError] = useState(null);
  const ApiURL = configParam.API_URL;
  const [maxWidth, setMaxWidth] = useState("lg");
  const [analyticsdata, setAnalyticsdata] = useState([]);
  const [statusCount, setStatusCount] = useState({
    Approved: 0,
    ReSubmit: 0,
    Rejected: 0,
    "Awaiting Approval": 0,
  });
  const [statusComment, setStatusComment] = useState({ Success: 0, Fail: 0 });
  const componentRef = useRef();
  const contentToPrint = useRef(null);

  // React-to-print hook for handling PDF printing
  const handlePrint = useReactToPrint({
    documentTitle: "User Analytics Report",
    onBeforePrint: () => console.log("Preparing to print..."),
    onAfterPrint: () => console.log("Printing completed."),
    removeAfterPrint: true,
    content: () => componentRef.current,
  });

  const handleSuccessFail = async () => {
    let url = ApiURL + "successFail_analytics/" + props.data.id;
    try {
      const res = await axios.get(url);
      if (res.data.length > 0) {
        const statusComments = res.data.reduce(
          (acc, item) => {
            if (item.status === "Success") {
              acc.Success += 1;
            } else if (item.status === "Fail") {
              acc.Fail += 1;
            }
            return acc;
          },
          { Success: 0, Fail: 0 }
        );
        setStatusComment(statusComments);
      } else {
        setStatusComment({ Success: 0, Fail: 0 });
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError("No data found for the specified parameters");
      } else {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data. Please try again later.");
      }
    }
  };

  const fetchData = async () => {
    // if (props.open === true) {
    const url = `${ApiURL}user_analytics/${props.data.id}`;
    try {
      const res = await axios.get(url);
      if (res.data.length > 0) {
        setAnalyticsdata(res.data);
        const statusCounts = res.data.reduce(
          (acc, item) => {
            const { submission_status } = item;
            if (submission_status in acc) {
              acc[submission_status]++;
            } else {
              acc[submission_status] = 1;
            }
            return acc;
          },
          {
            Approved: 0,
            Resubmitted: 0,
            Rejected: 0,
            "Awaiting Approval": 0,
          }
        );
        setStatusCount(statusCounts);
        // setStatusComment(statusComments);
      } else {
        setAnalyticsdata([]);
        setStatusCount({
          Approved: 0,
          Resubmitted: 0,
          Rejected: 0,
          "Awaiting Approval": 0,
        });
        setStatusComment({ Success: 0, Fail: 0 });
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError("No data found for the specified parameters");
      } else {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data. Please try again later.");
      }
    }
  };

  useEffect(() => {
    setError(null);
    fetchData();
    handleSuccessFail();
  }, [ props.data.id, ]);

  return (
    <React.Fragment>
      {error ? (
        <Dialog
          onClose={props.handleClose}
          aria-labelledby="customized-dialog-title"
          open={props.open}
          fullWidth={true}
          maxWidth={"xs"}
        >
          <Box sx={{ textAlign: "center" }}>
            <img
              src={emptyLogo}
              style={{ maxWidth: "100%", maxHeight: "220px" }}
              alt="no data"
            />
            <Typography
              variant="h3"
              sx={{
                fontSize: "22px",
                color: "#f4a019",
                fontWeight: "bold",
                marginBottom: "16px",
              }}
            >
              No Data Found
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                marginBottom: "16px",
                color: "#999",
              }}
            >
              We cannot find Trial Data
            </Typography>
          </Box>
        </Dialog>
      ) : (
        <Dialog
          onClose={props.handleClose}
          aria-labelledby="customized-dialog-title"
          open={props.open}
          fullWidth={true}
          maxWidth={maxWidth}
        >
          <DialogTitle id="user-analytics">
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              User Analytics
            </Typography>
          </DialogTitle>
          <DialogContent ref={componentRef}>
            <Root>
              <Grid
                container
                style={{ marginBottom: 20, display: "none" }}
                className="printHeader"
              >
                <Grid item xs={2}>
                  <img src={Logo} alt="Logo" style={{ width: 115 }} />
                </Grid>
                <Grid
                  item
                  xs={8}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // width: "100%",
                  }}
                >
                  <Typography
                    variant="h1"
                    style={{
                      textTransform: "uppercase",
                      textAlign: "center",
                      fontWeight: "bold",
                      maxWidth: "700px",
                      fontSize: "24px",
                    }}
                  >
                    USER TRIAL DATA
                  </Typography>
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
              <Grid
                container
                className="userList"
                rowSpacing={1}
                marginBottom={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12} md={3} sm={6}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "600",
                      marginBottom: "5px",
                      fontSize: "12px",
                    }}
                  >
                    SG ID
                  </Typography>
                  <Typography variant="body1">{props.data.sgid}</Typography>
                </Grid>
                <Grid item xs={12} md={3} sm={6}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "600",
                      marginBottom: "5px",
                      fontSize: "12px",
                    }}
                  >
                    User Name
                  </Typography>
                  <Typography variant="body1">
                    {props.data.first_name} {props.data.last_name}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={3} sm={6}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "600",
                      marginBottom: "5px",
                      fontSize: "12px",
                    }}
                  >
                    Zone Name
                  </Typography>
                  <Typography variant="body1">{props.data.name}</Typography>
                </Grid>
                <Grid item xs={12} md={3} sm={6}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "600",
                      marginBottom: "5px",
                      fontSize: "12px",
                    }}
                  >
                    Role
                  </Typography>
                  <Typography variant="body1">{props.data.role}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} marginBottom={3}>
                <Grid item xs={12} md={3} sm={6}>
                  <Box
                    display="flex"
                    sx={{
                      alignItems: "center",
                      padding: "16px",
                      borderRadius: "10px",
                      backgroundImage:
                        "linear-gradient(90deg, rgba(39,174,96,1) 0%, rgba(39,174,96,1) 48%, rgba(69,185,118,1) 74%, rgba(118,203,154,1) 89%, rgba(164,220,188,1) 100%)",
                    }}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box
                      sx={{
                        background: "#ffffff4d",
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ThumbUpAltRoundedIcon sx={{ color: "#fff" }} />
                    </Box>
                    <Box width={"calc(100% - 80px)"}>
                      <Typography
                        variant="h3"
                        sx={{
                          color: "#fff",
                          fontWeight: "600",
                          marginBottom: "5px",
                          fontSize: "21px",
                        }}
                      >
                        {statusCount.Approved}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#fff",
                          marginBottom: "5px",
                          fontSize: "12px",
                        }}
                      >
                        Approved
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3} sm={6}>
                  <Box
                    display="flex"
                    sx={{
                      alignItems: "center",
                      padding: "16px",
                      borderRadius: "10px",
                      backgroundImage:
                        "linear-gradient(90deg, rgba(247,95,95,1) 0%, rgba(247,95,95,1) 46%, rgba(250,154,154,1) 88%, rgba(252,194,194,1) 100%, rgba(253,214,214,1) 100%, rgba(254,228,228,1) 100%)",
                    }}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box
                      sx={{
                        background: "#ffffff4d",
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ThumbDownRoundedIcon sx={{ color: "#fff" }} />
                    </Box>
                    <Box width={"calc(100% - 80px)"}>
                      <Typography
                        variant="h3"
                        sx={{
                          color: "#fff",
                          fontWeight: "600",
                          marginBottom: "5px",
                          fontSize: "21px",
                        }}
                      >
                        {statusCount.Rejected}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#fff",
                          marginBottom: "5px",
                          fontSize: "12px",
                        }}
                      >
                        Rejects
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3} sm={6}>
                  <Box
                    display="flex"
                    sx={{
                      alignItems: "center",
                      padding: "16px",
                      borderRadius: "10px",
                      backgroundImage:
                        "linear-gradient(90deg, rgba(246,143,82,1) 47%, rgba(247,154,98,1) 65%, rgba(248,168,120,1) 83%, rgba(250,199,168,1) 100%)",
                    }}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box
                      sx={{
                        background: "#ffffff4d",
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <DownloadingIcon sx={{ color: "#fff" }} />
                    </Box>
                    <Box width={"calc(100% - 80px)"}>
                      <Typography
                        variant="h3"
                        sx={{
                          color: "#fff",
                          fontWeight: "600",
                          marginBottom: "5px",
                          fontSize: "21px",
                        }}
                      >
                        {statusCount["Awaiting Approval"]}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#fff",
                          marginBottom: "5px",
                          fontSize: "12px",
                        }}
                      >
                        Pendings
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3} sm={6}>
                  <Box
                    display="flex"
                    sx={{
                      alignItems: "center",
                      padding: "16px",
                      borderRadius: "10px",
                      backgroundImage:
                        "linear-gradient(90deg, rgba(55,94,202,1) 0%, rgba(75,110,207,1) 65%, rgba(82,116,209,1) 71%, rgba(109,137,216,1) 84%, rgba(140,162,224,1) 100%)",
                    }}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box
                      sx={{
                        background: "#ffffff4d",
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <AutorenewIcon sx={{ color: "#fff" }} />
                    </Box>
                    <Box width={"calc(100% - 80px)"}>
                      <Typography
                        variant="h3"
                        sx={{
                          color: "#fff",
                          fontWeight: "600",
                          marginBottom: "5px",
                          fontSize: "21px",
                        }}
                      >
                        {statusCount.ReSubmit}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#fff",
                          marginBottom: "5px",
                          fontSize: "12px",
                        }}
                      >
                        Resubmit
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <UserAnalyticsChart statusComment={statusComment} props={props} />
            </Root>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              style={{
                marginBottom: "10px",
                backgroundColor: "#FFC400",
                color: "#1A1B1F",
                marginRight: "16px",
              }}
              onClick={() => {
                handlePrint(null, () => contentToPrint.current);
              }}
            >
              Save PDF
            </Button>
            <Button
              variant="outlined"
              sx={{
                marginBottom: "10px",
              }}
              onClick={props.handleClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default UserAnalytics;
