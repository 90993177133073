import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { FormControl, Grid, Typography } from "@mui/material";
import TrialDate from "../input/TrialDate";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  trial_details,
  visitType,
  selectedVisitObj,
  error,
  client_details,
  concrete_mixdetails,
  supporting_info,
  comments,
  trial_results,
  Admixture_Composition
} from "../recoil/atom";
import axios from "axios";
import configParam from "../Config";
import gqlQueries from "../Queries/Queries";
import { getConvertedJSON } from "../../src/recoil/Selector";
import Selectautocomplete from "../input/Selectautocomplete";
const TrialDetails = (props) => {
  const [trDet, setTrDet] = useRecoilState(trial_details);
  const [labTypes, setlabTypes] = useState([]);
  const [users, setusers] = useState([]);
  const [visitTypes] = useRecoilState(visitType);
  const [clientDet, setclientTrDet] = useRecoilState(client_details);
  const [selectVisitData, setSelectVisitData] =
    useRecoilState(selectedVisitObj);
  const [, setcongreteDet] = useRecoilState(concrete_mixdetails);
  const [, setSupInfo] = useRecoilState(supporting_info);
  const [, setComment] = useRecoilState(comments);
  const [, setTrialRes] = useRecoilState(trial_results);
  const [, setAdmixture] = useRecoilState(Admixture_Composition);
  const [, setComments] = useRecoilState(comments);
  const [stepperError] = useRecoilState(error);
  const [enabled,] = useState(false);
  const trialApprovelData = localStorage.getItem("TrialApprovelData");
  const SavedTrialId = localStorage.getItem("SavedTrialId");
  const SavedTrialDetailData = localStorage.getItem("SavedTrialDetail");
  const SavedTrialDetail = JSON.parse(SavedTrialDetailData);
  const trialData = localStorage.getItem("ChrysoVisitData");
  const ApiURL = configParam.API_URL;
  let data =
    visitTypes === "1"
      ? trialData
        ? JSON.parse(trialData)
        : {}
      : trialApprovelData ?
        JSON.parse(trialApprovelData)
        : {}
    ;
  const sgid = localStorage.getItem("UserID");
  const VisitID = localStorage.getItem("DbVisitID");
  const getItemNo = configParam.getVisitedItem;
  const getSavedVisitNo = configParam.getSavedTrial;


  const convertedJSON = useRecoilValue(getConvertedJSON);
  useEffect(() => {
    getLabType();
    getUserDetails();
    if (visitTypes === "1") {
      let tempConvertedJSON = convertedJSON;
      const rowIndex = getItemNo();
      if (data[sgid]) {
        if (data[sgid][rowIndex ? rowIndex : 0]) {
          if (data[sgid][rowIndex ? rowIndex : 0].TrialDetails) {
            if (
              !data[sgid][rowIndex ? rowIndex : 0].TrialDetails.trial_number
            ) {
              let tmpObj = {
                ...data[sgid][rowIndex ? rowIndex : 0].TrialDetails,
              };
              tmpObj["trial_number"] = localStorage.getItem("trialNo");
              tmpObj["trial_date"] = new Date();

              setTrDet(tmpObj);
            } else {
              setTrDet(data[sgid][rowIndex ? rowIndex : 0].TrialDetails);
            }
          }
        } else {
          setTrDet(tempConvertedJSON.TrialDetails);
        }
        setLocalStorageData();
      } else {
        setTrDet(tempConvertedJSON.TrialDetails);
      }
    }
    else {
      getEditDetails(VisitID);
    }

  }, []);

  useEffect(() => {
    if (visitTypes === "2") {
      if (selectVisitData && Object.keys(selectVisitData).length > 0) {
        let tempConvertedJSON = convertedJSON;
        let data = {};
        const tempObj = JSON.parse(trialApprovelData);
        if (tempObj && tempObj[sgid]) {
          if (tempObj[sgid][SavedTrialId ? SavedTrialId : 0]) {
            let obj = {
              [SavedTrialId]: {
                TrialDetails: tempConvertedJSON.TrialDetails,
                ClientDetails: tempConvertedJSON.ClientDetails,
                ConcreteDetails: tempConvertedJSON.ConcreteDetails,
                supInfo: tempConvertedJSON.supInfo,
                Admixture: tempConvertedJSON.Admixture,
                TrialResult: tempConvertedJSON.TrialResult,
                comments: tempConvertedJSON.comments,
              },
            };
            let merged = { ...tempObj[sgid], ...obj };
            data[sgid] = merged;
          } else {
            if (SavedTrialId && SavedTrialId > 0) {
              let obj = {
                [SavedTrialId]: {
                  TrialDetails: tempConvertedJSON.TrialDetails,
                  ClientDetails: tempConvertedJSON.ClientDetails,
                  ConcreteDetails: tempConvertedJSON.ConcreteDetails,
                  supInfo: tempConvertedJSON.supInfo,
                  Admixture: tempConvertedJSON.Admixture,
                  TrialResult: tempConvertedJSON.TrialResult,
                  comments: tempConvertedJSON.comments,
                },
              };
              let merged = { ...tempObj[sgid], ...obj };
              data[sgid] = merged;
            } else {
              data[sgid] = {
                0: {
                  TrialDetails: tempConvertedJSON.TrialDetails,
                  ClientDetails: tempConvertedJSON.ClientDetails,
                  ConcreteDetails: tempConvertedJSON.ConcreteDetails,
                  supInfo: tempConvertedJSON.supInfo,
                  Admixture: tempConvertedJSON.Admixture,
                  TrialResult: tempConvertedJSON.TrialResult,
                  comments: tempConvertedJSON.comments,
                },
              };
            }
          }
        } else {
          data[sgid] = {
            [SavedTrialId]: {
              TrialDetails: tempConvertedJSON.TrialDetails,
              ClientDetails: tempConvertedJSON.ClientDetails,
              ConcreteDetails: tempConvertedJSON.ConcreteDetails,
              supInfo: tempConvertedJSON.supInfo,
              Admixture: tempConvertedJSON.Admixture,
              TrialResult: tempConvertedJSON.TrialResult,
              comments: tempConvertedJSON.comments,
            },
          };
        }
        localStorage.setItem("TrialApprovelData", JSON.stringify(data));
        setTrDet(tempConvertedJSON.TrialDetails);
        setclientTrDet(tempConvertedJSON.ClientDetails);
        setcongreteDet(tempConvertedJSON.ConcreteDetails);
        setSupInfo(tempConvertedJSON.supInfo);
        setAdmixture(tempConvertedJSON.Admixture);
        setTrialRes(tempConvertedJSON.TrialResult);
        setComments(tempConvertedJSON.comments);
      }
    }
  }, [selectVisitData]);
  // const getReplaceValue = (id) => {
  //   configParam
  //     .RUN_GQL_API(gqlQueries.REPLACE_VALUE, { trialNumber: id })
  //     .then((data) => {
  //       if (data.chryso_trial_details[0].trials.length > 0) {
  //         setSelectVisitData(data.chryso_trial_details[0].trials[0]);
  //       }
  //       // navigate("/NewVisit");
  //     });
  // };
  const getEditDetails = (id) => {
    let url = ApiURL + "getTrialDetails/" + id;
    axios
      .get(url)
      .then((res) => {
        if (res.data.length > 0) {
          setSelectVisitData(res.data[0]);
        } else {
          setSelectVisitData([]);

        }
      });
    // configParam
    //   .RUN_GQL_API(gqlQueries.GET_SELECTED_VISIT_EDIT_DATA, { id: id })
    //   .then((data) => {
    //     setSelectVisitData(data.chryso_trials[0]);
    //     // navigate("/NewVisit");
    //   });
  };
  //LabType
  const getLabType = () => {
    let url = ApiURL + 'get_lab_type';
    axios.post(url).then((res) => {
      if (res.data.length > 0) {

        setlabTypes(res.data);
      }
    });
    // configParam.RUN_GQL_API(gqlQueries.GET_LAB_TYPE, {}).then((data) => {
    //   setlabTypes(data.chryso_lab_type);
    // });
  };

  //TechPersonName
  const getUserDetails = () => {
    let url = ApiURL + 'get_user_details';
    axios.get(url).then((res) => {
      if (res.data.length > 0) {
        const newArr = res.data.map((z) => {
          let obj = { ...z };
          const fname = z.first_name + " " + z.last_name;
          obj["fullname"] = fname;
          return obj;
        });
        setusers(newArr);
      } else {
        setusers([]);
      }
    });
  };

  const updateTempObj = (key, value) => {
    let tmpObj = { ...trDet };
    tmpObj[key] = value;
    setTrDet(tmpObj);
  };

  useEffect(() => {
    setLocalStorageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trDet]);
  const setLocalStorageData = async () => {
    let val = data;
    let rowIndex;
    if (visitTypes === "1") {
      rowIndex = getItemNo();
    } else {
      rowIndex = getSavedVisitNo();
    }

    if (val[sgid]) {
      if (val[sgid][rowIndex ? rowIndex : 0]) {

        let obj = { TrialDetails: JSON.parse(JSON.stringify(trDet)) };
        let merged = { ...val[sgid][rowIndex ? rowIndex : 0], ...obj };
        val[sgid][rowIndex] = merged;
        setDataVlaue(val);
      } else {
        if (rowIndex && rowIndex > 0) {
          val[sgid][rowIndex] = { TrialDetails: JSON.parse(JSON.stringify(trDet)) };
          // let merged = { ...data[sgid][rowIndex ? rowIndex : 0], ...obj };
          // data[sgid][rowIndex] = merged;
          setDataVlaue(val);

        } else {
          val[sgid] = { 0: { TrialDetails: JSON.parse(JSON.stringify(trDet)) } };
          setDataVlaue(val);
        }
      }
    } else {
      val[sgid] = { 0: { TrialDetails: JSON.parse(JSON.stringify(trDet)) } };
      setDataVlaue(val);
    }

  }
  const setDataVlaue = (val) => {
    if (visitTypes === "1") {
      localStorage.setItem("ChrysoVisitData", JSON.stringify(val));
    } else {
      localStorage.setItem("TrialApprovelData", JSON.stringify(val));

    }
  }
  const dateOnchange = (newValue) => {
    updateTempObj("trial_date", newValue);
  };

  const changeTrDet = (e) => {
    updateTempObj(e.target.name, e.target.value);
  };

  return (
    <Grid container rowSpacing={1} style={{ marginTop: 25 }}>
      <Grid container rowSpacing={1} spacing={{
        xs: 0,
        sm: 2,
        lg: 5,
        xl: 12
      }}>

        <Grid item xl={3} md={4} sm={12} xs={12}>
          <Typography
            variant="subtitle1"
            gutterBottom
            title="TrialNo"
            style={{ fontSize: 12, marginBottom: "5px", fontWeight: 600 }}
          >
            Trial No *
          </Typography>
          <TextField
            id="outlined-multiline-static"
            name="trial_number"
            style={{ width: "100%" }}
            onChange={changeTrDet}
            disabled={true}
            size="small"
            defaultValue={
              trDet.trial_number
                ? trDet.trial_number
                : localStorage.getItem("trialNo")

            }
          />
        </Grid>
        <Grid item xl={3} md={4} sm={12} xs={12}>
          <FormControl size="small" style={{ width: "100%" }}>
            <TrialDate
              label="Date of Trial *"
              name="trial_date"
              value={trDet.trial_date ? trDet.trial_date : new Date()}
              onChange={dateOnchange}
              error={stepperError && !trDet.trial_date ? true : false}
              enabled={enabled}
              isDate={true}
              maxLimit={new Date()}
            />
          </FormControl>
        </Grid>

        <Grid item xl={3} md={4} sm={12} xs={12}>
          <Selectautocomplete
            label="Technical Person Name(Primary) *"
            name="tech_person_1"
            datakey="id"
            dataValue="fullname"
            value={trDet.tech_person_1 ? trDet.tech_person_1 : ""}
            myOptions={users}
            size="small"
            isObject={true}
            onChange={(e, value) => {
              updateTempObj("tech_person_1", value);
            }}
            error={stepperError && !trDet.tech_person_1 ? true : false}
          />


        </Grid>
      </Grid>
      <Grid container rowSpacing={1} spacing={{
        xs: 0,
        sm: 2,
        lg: 5,
        xl: 12
      }} style={{ marginTop: 15 }}>
        <Grid item xl={3} md={4} sm={12} xs={12}>
          <Selectautocomplete
            mandatory
            label="Technical Person Name(Secondary)"
            name="tech_person_2"
            datakey="id"
            dataValue="fullname"
            size="small"
            value={trDet.tech_person_2 ? trDet.tech_person_2 : ""}
            myOptions={users}
            isObject={true}
            onChange={(e, value) => {
              updateTempObj("tech_person_2", value);
            }}

          />
        </Grid>
        <Grid item xl={3} md={4} sm={12} xs={12}>
          <Selectautocomplete
            label="Laboratory Type *"
            name="laboratory_type"
            datakey="id"
            dataValue="type"
            value={trDet.laboratory_type ? trDet.laboratory_type : ""}
            myOptions={labTypes}
            size="small"
            onChange={(e, value) => {
              updateTempObj("laboratory_type", value);
            }}
            error={stepperError && !trDet.laboratory_type ? true : false}

          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TrialDetails;
