import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Typography } from '@mui/material';

const SelectViews = (props) => {
 return (
  <div >
   <Typography>Select Year</Typography>
      <FormControl sx={{ m: 1, width: 100 }}>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          value={props.value}
          onChange={props.onChange}
          style={{height:40}}
        >
           {props.options &&
props.options.map((d,index) => <MenuItem key={index} value={d}>{d}</MenuItem>) }
        </Select>
      </FormControl>
    </div>
 );
}

export default SelectViews;
