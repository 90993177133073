import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import configParam from "../Config";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  trial_details,
  client_details,
  concrete_mixdetails,
  supporting_info,
  comments,
  trial_results,
  Admixture_Composition,
  visitType,
  trialNo,
  currentSteper,
  selectedVisitObj,
  isReplace,
  admixtorPopupState,
  isReadOnly,
  error
} from "../recoil/atom";
import { Alert, IconButton, Grid } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/material/Skeleton';


const NewVisitTable = (props) => {
  const trialData = localStorage.getItem("ChrysoVisitData");
  let data = trialData ? JSON.parse(trialData) : {};

  const sgid = localStorage.getItem("UserID");
  const getVisitNo = configParam.VISIT_NO;
  const [tabledata, setTableData] = useState({});
  const [, setMessage] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [, setTrDet] = useRecoilState(trial_details);
  const [, setclientTrDet] = useRecoilState(client_details);
  const [, setcongreteDet] = useRecoilState(concrete_mixdetails);
  const [, setSupInfo] = useRecoilState(supporting_info);
  const [, setComment] = useRecoilState(comments);
  const [, setTrialRes] = useRecoilState(trial_results);
  const [, setAdmixture] = useRecoilState(Admixture_Composition);
  const resetTrDetValue = useResetRecoilState(trial_details);
  const resetClientDetValue = useResetRecoilState(client_details);
  const resetConcreteValue = useResetRecoilState(concrete_mixdetails);
  const resetSupportingValue = useResetRecoilState(supporting_info);
  const resetAdmixtureValue = useResetRecoilState(Admixture_Composition);
  const resetTrialResultValue = useResetRecoilState(trial_results);
  const resetCommentValue = useResetRecoilState(comments);
  const resetAlertValue = useResetRecoilState(admixtorPopupState);
  const [alertMessage] = useRecoilState(admixtorPopupState);
  const [, setVisitType] = useRecoilState(visitType);
  const [, SetTrialId] = useRecoilState(trialNo);
  const [, setValue] = useRecoilState(currentSteper);
  const [, setReplace] = useRecoilState(isReplace);
  const [, setStepperError] = useRecoilState(error);
  const navigate = useNavigate();
  const [, setSelectVisitData] = useRecoilState(selectedVisitObj);
  const [popupMsg, setPopupMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [, setReadonly] = useRecoilState(isReadOnly);
  const currentZone = localStorage.getItem("zoneName");
  const zone = JSON.parse(JSON.stringify(currentZone));


  useEffect(() => {
    setIsLoading(true);
    processedrows(data ? data[sgid] : []);
  }, []);


  const processedrows = (value) => {
    var temptabledata = [];
    if (value) {
      temptabledata = temptabledata.concat(
        Object.keys(value).map(function (key, val) {
          return value[val];
        })
      );
    }
    let res = {};
    for (var i = 0; i < temptabledata.length; i++)
      res[i] = temptabledata[i];
    setTableData(res);
    setMessage(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000)

  };

  const onHandleEdit = (index) => {
    if (alertMessage[12].status && alertMessage[12].trialNumber && alertMessage[12].trialNumber === data[sgid][index]["TrialDetails"].trial_number) {
    }
    else {
      resetAlertValue();
    }

    setStepperError(false);
    setVisitType("1");
    setReplace(0);
    localStorage.setItem("VisitID", index);
    let value = data[sgid][index];
    if (value && value["TrialDetails"]) {
      setTrDet(data[sgid][index]["TrialDetails"]);
    } else {
      resetTrDetValue();
    }
    if (value && value["ClientDetails"]) {
      setclientTrDet(data[sgid][index]["ClientDetails"]);
    } else {
      resetClientDetValue();
    }
    if (value && value["ConcreteDetails"]) {
      setcongreteDet(data[sgid][index]["ConcreteDetails"]);
    } else {
      resetConcreteValue();
    }
    if (value && value["supInfo"]) {
      setSupInfo(data[sgid][index]["supInfo"]);
    } else {
      resetSupportingValue();
    }
    if (value && value["Admixture"]) {
      setAdmixture(data[sgid][index]["Admixture"]);
    } else {
      resetAdmixtureValue();

    }
    if (value && value["TrialResult"]) {
      setTrialRes(data[sgid][index]["TrialResult"]);
    } else {
      resetTrialResultValue();
    }
    if (value && value["comments"]) {
      setComment(data[sgid][index]["comments"]);
    } else {
      resetCommentValue();
    }
    setValue(0);
    navigate(`/NewVisit`);
    props.tableChange();
  };


  const onHandleCreate = () => {
    resetAlertValue();
    setStepperError(false);
    setReadonly(false);
    const currentYear = new Date();
    setReplace(0);
    resetTrDetValue();
    resetClientDetValue();
    resetConcreteValue();
    resetSupportingValue();
    resetAdmixtureValue();
    resetTrialResultValue();
    resetCommentValue();
    const visit = getVisitNo();
    setVisitType("1");
    setValue(0);
    let trailsId =
      zone.charAt(0) +
      currentYear.getFullYear() +
      "-" +
      parseInt(currentYear / 1000);
    console.log("trailsId", trailsId)
    localStorage.setItem("trialNo", trailsId ? trailsId : "");
    setSelectVisitData({});
    SetTrialId(trailsId);
    localStorage.setItem("VisitID", visit ? visit : 0);
    navigate(`/NewVisit`);
  };

  const rowDelete = (index) => {
    localStorage.setItem("VisitID", index.id);
    setOpen(true);

  };

  const handleDelete = () => {
    const id = localStorage.getItem("VisitID");
    const myRegenData = localStorage.getItem("ChrysoVisitData");
    const value = JSON.parse(myRegenData);

    var temptabledata = [];
    temptabledata = temptabledata.concat(
      Object.keys(value[sgid]).map(function (key) {
        return [value[sgid][key]];
      })
    );
    const data = temptabledata.filter((key, index) => index != id);
    const tepArr = data.map((val, index) => {
      return data[index][0];
    });
    var target = { [sgid]: {} };
    Object.keys(tepArr).forEach((key) => (target[sgid][key] = tepArr[key]));
    localStorage.setItem("ChrysoVisitData", JSON.stringify(target));
    processedrows(target[sgid]);
    setOpen(false);
    setPopupMsg("Deleted Successfully");
    setTimeout(() => {
      setPopupMsg("");
    }, 3000);
  };

  const onClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      field: "ID",
      headerName: "S. No",
      width: 80,
      filterable: false,
      renderCell: (row, index) => row.id + 1,
      // renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "Action",
      headerName: "Actions",
      sortable: false,
      width: 150,
      disableClickEventBubbling: false,
      disableColumnMenu: true,

      renderCell: (row, index) => {
        return (
          <div
            style={{ cursor: "pointer" }}
          >
            <Tooltip title="Edit">
              <EditIcon
                onClick={() => {
                  setReadonly(false);
                  onHandleEdit(row.id);
                }}
                style={{ marginLeft: 15 }}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <DeleteIcon onClick={() => rowDelete(row)}
                style={{ marginLeft: 15 }}
              />
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "trial_number",
      headerName: "Trial No",
      width: 200,
      editable: true,
    },
    {
      field: "trial_date",
      headerName: "Trial Date",
      width: 180,
      editable: true,
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      width: 280,
      editable: true,
    },
    {
      field: "enquiry_number",
      headerName: "Enquiry No",
      width: 220,
      editable: true,
    },
    {
      field: "project_type",
      headerName: "Project Type",
      width: 180,
      editable: true,
    },
    {
      field: "srno_Pname",
      headerName: "Sample Name",
      width: 220,
      editable: true,
    },
  ];


  return (
    <React.Fragment>
      {isLoading ? (
        <div className="skeleton-dashboard" style={{ width: "100%" }}>
          <Grid container style={{ justifyContent: "flex-end" }}>
            <Skeleton style={{ width: "130px", height: 40, transform: "none" }} />
          </Grid>
          <Grid container style={{ justifyContent: "space-between", marginTop: "20px" }}>
            <Skeleton style={{ width: "100%", height: 400, transform: "none" }} />
          </Grid>
        </div>

      ) : (
        <React.Fragment>
          {popupMsg && (
            <Alert
              style={{ width: "100%" }}
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setPopupMsg("");
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {popupMsg}
            </Alert>
          )}

          <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {" Are you sure  you want to Delete?"}
            </DialogTitle>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <Button onClick={handleDelete} autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>

          <div style={{
            marginBottom: 15, display: "flex",
            justifyContent: "flex-end",
          }}>
            <Button variant="contained" startIcon={<AddIcon />} style={{ backgroundColor: "#203468" }} onClick={() => onHandleCreate()}> Add Visit </Button>
          </div>

          <Box sx={{ height: 600, width: "100%" }}>
            <DataGrid
              style={{ border: 0 }}
              className="newVisitTable"
              rows={
                Object.keys(tabledata).map((item, index) => ({
                  ID: index,
                  trial_number: tabledata[index] ? tabledata[index]["TrialDetails"].trial_number : "",
                  trial_date: tabledata[index] && tabledata[index]["TrialDetails"].trial_date ? moment(tabledata[index]["TrialDetails"].trial_date).format("DD-MMM-YYYY") : "--",
                  // customer_name: tabledata[index]["ClientDetails"] ?
                  //   typeof tabledata[index]["ClientDetails"].customer_name === "object" ?
                  //     tabledata[index]["ClientDetails"].customer_name.name :
                  //     "" :

                  //   " --",
                  customer_name: tabledata[index] && tabledata[index]["ClientDetails"] && tabledata[index]["ClientDetails"].customer_name && typeof tabledata[index]["ClientDetails"].customer_name === "object" && tabledata[index]["ClientDetails"].customer_name.name ? tabledata[index]["ClientDetails"].customer_name.name : "--",

                  project_type: tabledata[index] && tabledata[index]["ClientDetails"] ?
                    typeof tabledata[index]["ClientDetails"].project_type === "object" ?
                      tabledata[index]["ClientDetails"].project_type.type :
                      "" :

                    " --",
                  enquiry_number: tabledata[index] && tabledata[index]["ClientDetails"] ? tabledata[index]["ClientDetails"].enquiry_number : "--",
                  srno_Pname: tabledata[index] && tabledata[index]["Admixture"] ? tabledata[index]["Admixture"].srno_Pname : "--",
                }))
              }
              columns={columns}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 }
                }
              }}
              getRowId={(rows) => rows.ID}
              pageSize={10}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
            />
          </Box>
        </React.Fragment>
      )}

    </React.Fragment>
  );
};
export default NewVisitTable;
