import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import configParam from "../Config";
import AddDropDown from "./AddDrapdown";
import axios from "axios";

export default function ControlledAccordions(props) {
  const [expanded, setExpanded] = React.useState(false);
  const [rowData] = useState([]);
  const [labType, setLabType] = useState([]);
  const [projectType, setProjectType] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [cancreteType, setCancreteType] = useState([]);
  const [earlyStrengthTimeData, setEarlyStrengthTimeData] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [cementBrand, setCementBrand] = useState([]);
  const [sand, setSand] = useState([]);
  const ApiURL = configParam.API_URL;
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    getConcreteDetails();
  }, []);

  // GraphqlSection
  //LabType
  const getConcreteDetails = () => {
    let urlLabType = ApiURL + 'get_lab_type';
    axios.post(urlLabType).then((response) => {
      if (response.data.length > 0) {
        setLabType(response.data);
      } else {
        setLabType([]);
      }

    }).catch((error) => {
      console.log("delete_laboratory_type", error);
    });
    let Url = ApiURL + 'get_project_type';
    axios.get(Url).then((response) => {
      if (response.data.length > 0) {
        setProjectType(response.data);
      } else {
        setProjectType([]);
      }

    }).catch((error) => {
      console.log("delete_laboratory_type", error);
    });
    let GrudeUrl = ApiURL + 'get_congrete_Grade';
    axios.get(GrudeUrl).then((response) => {
      if (response.data.length > 0) {
        setGradeList(response.data);
      } else {
        setGradeList([]);
      }
    }).catch((error) => {
      console.log("delete_laboratory_type", error);
    });
    let ConcreteTypeUrl = ApiURL + 'get_concrete_Type';
    axios.get(ConcreteTypeUrl).then((response) => {
      if (response.data.length > 0) {
        setCancreteType(response.data);
      } else {
        setCancreteType([]);
      }
    }).catch((error) => {
      console.log("delete_laboratory_type", error);
    });
    let timeUrl = ApiURL + 'get_early_Strength_Time';
    axios.get(timeUrl).then((response) => {
      if (response.data.length > 0) {
        setEarlyStrengthTimeData(response.data);
      } else {
        setEarlyStrengthTimeData([]);
      }
    }).catch((error) => {
      console.log("delete_laboratory_type", error);
    });
    let customerUrl = ApiURL + 'get_customer_name';
    axios.get(customerUrl).then((response) => {
      if (response.data.length > 0) {
        setCustomer(response.data);
      } else {
        setCustomer([]);
      }
    }).catch((error) => {
      console.log("delete_laboratory_type", error);
    });
    let cementUrl = ApiURL + 'get_chryso_cement_brand';
    axios.get(cementUrl).then((response) => {
      if (response.data.length > 0) {
        setCementBrand(response.data);
      } else {
        setCementBrand([]);
      }
    }).catch((error) => {
      console.log("delete_laboratory_type", error);
    });
    let sandUrl = ApiURL + 'get_sand_category';
    axios.get(sandUrl).then((response) => {
      if (response.data.length > 0) {
        setSand(response.data);
      } else {
        setSand([]);
      }
    }).catch((error) => {
      console.log("delete_laboratory_type", error);
    });
  };

  return (
    <div style={{ width: "100%" }}>
      {props.type === 0 ? (
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ flexShrink: 0, fontWeight: 700 }}>
              Laboratory Type
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <AddDropDown
              data={labType ? labType : []}
              columnGrade="type"
              heading={"Add Laboratory Type"}
              loadingData={getConcreteDetails}
            />
          </AccordionDetails>
        </Accordion>
      ) : null}

      {props.type === 1 ? (
        <React.Fragment>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography sx={{ flexShrink: 0, fontWeight: 700 }}>
                Type of Project
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AddDropDown
                data={projectType}
                columnGrade="type"
                rowData={rowData}
                heading={"Add Type of Project"}
                loadingData={getConcreteDetails}
              />
            </AccordionDetails>
          </Accordion>
          <br />
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography sx={{ flexShrink: 0, fontWeight: 700 }}>
                Congrete Grade
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AddDropDown
                data={gradeList}
                columnGrade="grade"
                rowData={rowData}
                heading={"Add Congrete Grade"}
                loadingData={getConcreteDetails}
              />
            </AccordionDetails>
          </Accordion>

          <br />
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography sx={{ flexShrink: 0, fontWeight: 700 }}>
                Concrete Type
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AddDropDown
                data={cancreteType}
                columnGrade="type"
                rowData={rowData}
                heading={"Add Congrete Type"}
                loadingData={getConcreteDetails}
              />
            </AccordionDetails>
          </Accordion>

          <br />
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography
                sx={{
                  flexShrink: 0,
                  fontWeight: 700,
                }}
              >
                Early Strength Time
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AddDropDown
                data={earlyStrengthTimeData}
                columnGrade="time"
                rowData={rowData}
                heading={"Add Early Strength Time"}
                loadingData={getConcreteDetails}
              />
            </AccordionDetails>
          </Accordion>
          <br />
          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography sx={{ flexShrink: 0, fontWeight: 700 }}>
                Customer Name
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AddDropDown
                data={customer}
                columnGrade="name"
                rowData={rowData}
                heading={"Add Customer Name"}
                loadingData={getConcreteDetails}
              />
            </AccordionDetails>
          </Accordion>
        </React.Fragment>
      ) : null}

      {props.type === 2 ? (
        <Accordion
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ flexShrink: 0, fontWeight: 700 }}>
              Cement Brand
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AddDropDown
              data={cementBrand}
              columnGrade="type"
              heading={"Add Cement Brand/Grade/Source"}
              loadingData={getConcreteDetails}
            />
          </AccordionDetails>
        </Accordion>
      ) : null}
      {props.type === 3 ? (
        <Accordion
          expanded={expanded === "panel8"}
          onChange={handleChange("panel8")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ flexShrink: 0, fontWeight: 700 }}>
              Sand Category
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AddDropDown
              data={sand}
              columnGrade="category"
              rowData={rowData}
              heading={"Add sand Category"}
              loadingData={getConcreteDetails}
            />
          </AccordionDetails>
        </Accordion>
      ) : null}
    </div>
  );
}
