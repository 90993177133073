import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import SavedVisit from "../newVisit/SavedVisitTable";
import Visit from "../Visit/Visit";
import { useRecoilState } from "recoil";
import { visitType, TabValue } from "../recoil/atom";
import { Breadcrumbs } from "@mui/material";

const SavedVisitTab = () => {
  const [value, setValue] = useRecoilState(TabValue);
  const [, setVisitType] = useRecoilState(visitType);
  const handleChange = (event, newValue) => {
    setVisitType(newValue);
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Breadcrumbs />
      <Box sx={{ typography: "body1", borderBottom: "none" }}>
        <TabContext value={value ? value : "1"}>
          <Box sx={{}}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="New Visit" value="1" />
              <Tab label="Saved Visit" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Visit />
          </TabPanel>
          <TabPanel value="2">
            {/* {""} */}
            <SavedVisit />
          </TabPanel>
        </TabContext>
      </Box>
    </React.Fragment>
  );
};

export default SavedVisitTab;
