import React, { Component, useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  Grid,
  Card,
  TableCell,
  Table,
  TableRow,
  Box,
  TableContainer,
  TableBody,
  Typography,
} from "@mui/material";
import { concrete_mixdetails, trial_details, isExternal } from "../recoil/atom";
import configParam from "../Config";
import axios from "axios";
import Logo from "../asset/Logo.png";
import moment from "moment";

const PreviewForm = ({ data }) => {
  const [congreteDet, setcongreteDet] = useRecoilState(concrete_mixdetails);
  const [hide] = useRecoilState(isExternal);
  const [trDet, setTrDet] = useRecoilState(trial_details);
  const userDet = localStorage.getItem("user");
  let roleID = JSON.parse(userDet);
  // const toTimeString = (totalSeconds) => {
  //   console.log("totalSeconds",totalSeconds)
  //   if (isNaN(totalSeconds)) {
  //     return "";
  //   }
  //   const totalMs = totalSeconds * 1000;
  //   const result = new Date(totalMs).toISOString().slice(11, 19);
  //   console.log("result",result)
  //   return result;
  // }
  // const toTimeString = (totalSeconds) => {
  //   if (isNaN(totalSeconds)) {
  //     return "";
  //   }
  //   const minutes = Math.floor(totalSeconds / 60).toString();
  //   const seconds = (totalSeconds % 60).toString().padStart(2, "0");

  //   return `${minutes}:${seconds}`;
  // };
  // const toTimeString1 = (dateString) => {
  //   const date = new Date(dateString);
  //   if (isNaN(date.getTime())) {
  //     return "";
  //   }
  //   const totalSeconds = date.getUTCHours() * 3600 + date.getUTCMinutes() * 60;
  //   const hours = Math.floor(totalSeconds / 3600).toString().padStart(2, "0");
  //   const minutes = Math.floor((totalSeconds % 3600) / 60).toString().padStart(2, "0");

  //   return `${hours}:${minutes}`;
  // };

  const toTimeString = (value) => {
    console.log("value", value);
    if (!value) {
      return "";
    }

    let totalSeconds;
    if (!isNaN(Number(value))) {
      totalSeconds = Number(value);
    } else {
      const date = new Date(value);
      console.log("date", date);
      if (isNaN(date.getTime())) {
        return "";
      }

      totalSeconds = date.getUTCHours() * 3600 + date.getUTCMinutes() * 60;
      const hours = Math.floor(totalSeconds / 3600)
        .toString()
        .padStart(2, "0");
      const minutes = Math.floor((totalSeconds % 3600) / 60)
        .toString()
        .padStart(2, "0");
      return `${hours}:${minutes}`;
    }

    const minutes = Math.floor(totalSeconds / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (totalSeconds % 60).toString().padStart(2, "0");

    return `${minutes}:${seconds}`;
  };

  function convertDateToMinutesIfDateTime(str) {
    const dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    if (dateTimeRegex.test(str)) {
      const date = new Date(str);
      const hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();
      const totalMinutes = hours * 60 + minutes;
      return totalMinutes ? Number(totalMinutes) : totalMinutes;
    } else {
      return str;
    }
  }
  return (
    <Grid>
      <Grid container style={{ marginBottom: 20, marginTop: 10 }}>
        <Grid item xs={2}>
          <img src={Logo} alt="Logo" style={{ width: 160 }} />
        </Grid>
        <Grid
          item
          xs={8}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // width: "100%",
          }}
        >
          <Typography
            variant="h1"
            style={{
              textTransform: "uppercase",
              textAlign: "center",
              fontWeight: "bold",
              maxWidth: "700px",
              fontSize: "28px",
            }}
          >
            CHRYSO - TRIAL DATA
          </Typography>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
      <Card
        style={{ marginBottom: "20px", width: "99%", border: "1px solid #ccc" }}
      >
        <h3
          style={{
            width: "40%",
            fontWeight: 600,
            color: "#4D4D4D",
            padding: "7px 15px",
            fontSize: 22,
          }}
        >
          Trial Details
        </h3>
        <Box p={1} style={{ marginTop: "-15px" }}>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Trial No:
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      width: "50%",
                    }}
                  >
                    {data[0].trialno ? data[0].trialno : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      width: "50%",
                      fontWeight: 600,
                    }}
                  >
                    Date of Trial:
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      width: "50%",
                    }}
                  >
                    {moment(data[0].trial_date).format("DD-MMM-YYYY")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      width: "50%",
                      fontWeight: 600,
                    }}
                  >
                    Technical Person Name(Primary):
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      width: "50%",
                    }}
                  >
                    {data[0].tech_person_1_firstname
                      ? data[0].tech_person_1_firstname
                      : ""}{" "}
                    {data[0].tech_person_1_lastname
                      ? data[0].tech_person_1_lastname
                      : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      width: "50%",
                      fontWeight: 600,
                    }}
                  >
                    Technical Person Name(Secondary):
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      width: "50%",
                    }}
                  >
                    {data[0].tech_person_2_firstname
                      ? data[0].tech_person_2_firstname
                      : ""}{" "}
                    {data[0].tech_person_2_lastname
                      ? data[0].tech_person_2_lastname
                      : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      width: "50%",
                      fontWeight: 600,
                    }}
                  >
                    Laboratory Type:
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      width: "50%",
                    }}
                  >
                    {data[0].labtype ? data[0].labtype : ""}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Card>

      <Card
        style={{ marginBottom: "20px", width: "99%", border: "1px solid #ccc" }}
      >
        <h3
          style={{
            width: "100%",
            border: "none",
            fontWeight: 600,
            color: "#4D4D4D",
            padding: "7px 15px",
            fontSize: 22,
          }}
        >
          Client Details & Parameters
        </h3>
        <Box p={1} style={{ marginTop: "-15px" }}>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      width: "50%",
                      fontWeight: 600,
                    }}
                  >
                    Type of Project:
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      width: "50%",
                    }}
                  >
                    {data[0].projecttype ? data[0].projecttype : ""}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      width: "50%",
                      fontWeight: 600,
                    }}
                  >
                    Type of Concrete:
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data[0].concretetype ? data[0].concretetype : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Customer Name:
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data[0].customername ? data[0].customername : ""}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Enquiry No.:
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data[0].enquiry_number ? data[0].enquiry_number : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Project Details:
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "normal",
                      wordBreak: "break-word",
                      maxWidth: "200px",
                      overflow: "hidden",
                    }}
                  >
                    {data[0].project_details ? data[0].project_details : ""}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Project Location:
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      padding: "7px 15px",
                    }}
                  >
                    {data[0].project_location ? data[0].project_location : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Grade of concrete:
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      padding: "7px 15px",
                    }}
                  >
                    {data[0].cograde ? data[0].cograde : ""}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Retention(Mins):
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {convertDateToMinutesIfDateTime(
                      data[0].retention
                        ? data[0].retention === "NaN"
                          ? ""
                          : data[0].retention
                        : ""
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Workability Flow/ Slump (mm):
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      padding: "7px 15px",
                    }}
                  >
                    {data[0].workability_flow_slump
                      ? data[0].workability_flow_slump
                      : ""}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Air Content %:
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      padding: "7px 15px",
                    }}
                  >
                    {data[0].air_content ? data[0].air_content : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Early Strength (Time):
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data[0].earlystrengthtime ? data[0].earlystrengthtime : ""}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Early Strength(Mpa):
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data[0].early_strength_mpa
                      ? data[0].early_strength_mpa
                      : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Target Strength 28 days(Mpa):
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data[0].target_strength_28_days
                      ? data[0].target_strength_28_days
                      : ""}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Card>

      <Card
        style={{
          marginBottom: "20px",
          marginTop: 50,
          width: "99%",
          border: "1px solid #ccc",
          overflow: "hidden",
        }}
      >
        <h3
          style={{
            width: "40%",
            border: "none",
            fontWeight: 600,
            color: "#4D4D4D",
            padding: "7px 15px",
            fontSize: 22,
          }}
        >
          Concrete Mix Design Details
        </h3>
        <Box p={1} style={{ marginTop: "-15px" }}>
          <TableContainer style={{ overflow: "hidden" }}>
            <Grid xs={12}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      Cement Brand/Grade/Source:
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        width: "20%",
                      }}
                    >
                      {data[0].cementbrand ? data[0].cementbrand : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      Cement Content (Kg):
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        padding: "7px 15px",
                      }}
                    >
                      {data[0].cement_content ? data[0].cement_content : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        width: "40%",
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        fontWeight: 600,
                      }}
                    >
                      Flyash Content (Kg):
                    </TableCell>
                    <TableCell
                      style={{
                        width: "40%",
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                      }}
                    >
                      {data[0].flyash ? data[0].flyash : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        width: "40%",
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        fontWeight: 600,
                      }}
                    >
                      GGBFS Content (Kg):
                    </TableCell>
                    <TableCell
                      style={{
                        width: "40%",
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        padding: "7px 15px",
                      }}
                    >
                      {data[0].ggbfs_content ? data[0].ggbfs_content : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        width: "40%",
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        fontWeight: 600,
                      }}
                    >
                      Microsilica Content(Kg):
                    </TableCell>
                    <TableCell
                      style={{
                        width: "40%",
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        padding: "7px 15px",
                      }}
                    >
                      {data[0].microsilica_content
                        ? data[0].microsilica_content
                        : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        width: "40%",
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        width: "50%",
                        fontWeight: 600,
                      }}
                    >
                      Fine Aggregate (Natural Sand) Content (Kg):
                    </TableCell>
                    <TableCell
                      style={{
                        width: "40%",
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        width: "50%",
                      }}
                    >
                      {data[0].aggregates &&
                      data[0].aggregates.fine_aggregate_natural_sand_content
                        ? data[0].aggregates.fine_aggregate_natural_sand_content
                        : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        width: "40%",
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        width: "50%",
                        fontWeight: 600,
                      }}
                    >
                      Fine Aggregate (Crushed Sand) Content (Kg):
                    </TableCell>
                    <TableCell
                      style={{
                        width: "40%",
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        width: "50%",
                      }}
                    >
                      {data[0].aggregates &&
                      data[0].aggregates.fine_aggregate_crushed_sand_content
                        ? data[0].aggregates.fine_aggregate_crushed_sand_content
                        : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        width: "40%",
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        width: "50%",
                        fontWeight: 600,
                      }}
                    >
                      Coarse Aggregate (CA 10mm) Content (Kg):
                    </TableCell>
                    <TableCell
                      style={{
                        width: "40%",
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        width: "50%",
                      }}
                    >
                      {data[0].aggregates &&
                      data[0].aggregates.coarse_aggregate_ca10mm_content
                        ? data[0].aggregates.coarse_aggregate_ca10mm_content
                        : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        width: "40%",
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        width: "50%",
                        fontWeight: 600,
                      }}
                    >
                      Coarse Aggregate CA20mm Content (Kg):
                    </TableCell>
                    <TableCell
                      style={{
                        width: "40%",
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        width: "50%",
                      }}
                    >
                      {data[0].aggregates &&
                      data[0].aggregates.coarse_aggregate_ca20mm_content
                        ? data[0].aggregates.coarse_aggregate_ca20mm_content
                        : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        width: "40%",
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        fontWeight: 600,
                      }}
                    >
                      Other Aggregate( Special if any) Content (Kg):
                    </TableCell>
                    <TableCell
                      style={{
                        width: "40%",
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        width: "50%",
                      }}
                    >
                      {data[0].aggregates &&
                      data[0].aggregates.other_aggregate_content
                        ? data[0].aggregates.other_aggregate_content
                        : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        width: "40%",
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        fontWeight: 600,
                      }}
                    >
                      Free Water (Kg):
                    </TableCell>
                    <TableCell
                      style={{
                        width: "40%",
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        width: "50%",
                      }}
                    >
                      {data[0].free_water ? data[0].free_water : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow></TableRow>
                </TableBody>
              </Table>
            </Grid>
          </TableContainer>
        </Box>
      </Card>

      <Card
        style={{ marginBottom: "20px", width: "99%", border: "1px solid #ccc" }}
      >
        <h3
          style={{
            width: "40%",
            border: "none",
            fontWeight: 600,
            color: "#4D4D4D",
            fontWeight: 600,
            padding: "7px 15px",
            fontSize: 22,
          }}
        >
          Supporting Information( Mix Design )
        </h3>
        <Box p={1} style={{ marginTop: "-15px" }}>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      width: "50%",
                      fontWeight: 600,
                    }}
                  >
                    W/CM :
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      width: "50%",
                    }}
                  >
                    {data[0].w_cm ? data[0].w_cm : ""}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      width: "50%",
                      fontWeight: 600,
                    }}
                  >
                    Alkali content in cement(%):
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      width: "50%",
                    }}
                  >
                    {data[0].alkali_content ? data[0].alkali_content : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Sand Passing % in 600 µ :
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data[0].sand_passing600 ? data[0].sand_passing600 : ""}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Sand Passing % in 150 µ :
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data[0].sand_passing150 ? data[0].sand_passing150 : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Sand Category :
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data[0].sandcategory ? data[0].sandcategory : ""}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Clear Test 10:
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data[0].clear_test10 ? data[0].clear_test10 : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Clear Test 50:
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data[0].clear_test50 ? data[0].clear_test50 : ""}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Coarse Aggregate 150 micron passing 20mm:
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data[0].coarse_aggregate_150micpass20
                      ? data[0].coarse_aggregate_150micpass20
                      : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Coarse Aggregate 150 micron passing 10mm:
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data[0].coarse_aggregate_150micpass10
                      ? data[0].coarse_aggregate_150micpass10
                      : ""}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Ambient Temperature (°C):
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data[0].ambient_temperature
                      ? data[0].ambient_temperature
                      : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Ambient Humidity (%):
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data[0].ambient_humidity ? data[0].ambient_humidity : ""}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Card>

      <Grid container style={{ marginBottom: 20, pageBreakBefore: "always" }}>
        <Grid item xs={2}>
          <img src={Logo} alt="Logo" style={{ width: 160 }} />
        </Grid>
        <Grid
          item
          xs={8}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Typography
            variant="h1"
            style={{
              textTransform: "uppercase",
              textAlign: "center",
              fontWeight: "bold",
              maxWidth: "700px",
              fontSize: "28px",
            }}
          >
            CHRYSO - TRIAL data
          </Typography>
        </Grid>
      </Grid>
      {!hide ? (
        <Card
          style={{
            marginBottom: "15px",
            border: "1px solid #ccc",
            width: "99%",
          }}
        >
          <h3
            style={{
              width: "40%",
              border: "none",
              fontWeight: 600,
              color: "#4D4D4D",
              padding: "7px 15px",
              fontSize: 22,
            }}
          >
            Admixture Composition (In gm/kg of Product)
          </h3>
          <Box p={1} style={{ marginTop: "-15px" }}>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        width: "10%",
                        fontWeight: 600,
                      }}
                    >
                      P20
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        width: "20%",
                      }}
                    >
                      {data[0].compositions.p20 ? data[0].compositions.p20 : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        width: "30%",
                        fontWeight: 600,
                      }}
                    >
                      P15
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.p15 ? data[0].compositions.p15 : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        width: "30%",
                        fontWeight: 600,
                      }}
                    >
                      P16
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        width: "30%",
                      }}
                    >
                      {data[0].compositions.p16 ? data[0].compositions.p16 : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        width: "10%",
                        fontWeight: 600,
                      }}
                    >
                      P18/HR150
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.p18_hr150
                        ? data[0].compositions.p18_hr150
                        : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      P11
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.p11 ? data[0].compositions.p11 : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      SNF/N5
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.snf_n5
                        ? data[0].compositions.snf_n5
                        : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      L1(Ligno50%)
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.l1_ligno_50
                        ? data[0].compositions.l1_ligno_50
                        : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      Ligno
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.ligno
                        ? data[0].compositions.ligno
                        : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      GN1
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.gn1 ? data[0].compositions.gn1 : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      CF
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.cf ? data[0].compositions.cf : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      G1
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.g1 ? data[0].compositions.g1 : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      AC2/AC3
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.ac2_ac3
                        ? data[0].compositions.ac2_ac3
                        : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      AC1
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.ac1 ? data[0].compositions.ac1 : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      AC5/AC6
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.ac5_ac6
                        ? data[0].compositions.ac5_ac6
                        : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      DF2
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.df2 ? data[0].compositions.df2 : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      DF1
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.df1 ? data[0].compositions.df1 : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      DF3
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.df3 ? data[0].compositions.df3 : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      AR2
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.ar2 ? data[0].compositions.ar2 : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      AR5
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.ar5 ? data[0].compositions.ar5 : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      AR6
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.ar6 ? data[0].compositions.ar6 : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      CR/1
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.cr_1
                        ? data[0].compositions.cr_1
                        : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      DF1
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.df1 ? data[0].compositions.df1 : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      DF3
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.df3 ? data[0].compositions.df3 : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      AR2
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.ar2 ? data[0].compositions.ar2 : ""}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      AR5
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.ar5 ? data[0].compositions.ar5 : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      AR6
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.ar6 ? data[0].compositions.ar6 : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      CR/1
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.cr_1
                        ? data[0].compositions.cr_1
                        : ""}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      B29/ P23
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.b29_p23
                        ? data[0].compositions.b29_p23
                        : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      P27/R650
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.p27_r650
                        ? data[0].compositions.p27_r650
                        : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      A15/P24
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.a15_p24
                        ? data[0].compositions.a15_p24
                        : ""}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      PR1
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.pr1 ? data[0].compositions.pr1 : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                        width: "10%",
                      }}
                    >
                      GN2
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        width: "10%",
                      }}
                    >
                      {data[0].compositions.gn2 ? data[0].compositions.gn2 : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      N43
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.n43 ? data[0].compositions.n43 : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      AR7
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.ar7 ? data[0].compositions.ar7 : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      Gly
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.gly ? data[0].compositions.gly : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      AR3
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.ar3 ? data[0].compositions.ar3 : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      AR4
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.ar4 ? data[0].compositions.ar4 : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      CA
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.ca ? data[0].compositions.ca : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      AC6
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.ac6 ? data[0].compositions.ac6 : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      P25/A19
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.p25_a19
                        ? data[0].compositions.p25_a19
                        : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      P26
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.p26 ? data[0].compositions.p26 : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      AR1
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.ar1 ? data[0].compositions.ar1 : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      VMA1
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.vma1
                        ? data[0].compositions.vma1
                        : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      VMA2
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.vma2
                        ? data[0].compositions.vma2
                        : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      Other
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.other
                        ? data[0].compositions.other
                        : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      Sample Ref No/Product Name
                    </TableCell>
                    <TableCell
                      style={{
                        border: "none",
                        fontWeight: 500,
                        color: "#4D4D4D",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data[0].compositions.srno_Pname
                        ? data[0].compositions.srno_Pname
                        : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
      ) : null}

      <Card
        style={{ marginBottom: "15px", border: "1px solid #ccc", width: "99%" }}
      >
        <h3
          style={{
            width: "40%",
            border: "none",
            fontWeight: 600,
            color: "#4D4D4D",
            padding: "7px 15px",
            fontSize: 22,
          }}
        >
          Trial Details Obtained
        </h3>
        <Box p={1} style={{ marginTop: "-15px" }}>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      width: "50%",
                      fontWeight: 600,
                    }}
                  >
                    Dosage % :
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      width: "50%",
                    }}
                  >
                    {data[0].dosage ? data[0].dosage : ""}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Retention(Mins):
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {convertDateToMinutesIfDateTime(
                      data[0].trretention
                        ? data[0].trretention === "NaN"
                          ? ""
                          : data[0].trretention
                        : ""
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Workability(mm):
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data[0].workability ? data[0].workability : ""}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Air Content %:
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data[0].traircontent ? data[0].traircontent : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Early Strength (Mpa):
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data[0].early_strength ? data[0].early_strength : ""}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Strength at 7 Days(Mpa):
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data[0].strength_at_7_days
                      ? data[0].strength_at_7_days
                      : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Strength at 28 Days(Mpa):
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data[0].strength_at_28_days
                      ? data[0].strength_at_28_days
                      : ""}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Card>

      <Card
        style={{ marginBottom: "15px", border: "1px solid #ccc", width: "99%" }}
      >
        <h3
          style={{
            width: "40%",
            border: "none",
            fontWeight: 600,
            color: "#4D4D4D",
            padding: "7px 15px",
            fontSize: 22,
          }}
        >
          Comments
        </h3>
        <Box p={1} style={{ marginTop: "-15px" }}>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      width: "50%",
                      fontWeight: 600,
                    }}
                  >
                    Cost Factor Comparison :
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      width: "50%",
                    }}
                  >
                    {data[0].cost_factor_comparison
                      ? data[0].cost_factor_comparison
                      : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      // whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Remarks :
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      // whiteSpace: "nowrap",
                    }}
                  >
                    {data[0].remarks ? data[0].remarks : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Success / Fail :
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data[0].status ? data[0].status : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                    }}
                  >
                    Reference to previous trial data ( Match):
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      fontWeight: 500,
                      color: "#4D4D4D",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data[0].ref_previous_trial_data
                      ? data[0].ref_previous_trial_data
                      : ""}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Card>
    </Grid>
  );
};
export default PreviewForm;
