import { ReactComponent as UserManagementLight } from "../../src/asset/UserManagementLight.svg";
import { ReactComponent as Dashbord } from "../../src/asset/dashbord.svg";
import { ReactComponent as TrialVisits } from "../../src/asset/trial_visits.svg";
import User from "../UserManagement/User";
import NewVisit from "../Visit/NewVisit";
import SavedVisitTab from "../input/SavedVisitTab";
import NewAddCategory from "../Visit/AddCategory/NewAddCategory";
import AdvancedSearch from "../PageVisit/AdvancedSearch";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";

const Routes = {
  mainRoutes: [
    {
      path: "/AdvancedSearch",
      name: "Dashboard",
      component: AdvancedSearch,
      icon: Dashbord,
      roleWiseDispaly: false,
    },
    {
      path: "/home",
      name: "Trial Visits",
      component: SavedVisitTab,
      icon: TrialVisits,
      roleWiseDispaly: false,
    },
    {
      path: "/user",
      name: "User Management",
      component: User,
      icon: UserManagementLight,
      roleWiseDispaly: true,
    },
    {
      path: "/NewVisit",
      name: "New Visit",
      component: NewVisit,
      roleWiseDispaly: false,
    },
    {
      path: "/AddCatogery",
      name: "Data Management",
      component: NewAddCategory,
      icon: AddToPhotosIcon,
      roleWiseDispaly: false,
    },
  ],
};
export default Routes;
