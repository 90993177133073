import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ControlledAccordions from "./ControlledAccordions";
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Typography,
  Box,
  Tab,
  Tabs
} from "@mui/material";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <React.Fragment>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography style={{ textAlign: "left", fontWeight: 700 }}>
              {children}
            </Typography>
          </Box>
        )}
      </div>

    </React.Fragment>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#635ee7',
  },
});
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: '#000',
    justifyContent: "flex-start",
    alignItems: "flex-start",
    '&.Mui-selected': {
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  }),
);
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000)
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="skeleton-dashboard" style={{ width: "100%", padding: "20px" }}>

          <Grid container style={{ justifyContent: "space-between", marginTop: "40px" }}>
            <Grid container style={{ width: "14%", justifyContent: "flex-end" }}>
              <Skeleton style={{ width: "100%", height: 50, transform: "none" }} />
              <Skeleton style={{ width: "100%", height: 50, transform: "none", marginTop: "10px" }} />
              <Skeleton style={{ width: "100%", height: 50, transform: "none", marginTop: "10px" }} />
              <Skeleton style={{ width: "100%", height: 50, transform: "none", marginTop: "10px" }} />
              <Skeleton style={{ width: "100%", height: 50, transform: "none", marginTop: "10px" }} />
              <Skeleton style={{ width: "100%", height: 50, transform: "none", marginTop: "10px" }} />
              <Skeleton style={{ width: "100%", height: 50, transform: "none", marginTop: "10px" }} />
            </Grid>
            <Grid container style={{ width: "85%", justifyContent: "flex-end" }}>
              <Grid container style={{ justifyContent: "space-between" }}>
                <Skeleton style={{ width: "100%", height: 50, transform: "none" }} />
              </Grid>
              <Grid container style={{ justifyContent: "space-between", marginTop: "20px" }}>
                <Skeleton style={{ width: "100%", height: 50, transform: "none" }} />
              </Grid>
              <Grid container style={{ justifyContent: "space-between", marginTop: "20px" }}>
                <Skeleton style={{ width: "100%", height: 50, transform: "none" }} />
              </Grid>
              <Grid container style={{ justifyContent: "space-between", marginTop: "20px" }}>
                <Skeleton style={{ width: "100%", height: 50, transform: "none" }} />
              </Grid>
              <Grid container style={{ justifyContent: "space-between", marginTop: "20px" }}>
                <Skeleton style={{ width: "100%", height: 50, transform: "none" }} />
              </Grid>
            </Grid>
          </Grid>
        </div>
      ) : (<Box
      >
        <Typography
          sx={{ fontSize: 22, padding: "20px", fontWeight: "bold", color: "#212121", marginBottom: 0 }}
          variant="subtitle1"
          color="text.secondary"
          gutterBottom
        >
          DATA MANAGEMENT
        </Typography>
        <Grid container>
          <StyledTabs
            orientation="harizontal"
            variant="scrollable"
            centered={false}
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
          >
            <StyledTab
              label="Trial Details"
              {...a11yProps(0)}
              style={{ textAlign: "left" }}
            />
            <StyledTab
              label="Client Details & Parameters"
              {...a11yProps(1)}
            />
            <StyledTab
              label="Concrete Mix Design Details"
              {...a11yProps(2)}
            />
            <StyledTab
              label="Supporting Information"
              {...a11yProps(3)}
            />
            <StyledTab
              label="Admixture Composition"
              {...a11yProps(4)}
            />
            <StyledTab
              label="Trial Results Obtained"
              {...a11yProps(5)}
            />
            <StyledTab
              label="Comments"
              {...a11yProps(6)}
            />
          </StyledTabs>
          <div style={{ width: '100%', flex: "1" }}>
            <TabPanel style={{ width: "100%" }} value={value} index={0}>
              <ControlledAccordions type={0} />
            </TabPanel>
            <TabPanel style={{ width: "100%" }} value={value} index={1}>
              <ControlledAccordions type={1} />
            </TabPanel>
            <TabPanel style={{ width: "100%" }} value={value} index={2}>
              <ControlledAccordions type={2} />
            </TabPanel>
            <TabPanel style={{ width: "100%" }} value={value} index={3}>
              <ControlledAccordions type={3} />
            </TabPanel>
            <TabPanel style={{ width: "100%" }} value={value} index={4}>
              <p style={{ textAlign: "center" }}>No Records Found</p>
            </TabPanel>
            <TabPanel style={{ width: "100%" }} value={value} index={5}>
              <p style={{ textAlign: "center" }}>No Records Found</p>
            </TabPanel>
            <TabPanel style={{ width: "100%" }} value={value} index={6}>
              <p style={{ textAlign: "center" }}>No Records Found</p>
            </TabPanel>
          </div>
        </Grid>
      </Box>)}
    </React.Fragment>
  );
}
