import React, { Suspense, useState, useEffect } from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { CssBaseline, LinearProgress } from "@mui/material";
import "./App.css";
import routes from "../src/router/PageRoutes";
import Login from "../src/Login/Login";
import Home from "./home/Home";
import AdvancedSearch from "./PageVisit/AdvancedSearch";
import decode from "jwt-decode";
import { AuthContext } from "./Context";
import RouteMissing from "./router/RouteMissing";
import PasswordReset from "./Login/PasswordReset";
import configParam from "./Config";
import { ReactComponent as UserManagementLight } from "./asset/UserManagementLight.svg";
import { ReactComponent as Dashbord } from "./asset/dashbord.svg";
import { ReactComponent as TrialVisits } from "./asset/trial_visits.svg";
import User from "./UserManagement/User";
import NewVisit from "./Visit/NewVisit";
import SavedVisitTab from "./input/SavedVisitTab";
import NewAddCategory from "./Visit/AddCategory/NewAddCategory";
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";

// const ChrysoTemplete = lazy(() => import('../src/Layout/ChrysoTemplete'));
const delay = 6000000;
const goToHome = () => {
  if (
    window &&
    window.location &&
    ["/", "/login"].includes(window.location.pathname)
  ) {
    window.location.pathname = "/home";
  }
};

const goToLogin = () => {
  if (window && window.location && window.location.pathname === "/") {
    window.location.pathname = "/login";
  }
};
export const switchRoutes = (
  <React.Fragment>
    {
      routes.mainRoutes.map((route, index) => {
        return route.component ? (
          <Route
            path={route.path}
            name={route.name}
            element={<route.component />}
            key={index}
          />
        ) : (
          <React.Fragment></React.Fragment>
        );
      })}
    <Route path="/" element={<Navigate replace to="/home" />} />
  </React.Fragment>
);

const PrivateOutlet = () => {
  const currentUser = localStorage.getItem("userId");
  const user = JSON.parse(JSON.stringify(currentUser));
  return user ? <Home /> : <Navigate to="/login" />;
};

function App() {
  const currentUser = localStorage.getItem("userId");
  const user = JSON.parse(JSON.stringify(currentUser));
  const [userId, setUserID] = useState(user);



  React.useEffect(() => {
    if (
      userId !== "" &&
      userId !== "undefined" &&
      userId !== null
    ) {
      goToHome();
      // }
    } else if (window && window.location && window.location.pathname === "/") {
      window.location.pathname = "/login";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  // useEffect(() => {
  //   setInterval(() => {
  //     getRefreshToken();
  //   }, 5 * 60 * 1000);
  // }, []);
  return (
    <div>
      <AuthContext.Provider value={{ userId, setAuthTokens: userId }}>
        <CssBaseline />
        <BrowserRouter >
          <Suspense fallback={<LinearProgress />}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/ResetPassword" element={<PasswordReset />} />
              <Route path="*" element={<RouteMissing />} />
              <Route path="/" element={<PrivateOutlet />}>
                {switchRoutes}
              </Route>
            </Routes>
          </Suspense>
        </BrowserRouter>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
