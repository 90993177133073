import React, { useEffect, useState } from "react";
import Select from "../input/SelectItem";
import TextBox from "../input/TextBox";
import { Grid } from "@mui/material";
import axios from "axios";
import { useRecoilState } from "recoil";
import {
  client_details,
  errorHandleObj,
  visitType,
  error,
  optionalerror,
} from "../recoil/atom";
import configParam from "../Config";
import gqlQueries from "../Queries/Queries";
import HourMinInput from "../input/HourMinInput";
import Selectautocomplete from "../input/Selectautocomplete";
import moment from "moment";
import { TextField, Typography } from "@mui/material";

const ClientDetails = () => {
  const [clientDet, setclientTrDet] = useRecoilState(client_details);
  const [clinterrorDet, setClinterrorDet] = useRecoilState(errorHandleObj);
  const [visitTypes] = useRecoilState(visitType);
  const [projectType, setProjectType] = useState([]);
  const [concreteType, setconcreteType] = useState([]);
  const [gradeType, setgradeType] = useState([]);
  const [earlyTime, setEarlyTime] = useState([]);
  const [customerName, setcustomerName] = useState([]);
  const [stepperError, setStepperError] = useRecoilState(error);
  const [optionalError, setOptionalError] = useState(false);
  const [optionSteperror, setOptionSteperror] = useRecoilState(optionalerror);
  const ApiURL = configParam.API_URL;
  const trialApprovelData = localStorage.getItem("TrialApprovelData");
  const SavedTrialId = localStorage.getItem("SavedTrialId");
  const trialData = localStorage.getItem("ChrysoVisitData");
  const SavedTrialDetailData = localStorage.getItem("SavedTrialDetail");
  const SavedTrialDetail = JSON.parse(SavedTrialDetailData);

  let data =
    visitTypes === "1"
      ? trialData
        ? JSON.parse(trialData)
        : {}
      : trialApprovelData
      ? JSON.parse(trialApprovelData)
      : {};
  const sgid = localStorage.getItem("UserID");
  const getItemNo = configParam.getVisitedItem;
  const getSavedVisitNo = configParam.getSavedTrial;
  useEffect(() => {
    getProjectDetails();
    getConcreteDetails();
    getConcreteGrade();
    getEarlyTime();
    getCustomerDetails();
  }, []);

  // ProjectType
  const getProjectDetails = () => {
    let url = ApiURL + "get_project_type";
    axios.get(url).then((res) => {
      if (res.data.length > 0) {
        setProjectType(res.data);
      }
    });
    // configParam.RUN_GQL_API(gqlQueries.GET_PROJECT_TYPE, {}).then((data) => {
    //   setProjectType(data.chryso_project_type);
    // });
  };

  // customer Name
  const getCustomerDetails = () => {
    let url = ApiURL + "get_customer_name";
    axios.get(url).then((res) => {
      if (res.data.length > 0) {
        setcustomerName(res.data);
      }
    });
    // configParam.RUN_GQL_API(gqlQueries.GET_CUSTOMER, {}).then((data) => {
    //   setcustomerName(data.chryso_customer);
    // });
  };
  // ConcrateType
  const getConcreteDetails = () => {
    let url = ApiURL + "get_concrete_Type";
    axios.get(url).then((res) => {
      if (res.data.length > 0) {
        setconcreteType(res.data);
      }
    });
    // configParam.RUN_GQL_API(gqlQueries.GET_CONCRETE_TYPE, {}).then((data) => {
    //   setconcreteType(data.chryso_concrete_type);
    // });
  };
  // Concrate-Grade-Type
  const getConcreteGrade = () => {
    let url = ApiURL + "get_congrete_Grade";
    axios.get(url).then((res) => {
      if (res.data.length > 0) {
        setgradeType(res.data);
      }
    });

    // configParam.RUN_GQL_API(gqlQueries.GET_CONCRETE_GRADE, {}).then((data) => {
    //   setgradeType(data.chryso_concrete_grade);
    // });
  };
  // early_strength_time
  const getEarlyTime = () => {
    let url = ApiURL + "get_early_Strength_Time";
    axios.get(url).then((res) => {
      if (res.data.length > 0) {
        setEarlyTime(res.data);
      }
    });

    // configParam.RUN_GQL_API(gqlQueries.GET_EARLY_TIME, {}).then((data) => {
    //   setEarlyTime(data.chryso_early_strength_time);
    // });
  };

  const validateError = (value, key) => {
    if (value) {
      setClinterrorDet({
        ...clinterrorDet,
        [key]: { value: value, isChecked: false },
      });
    } else {
      setClinterrorDet({
        ...clinterrorDet,
        [key]: { value: "", isChecked: true },
      });
    }
  };
  const changeClientDet = (e) => {
    let tmpObj = { ...clientDet };
    tmpObj[e.target.name] = e.target.value;
    setclientTrDet(tmpObj);
  };
  const timeConvert = (min) => {
    var num = min;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    var customMin = !isNaN(min) ? rhours + ":" + rminutes : "00:00";
    let value = new Date('July 1, 1999, ' + customMin + ':00');
    return value.toISOString();
  };
  const projectOnchange = (e, min, max) => {
    let tmpObj = { ...clientDet };
    let temError = { ...optionSteperror };
    let obj = { ...temError.client_details };

    if (e.target.value < min || e.target.value > max) {
      obj[e.target.name] = true;
      temError["client_details"] = obj
      setOptionSteperror(temError);
      setOptionalError(true);
    } else {
      setOptionalError(false);
      obj[e.target.name] = false;
      temError["client_details"] = obj;
      setOptionSteperror(temError);
    }
    tmpObj[e.target.name] = e.target.value;
    setclientTrDet(tmpObj);
  };

  const autocompleteChange = (key, value) => {
    let tmpObj = { ...clientDet };
    tmpObj[key] = value;
    setclientTrDet(tmpObj);
  };

  const changeRetention = (mins) => {
    let tmpObj = { ...clientDet };
    if (mins) {
      tmpObj["retention"] = mins;
    } else {
      if (mins === "retention") {
        validateError(mins, "retention1");
      }
    }
    setclientTrDet(tmpObj);
  };
  useEffect(() => {
    let rowIndex;
    if (visitTypes === "1") {
      rowIndex = getItemNo();
    } else {
      rowIndex = getSavedVisitNo();
    }
    if (data[sgid]) {
      let obj = { ClientDetails: clientDet };
      let merged = { ...data[sgid][rowIndex ? rowIndex : 0], ...obj };
      data[sgid][rowIndex] = merged;
    } else {
      data[sgid] = { 0: { ClientDetails: clientDet } };
    }
    if (visitTypes === "1") {
      localStorage.setItem("ChrysoVisitData", JSON.stringify(data));
    } else {
      localStorage.setItem("TrialApprovelData", JSON.stringify(data));
    }
  }, [clientDet]);

  return (
    <Grid container rowSpacing={2} style={{ marginTop: 25 }}>
      <Grid
        container
        rowSpacing={1}
        spacing={{
          xs: 0,
          sm: 2,
          lg: 5,
          xl: 12,
        }}
      >
        <Grid item xl={3} md={4} sm={12} xs={12}>
          <Selectautocomplete
            label="Type of Project *"
            name="project_type"
            datakey="id"
            dataValue="type"
            value={clientDet.project_type ? clientDet.project_type : ""}
            myOptions={projectType}
            error={stepperError && !clientDet.project_type ? true : false}
            onChange={(e, value) => {
              autocompleteChange("project_type", value);
            }}
          />
        </Grid>
        <Grid item xl={3} md={4} sm={12} xs={12}>
          <Selectautocomplete
            label="Type of Concrete *"
            name="concrete_type"
            datakey="id"
            dataValue="type"
            value={clientDet.concrete_type ? clientDet.concrete_type : ""}
            myOptions={concreteType}
            error={stepperError && !clientDet.concrete_type ? true : false}
            onChange={(e, value) => {
              autocompleteChange("concrete_type", value);
            }}
          />
        </Grid>
        <Grid item xl={3} md={4} sm={12} xs={12}>
          <Selectautocomplete
            label="Customer Name *"
            name="customer_name"
            datakey="id"
            dataValue="name"
            value={clientDet.customer_name ? clientDet.customer_name : ""}
            error={stepperError && !clientDet.customer_name ? true : false}
            myOptions={customerName}
            onChange={(e, value) => {
              autocompleteChange("customer_name", value);
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={1}
        spacing={{
          xs: 0,
          sm: 2,
          lg: 5,
          xl: 12,
        }}
        style={{ marginTop: 15 }}
      >
        <Grid item xl={3} md={4} sm={12} xs={12}>
          <TextBox
            label="Enquiry No. *"
            name="enquiry_number"
            size="small"
            value={
              clientDet.enquiry_number
                ? clientDet.enquiry_number === "0"
                  ? ""
                  : clientDet.enquiry_number
                : ""
            }
            onChange={(e) => changeClientDet(e)}
            style={{
              backgroundColor:
                clientDet.enquiry_number === "0" ? "lightgray" : "",
              color: "#000",
            }}
            error={stepperError && !clientDet.enquiry_number ? true : false}
          />
        </Grid>
        <Grid item xl={3} md={4} sm={12} xs={12}>
          <TextBox
            label="Project Details *"
            name="project_details"
            value={
              clientDet.project_details
                ? clientDet.project_details.toLocaleUpperCase()
                : ""
            }
            isString={true}
            onChange={(e) => changeClientDet(e)}
            error={stepperError && !clientDet.project_details ? true : false}
          />
        </Grid>
        <Grid item xl={3} md={4} sm={12} xs={12}>
          <TextBox
            label="Project Location *"
            name="project_location"
            isString={true}
            value={
              clientDet.project_location
                ? clientDet.project_location.toLocaleUpperCase()
                : ""
            }
            onChange={(e) => changeClientDet(e)}
            error={stepperError && !clientDet.project_location ? true : false}
          />
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={1}
        spacing={{
          xs: 0,
          sm: 2,
          lg: 5,
          xl: 12,
        }}
        style={{ marginTop: 15 }}
      >
        <Grid item xl={3} md={4} sm={12} xs={12}>
          <Selectautocomplete
            label="Grade of concrete *"
            name="concrete_grade"
            datakey="id"
            dataValue="grade"
            value={clientDet.concrete_grade ? clientDet.concrete_grade : ""}
            myOptions={gradeType}
            error={stepperError && !clientDet.concrete_grade ? true : false}
            onChange={(e, value) => {
              autocompleteChange("concrete_grade", value);
            }}
          />
        </Grid>
        <Grid item xl={3} md={4} sm={12} xs={12}>
          <HourMinInput
            label="Retention(Hr:Min) *"
            name="retention"
            value={
              clientDet.retention && typeof clientDet.retention === "object"
                ? clientDet.retention
                : clientDet.retention &&
                  typeof clientDet.retention === "string" &&
                  clientDet.retention.length <= 3
                ? timeConvert(clientDet.retention)
                : clientDet.retention
            }
            onChange={changeRetention}
            error={stepperError && !clientDet.retention ? true : false}
          />
        </Grid>
        <Grid item xl={3} md={4} sm={12} xs={12}>
          <TextBox
            label="Workability Flow/ Slump (mm) *"
            name="workability_flow_slump"
            value={
              clientDet.workability_flow_slump
                ? clientDet.workability_flow_slump === "0"
                  ? ""
                  : clientDet.workability_flow_slump
                : ""
            }
            style={{
              backgroundColor:
                clientDet.workability_flow_slump === "0" ? "lightgray" : "",
              width: "80%",
            }}
            onChange={(e) => projectOnchange(e, 0, 800)}
            helperText={
              stepperError && !clientDet.workability_flow_slump
                ? "Enter a value between 0 to 800"
                : optionSteperror.client_details.workability_flow_slump
                ? "Enter a value between 0 to 800"
                : ""
            }
            error={
              stepperError && !clientDet.workability_flow_slump
                ? true
                : optionSteperror.client_details.workability_flow_slump
                ? true
                : false
            }
          />
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={1}
        spacing={{
          xs: 0,
          sm: 2,
          lg: 5,
          xl: 12,
        }}
        style={{ marginTop: 15 }}
      >
        <Grid item xl={3} md={4} sm={12} xs={12}>
          <TextBox
            label="Air Content %"
            name="air_content"
            type="number"
            value={
              clientDet.air_content
                ? clientDet.air_content === "0"
                  ? ""
                  : clientDet.air_content
                : ""
            }
            style={{
              backgroundColor: clientDet.air_content === "0" ? "lightgray" : "",
              width: "80%",
            }}
            onChange={(e) => projectOnchange(e, 0, 10)}
            helperText={
              optionSteperror.client_details.air_content
                ? "Enter a value between 1 to 10"
                : ""
            }
            error={optionSteperror.client_details.air_content ? true : false}
          />
        </Grid>
        <Grid item xl={3} md={4} sm={12} xs={12}>
          <Selectautocomplete
            label="Early Strength (Time)"
            name="early_strength_time"
            datakey="id"
            dataValue="time"
            value={
              clientDet.early_strength_time ? clientDet.early_strength_time : ""
            }
            myOptions={earlyTime}
            onChange={(e, value) => {
              autocompleteChange("early_strength_time", value);
            }}
          />
        </Grid>
        <Grid item xl={3} md={4} sm={12} xs={12}>
          <TextBox
            label="Early Strength(Mpa)"
            name="early_strength_mpa"
            value={
              clientDet.early_strength_mpa
                ? clientDet.early_strength_mpa === "0"
                  ? ""
                  : clientDet.early_strength_mpa
                : ""
            }
            onChange={(e) => projectOnchange(e, 10, 60)}
            style={{
              backgroundColor:
                clientDet.early_strength_mpa === "0" ? "lightgray" : "",
              width: "80%",
            }}
            helperText={
              optionSteperror.client_details.early_strength_mpa
                ? "Enter a value between 10 to 60"
                : ""
            }
            
          />
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={1}
        spacing={{
          xs: 0,
          sm: 2,
          lg: 5,
          xl: 12,
        }}
        style={{ marginTop: 15 }}
      >
        <Grid item xl={3} md={4} sm={12} xs={12}>
          <TextBox
            label="Target Strength 28 days(Mpa) *"
            name="target_strength_28_days"
            value={
              clientDet.target_strength_28_days
                ? clientDet.target_strength_28_days === "0"
                  ? ""
                  : clientDet.target_strength_28_days
                : ""
            }
            style={{
              backgroundColor:
                clientDet.target_strength_28_days === "0" ? "lightgray" : "",
              width: "80%",
            }}
            onChange={(e) => projectOnchange(e, 10, 200)}
            helperText={
              stepperError && !clientDet.target_strength_28_days
                ? "Enter a value between 10 to 200"
                : optionSteperror.client_details.target_strength_28_days
                ? "Enter a value between 10 to 200"
                : ""
            }
            error={
              stepperError && !clientDet.target_strength_28_days
                ? true
                : optionSteperror.client_details.target_strength_28_days
                ? true
                : false
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ClientDetails;
