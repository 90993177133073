import React, { useEffect, useState } from "react";
import Select from "../input/SelectItem";
import TextBox from "../input/TextBox";
import { Grid } from "@mui/material";
import {
  comments,
  errorHandleObj,
  visitType,
  selectedVisitObj,
  user,
  error,
  trial_details,
  client_details,
  concrete_mixdetails,
  supporting_info,
  Admixture_Composition,
  trial_results,
  currentSteper,
  isReadOnly,
  optionalerror,
} from "../recoil/atom";
import { useRecoilState, useRecoilValue } from "recoil";
import configParam from "../Config";
import gqlQueries from "../Queries/Queries";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { Alert, TextField, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import SuccessPopup from "../input/SuccessPopup";
import { getConvertedJSON } from "../../src/recoil/Selector";
import moment from "moment";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
const Comments = () => {
  const [comment, setComment] = useRecoilState(comments);
  const [visitTypes] = useRecoilState(visitType);
  const [commentDet, setCommentDet] = useRecoilState(errorHandleObj);
  const [trDetails] = useRecoilState(trial_details);
  const [clientDet] = useRecoilState(client_details);
  const [congreteDet] = useRecoilState(concrete_mixdetails);
  const [supInfo] = useRecoilState(supporting_info);
  const [aCDet] = useRecoilState(Admixture_Composition);
  const [trRes] = useRecoilState(trial_results);
  const [value, setValue] = useRecoilState(currentSteper);
  const [message, setMessage] = useState(false);
  const [popupError, setPopuperror] = useState(false);
  const [stepperError, setStepperError] = useRecoilState(error);
  const [readOnly, setReadonly] = useRecoilState(isReadOnly);
  const statusDet = ["Success", "Fail"];
  const trialDet = ["Match", "No Match"];
  const trialData = localStorage.getItem("ChrysoVisitData");
  const trialApprovelData = localStorage.getItem("SavedTrialDetail");
  const SavedTrialId = localStorage.getItem("SavedTrialId");
  const userID = Number(localStorage.getItem("userDetails"));
  const userDet = JSON.parse(localStorage.getItem("user"));
  const [msg, setMsg] = useState("");
  const [optionSteperror, setOptionSteperror] = useRecoilState(optionalerror);
  const SavedTrialDetailData = localStorage.getItem("SavedTrialDetail");
  const SavedTrialDetail = JSON.parse(SavedTrialDetailData);
  const [isLoading, setIsLoading] = useState(false);
  const ApiURL = configParam.API_URL;
  const vlidateField = [
    "air_content",
    "alkali_content",
    "ambient_humidity",
    "ambient_temperature",
    "cement_content",
    "clear_test10",
    "clear_test50",
    "coarse_aggregate_150micpass10",
    "coarse_aggregate_150micpass20",
    "concrete_type",
    "customer_name",
    "early_strength_mpa",
    "early_strength_time",
    "enquiry_number",
    "flyash",
    "ggbfs_content",
    "microsilica_content",
    "project_details",
    "project_location",
    "project_type",
    "retention",
    "sand_category",
    "sand_passing150",
    "sand_passing600",
    "target_strength_28_days",
    "w_cm",
    "workability_flow_slump",
  ];

  let data =
    visitTypes === "1"
      ? trialData
        ? JSON.parse(trialData)
        : {}
      : trialApprovelData
      ? JSON.parse(trialApprovelData)
      : {};
  const sgid = localStorage.getItem("UserID");
  const [selectVisitData, setSelectVisitData] =
    useRecoilState(selectedVisitObj);
  const getItemNo = configParam.getVisitedItem;
  const getSavedVisitNo = configParam.getSavedTrial;
  const navigate = useNavigate();
  const convertedJSON = useRecoilValue(getConvertedJSON);
  const [userDetails] = useRecoilState(user);

  const changeCommentDet = (e) => {
    let tmpObj = { ...comment };
    tmpObj[e.target.name] = e.target.value;
    setComment(tmpObj);
  };
  useEffect(() => {
    let tempConvertedJSON = convertedJSON;
    if (visitTypes === "2") {
      setComment(tempConvertedJSON.comments);
    } else if (selectVisitData && Object.keys(selectVisitData).length > 0) {
      let SavedTrialId = getItemNo();
      let data =
        visitTypes === "1"
          ? trialData
            ? JSON.parse(trialData)
            : {}
          : trialApprovelData
          ? JSON.parse(trialApprovelData)
          : {};
      const tempObj = JSON.parse(trialApprovelData);
      if (tempObj && tempObj[sgid]) {
        if (tempObj[sgid][SavedTrialId ? SavedTrialId : 0]) {
          let obj = {
            [SavedTrialId]: {
              TrialDetails: tempConvertedJSON.TrialDetails,
              ClientDetails: tempConvertedJSON.ClientDetails,
              ConcreteDetails: tempConvertedJSON.ConcreteDetails,
              supInfo: tempConvertedJSON.supInfo,
              Admixture: tempConvertedJSON.Admixture,
              TrialResult: tempConvertedJSON.TrialResult,
              comments: tempConvertedJSON.comments,
            },
          };
          let merged = { ...tempObj[sgid], ...obj };
          data[sgid] = merged;
        } else {
          if (SavedTrialId && SavedTrialId > 0) {
            let obj = {
              [SavedTrialId]: {
                TrialDetails: tempConvertedJSON.TrialDetails,
                ClientDetails: tempConvertedJSON.ClientDetails,
                ConcreteDetails: tempConvertedJSON.ConcreteDetails,
                supInfo: tempConvertedJSON.supInfo,
                Admixture: tempConvertedJSON.Admixture,
                TrialResult: tempConvertedJSON.TrialResult,
                comments: tempConvertedJSON.comments,
              },
            };
            let merged = { ...tempObj[sgid], ...obj };
            data[sgid] = merged;
          } else {
            data[sgid] = {
              0: {
                TrialDetails: tempConvertedJSON.TrialDetails,
                ClientDetails: tempConvertedJSON.ClientDetails,
                ConcreteDetails: tempConvertedJSON.ConcreteDetails,
                supInfo: tempConvertedJSON.supInfo,
                Admixture: tempConvertedJSON.Admixture,
                TrialResult: tempConvertedJSON.TrialResult,
                comments: tempConvertedJSON.comments,
              },
            };
          }
        }
      } else {
        data[sgid] = {
          [SavedTrialId]: {
            TrialDetails: tempConvertedJSON.TrialDetails,
            ClientDetails: tempConvertedJSON.ClientDetails,
            ConcreteDetails: tempConvertedJSON.ConcreteDetails,
            supInfo: tempConvertedJSON.supInfo,
            Admixture: tempConvertedJSON.Admixture,
            TrialResult: tempConvertedJSON.TrialResult,
            comments: tempConvertedJSON.comments,
          },
        };
      }

      localStorage.setItem("ChrysoVisitData", JSON.stringify(data));
    }
  }, []);

  const onHandleSubmit = () => {
    let validation = checkAllMandatoryFields();
    if (validation) {
      setStepperError(true);
      return true;
    } else {
      for (let i = 0; i <= vlidateField.length; i++) {
        if (readOnly) {
          setStepperError(false);
          return true;
        }
        if (optionSteperror.client_details[vlidateField[i]]) {
          setStepperError(true);
          return true;
        }
      }
      const rowIndex = getItemNo();
      const value = data[sgid][rowIndex];
      if (visitTypes === "1") {
        handleInsert(value);
      } else {
        let tempConvertedJSON = convertedJSON;
        handleUpadte(tempConvertedJSON);
      }
    }
  };
  const checkAllMandatoryFields = () => {
    const atepper1MandatoryFields = ["tech_person_1", "laboratory_type"];
    let stepper1Validattion = checkAllValues(
      trDetails,
      atepper1MandatoryFields
    );
    if (stepper1Validattion) {
      setValue(0);
      return true;
    }
    const atepper2MandatoryFields = [
      "project_type",
      "concrete_type",
      "customer_name",
      "enquiry_number",
      "project_details",
      "project_location",
      "concrete_grade",
      "workability_flow_slump",
      "target_strength_28_days",
      "retention",
    ];
    let stepper2Validattion = checkAllValues(
      clientDet,
      atepper2MandatoryFields
    );
    if (stepper2Validattion) {
      setValue(1);
      return true;
    }
    const atepper3MandatoryFields = [
      "cement_brand",
      "cement_content",
      "free_water",
      "fine_aggregate_natural_sand_content",
      "fine_aggregate_crushed_sand_content",
      "coarse_aggregate_ca10mm_content",
      "coarse_aggregate_ca20mm_content",
      "other_aggregate_content",
    ];
    let stepper3Validattion = checkAllValues(
      congreteDet,
      atepper3MandatoryFields
    );
    if (stepper3Validattion) {
      setValue(2);
      return true;
    }
    const atepper4MandatoryFields = [
      "w_cm",
      "sand_passing600",
      "sand_category",
      "ambient_temperature",
      "ambient_humidity",
    ];
    let stepper4Validattion = checkAllValues(supInfo, atepper4MandatoryFields);
    if (stepper4Validattion) {
      setValue(3);
      return true;
    }
    const atepper5MandatoryFields = ["srno_Pname"];
    let stepper5Validattion = checkAllValues(aCDet, atepper5MandatoryFields);
    if (stepper5Validattion) {
      setValue(4);
      return true;
    }
    const atepper6MandatoryFields = ["dosage", "retention", "workability"];
    let stepper6Validattion = checkAllValues(trRes, atepper6MandatoryFields);
    if (stepper6Validattion) {
      setValue(5);
      return true;
    }
    const mandatoryFields = ["status"];
    let stepper7Validattion = checkAllValues(comment, mandatoryFields);
    return stepper7Validattion;
  };
  const checkAllValues = (value, mandatoryFields) => {
    let validation = false;
    for (let i = 0; i < mandatoryFields.length; i++) {
      if (value[mandatoryFields[i]] === null) {
        return true;
      }
      if (
        mandatoryFields[i] === "fine_aggregate_natural_sand_content" ||
        mandatoryFields[i] === "fine_aggregate_crushed_sand_content" ||
        mandatoryFields[i] === "coarse_aggregate_ca10mm_content" ||
        mandatoryFields[i] === "coarse_aggregate_ca20mm_content" ||
        mandatoryFields[i] === "other_aggregate_content"
      ) {
        if (
          (typeof value.aggregates[mandatoryFields[i]] === "string" &&
            !value.aggregates[mandatoryFields[i]] &&
            value.aggregates[mandatoryFields[i]].length === 0) ||
          (typeof value.aggregates[mandatoryFields[i]] === "object" &&
            Object.keys(value.aggregates[mandatoryFields[i]]).length === 0)
        ) {
          return true;
        }
      } else {
        if (
          (typeof value[mandatoryFields[i]] === "string" &&
            !value[mandatoryFields[i]] &&
            value[mandatoryFields[i]].length === 0) ||
          (typeof value[mandatoryFields[i]] === "object" &&
            Object.keys(value[mandatoryFields[i]]).length === 0) ||
          (mandatoryFields[i] === "retention" &&
            moment(value[mandatoryFields[i]].$d).format("HH:mm") === "00:00")
        ) {
          return true;
        }
      }
    }
    return validation;
  };

  const handleUpadte = () => {
    let rowIndex = getSavedVisitNo();
    let value = data[sgid][rowIndex];
    // return true;
    let ids = convertedJSON.ids;

    var tech_person_id = {};

    let retentionTime = clientDet.retention;
    let retentionHr = retentionTime["$H"] * 60;
    let retentionMin = retentionTime["$m"];
    let retention =
      typeof retentionTime === "object"
        ? parseInt(retentionHr) + parseInt(retentionMin)
        : retentionTime;

    let retentionTime1 = trRes.retention;
    let retention1Hr = retentionTime1["$H"] * 60;
    let retention1Min = retentionTime1["$m"];
    let retention1 =
      typeof retentionTime1 === "object"
        ? parseInt(retention1Hr) + parseInt(retention1Min)
        : retentionTime1;

    let url = ApiURL + "update_new_visit";
    axios
      .post(url, {
        ids: ids,
        retention: retention,
        retention1: retention1,
        trialDetails: trDetails,
        clientDetails: clientDet,
        congreteDet: congreteDet,
        supInfo: supInfo,
        aCDet: aCDet,
        trRes: trRes,
        comment: comment,
      })
      .then((res) => {
        if (
          res.data.Trial === 1 &&
          res.data.TrialDetails === 1 &&
          res.data.admixer === 1 &&
          res.data.clientDetail === 1 &&
          res.data.commandResult === 1 &&
          res.data.concrete === 1 &&
          res.data.supportingInfo === 1 &&
          res.data.trialResult === 1
        ) {
          const rowIndex = getItemNo();
          RemoveLocalStorage(rowIndex);
          setMsg("Visit resubmitted successfully");
          setMessage(true);
          setPopuperror(false);
        } else {
          setPopuperror(true);
        }
      });
  };

  const handleInsert = (value) => {
    let retentionTime = clientDet.retention;
    let retentionHr = retentionTime["$H"] * 60;
    let retentionMin = retentionTime["$m"];
    let retention =
      typeof retentionTime === "object"
        ? parseInt(retentionHr) + parseInt(retentionMin)
        : retentionTime;

    let retentionTime1 = trRes.retention;
    let retention1Hr = retentionTime1["$H"] * 60;
    let retention1Min = retentionTime1["$m"];
    let retention1 =
      typeof retentionTime1 === "object"
        ? parseInt(retention1Hr) + parseInt(retention1Min)
        : retentionTime1;

    let url = ApiURL + "create_new_visit";

    // Show the loader
    setIsLoading(true);

    axios
      .post(url, {
        retention: retention.toString(),
        retention1: retention1.toString(),
        trialDetails: trDetails,
        clientDetails: clientDet,
        congreteDet: congreteDet,
        supInfo: supInfo,
        aCDet: aCDet,
        trRes: trRes,
        comment: comment,
        user: userDet,
        submission_status:
          userDet.role_id === 3 ? "Awaiting Approval" : "Approved",
      })
      .then((res) => {
        console.log("res", res);
        if (Object.keys(res.data).length > 0) {
          if (userDet.role_id !== 3) {
            updateApprove("Approved", res.data.TrialRepoResult.raw[0].id);
          }
          setMessage(true);
          setMsg("Visit submitted successfully");
          const rowIndex = getItemNo();
          RemoveLocalStorage(rowIndex);
        } else {
          setPopuperror(true);
        }
      })
      .finally(() => {
        // Hide the loader after the request is completed
        setTimeout(() => {
          setIsLoading(false);
        }, 5000);
      });
  };

  const updateApprove = (Status, VisitID) => {
    setStepperError(false);
    let url = ApiURL + "approveTrials";
    axios
      .post(url, {
        userId: userDet["id"],
        id: VisitID,
        status: Status,
        command: comment.rejection_notes,
      })
      .then((res) => {
        if (res.data.affected > 0) {
          // setMessage(true);
          // setMsg(Status === "Approved" ? "Visit approved" : Status === "Rejected" ? "Visit rejected successfully" : "Visit resubmitted successfully");
        }
      });
  };

  const onHandleApprove = (Status) => {
    const VisitID = localStorage.getItem("DbVisitID");
    if (Status === "Rejected" && !comment.rejection_notes) {
      setStepperError(true);
      return true;
    } else {
      setStepperError(false);
      // Show the loader
      setIsLoading(true);
      let url = ApiURL + "approveTrials";
      axios
        .post(url, {
          userId: userDet["id"],
          id: VisitID,
          status: Status,
          command: comment.rejection_notes,
        })
        .then((res) => {
          if (res.data.affected > 0) {
            setMessage(true);
            setMsg(
              Status === "Approved"
                ? "Visit approved"
                : Status === "Rejected"
                ? "Visit rejected successfully"
                : "Visit resubmitted successfully"
            );
            setIsLoading(false);
          }
        });
    }
  };
  const RemoveLocalStorage = (id) => {
    const myRegenData = localStorage.getItem("ChrysoVisitData");
    const value = JSON.parse(myRegenData);

    var temptabledata = [];
    temptabledata = temptabledata.concat(
      Object.keys(value[sgid]).map(function (key) {
        return [value[sgid][key]];
      })
    );
    const data = temptabledata.filter((key, index) => index != id);
    const tepArr = data.map((val, index) => {
      return data[index][0];
    });
    var target = { [sgid]: {} };
    Object.keys(tepArr).forEach((key) => (target[sgid][key] = tepArr[key]));
    localStorage.setItem("ChrysoVisitData", JSON.stringify(target));
  };
  useEffect(() => {
    if (visitTypes === "1") {
      if (selectVisitData || Object.keys(selectVisitData).length === 0) {
        if (
          comment.status.length !== 0 ||
          comment.remarks.length !== 0 ||
          comment.rejection_notes.length !== 0 ||
          comment.ref_previous_trial_data.length !== 0 ||
          comment.cost_factor_comparison.length !== 0
        ) {
          let rowIndex;
          if (visitTypes === "1") {
            rowIndex = getItemNo();
          } else {
            rowIndex = getSavedVisitNo();
          }

          if (data[sgid]) {
            let obj = {
              TrialDetails: trDetails,
              ClientDetails: clientDet,
              ConcreteDetails: congreteDet,
              supInfo: supInfo,
              Admixture: aCDet,
              TrialResult: trRes,
              comments: comment,
            };
            let merged = { ...data[sgid][rowIndex ? rowIndex : 0], ...obj };
            data[sgid][rowIndex] = merged;
          } else {
            data[sgid] = {
              0: {
                TrialDetails: trDetails,
                ClientDetails: clientDet,
                ConcreteDetails: congreteDet,
                supInfo: supInfo,
                Admixture: aCDet,
                TrialResult: trRes,
                comments: comment,
              },
            };
          }
          if (visitTypes === "1") {
            localStorage.setItem("ChrysoVisitData", JSON.stringify(data));
          }
        }
      }
    }
  }, [comment]);

  const onHandleClosed = () => {
    setMessage(false);
    navigate("/home");
  };
  return (
    <React.Fragment>
      {message || popupError ? (
        <SuccessPopup
          style={{ width: 400 }}
          open={message || popupError}
          onClose={onHandleClosed}
          popupError={popupError}
          msg={msg}
        />
      ) : null}
      <Grid container rowSpacing={1} style={{ marginTop: 25 }}>
        <Grid
          container
          rowSpacing={1}
          spacing={{
            xs: 0,
            sm: 2,
            lg: 5,
            xl: 12,
          }}
        >
          <Grid item xl={4} lg={4} md={6} xs={12}>
            <TextBox
              label="Cost Factor Comparison"
              name="cost_factor_comparison"
              value={
                comment && comment.cost_factor_comparison
                  ? comment.cost_factor_comparison
                  : ""
              }
              onChange={changeCommentDet}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={6} xs={12}>
            <TextBox
              label="Remarks"
              name="remarks"
              isString={true}
              value={
                comment && comment.remarks
                  ? comment.remarks.toLocaleUpperCase()
                  : ""
              }
              onChange={changeCommentDet}
            />
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={1}
          spacing={{
            xs: 0,
            sm: 2,
            lg: 5,
            xl: 12,
          }}
          style={{ marginTop: 15 }}
        >
          <Grid item xl={4} lg={4} md={6} xs={12}>
            <Select
              label="Success / Fail *"
              name="status"
              isMarray={false}
              value={comment && comment.status ? comment.status : ""}
              onChange={changeCommentDet}
              data={statusDet}
              error={
                visitTypes === "1"
                  ? stepperError && !comment.status
                    ? true
                    : false
                  : false
              }
              // helperText={
              //   stepperError && !comment.status ? "Pls Enter Success" : ""
              // }
            />
          </Grid>
          <Grid item xl={4} lg={4} md={6} xs={12}>
            <Select
              label="Reference to previous trial data( Match)"
              name="ref_previous_trial_data"
              key="id"
              dataValue="type"
              value={
                comment && comment.ref_previous_trial_data
                  ? comment.ref_previous_trial_data
                  : ""
              }
              onChange={changeCommentDet}
              data={trialDet}
            />
          </Grid>
        </Grid>

        {visitTypes === "2" ? (
          <Grid container rowSpacing={1} spacing={12} style={{ marginTop: 15 }}>
            <Grid item xs={8}>
              <Typography
                variant="subtitle1"
                style={{
                  fontSize: "12px",
                  lineHeight: 1.75,
                  fontWeight: 600,
                  marginBottom: "5px",
                }}
                gutterBottom
              >
                Rejection Notes *
              </Typography>
              <TextField
                id="outlined-multiline-static"
                name="rejection_notes"
                multiline
                style={{ width: "100%" }}
                onChange={changeCommentDet}
                rows={4}
                InputProps={{
                  readOnly:
                    visitTypes === "2" && userDet.role_id == 3 ? true : false,
                }}
                value={
                  comment && comment.rejection_notes
                    ? comment.rejection_notes.toLocaleUpperCase()
                    : ""
                }
                error={stepperError && !comment.rejection_notes ? true : false}
              />
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      <div style={{ display: "flex", marginTop: 30 }}>
        {isLoading && (
          <div className="loader">
            <CircularProgress />{" "}
          </div>
        )}
        {!readOnly ? (
          visitTypes === "2" ? (
            convertedJSON.status !== "Approved" &&
            (userDet.role_id == 3 ? (
              <Button
                variant="contained"
                onClick={onHandleSubmit}
                style={{ margin: "10px" }}
              >
                ReSubmit
              </Button>
            ) : (
              <React.Fragment>
                <Button
                  variant="contained"
                  onClick={() => onHandleApprove("Approved")}
                  style={{ margin: "10px" }}
                >
                  APPROVE
                </Button>
                <Button
                  variant="contained"
                  onClick={() => onHandleApprove("Rejected")}
                  style={{ margin: "10px" }}
                >
                  REJECT
                </Button>
              </React.Fragment>
            ))
          ) : (
            <Button
              variant="contained"
              onClick={onHandleSubmit}
              style={{ margin: "10px" }}
            >
              Submit
            </Button>
          )
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default Comments;
