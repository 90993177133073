import React, { useState } from "react";
import {
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  Button,
  FormControl,
  Box,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LandImage from "../asset/landImage.jpg";
import configParam from "../Config";
import gqlQueries from "../Queries/Queries";
import { useNavigate } from "react-router-dom";

const PasswordReset = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  const [, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleLog(e);
    }
  };
  const goToLogin = () => {
    navigate("/login");
  };
  const handleLog = () => {
    if (username === "" && password === "") {
      setError("Enter SG-ID and Password");
    } else if (username === "") {
      setError("Enter SG-ID");
    } else if (password === "") {
      setError("Enter Password");
    } else {
      configParam
        .RUN_REST_API("login", { username, password }, true)
        .then((data) => {
          if (data && data.access_token && data.user) {
            localStorage.setItem("ChrysoAccessToken", data.access_token);
            localStorage.setItem("ChrysoRefreshToken", data.refresh_token);
            configParam.SET_SG_ID(data.user);
            configParam
              .RUN_GQL_API(gqlQueries.CHECK_USERS, { sgid: data.user })
              .then((data) => {
                if (data && data.chryso_users.length > 0) {
                  navigate(`/home`);
                } else {
                  localStorage.setItem("ChrysoAccessToken", "");
                  localStorage.setItem("ChrysoRefreshToken", "");
                  setError("Access denied");
                }
              });
            setLoading(false);
          } else {
            setError("incorrect credentials");
            setLoading(false);
          }
        });
    }
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item md={8} xl={9} sm={12}>
          <img
            src={LandImage}
            alt=""
            style={{
              height: "100vh",
            }}
          />
        </Grid>
        <Grid item md={4} xl={3} sm={12}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "whitesmoke",
              height: "100vh",
            }}
          >
            <div
              style={{ alignItems: "center", width: "80%", marginBottom: 20 }}
            >
              <Typography variant="h4">Reset </Typography>
              <Typography variant="subtitle2" textAlign="left">
                Please enter your details!
              </Typography>
            </div>
            <FormControl fullWidth style={{ alignItems: "center" }}>
              <Box style={{ width: "80%" }}>
                <label htmlFor="user-name">SG-ID Or Email</label>
                <TextField
                  id="user-name"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={username}
                  onInput={(e) => {
                    setError("");
                    setUsername(e.target.value);
                  }}
                  onKeyPress={handleEnter}
                />
              </Box>
            </FormControl>

            <Typography
              variant="subtitle1"
              style={{ color: "red", marginTop: "15px" }}
            >
              {error}&nbsp;
            </Typography>
            <Button
              variant="contained"
              style={{ width: "80%" }}
              onClick={handleLog}
            >
              Reset Password
            </Button>
            <div style={{ marginTop: 20 }}>
              <Typography>
                Back To Login{" "}
                <span style={{ color: "blue" }} onClick={goToLogin}>
                  Click Here{" "}
                </span>
              </Typography>
            </div>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default PasswordReset;
