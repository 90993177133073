import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import LeftContainer from "./LeftContainer";
import RightContainer from "./RightContainer";
import useMediaQuery from '@mui/material/useMediaQuery';
import { menuName } from "../recoil/atom"
import { useRecoilState } from "recoil";
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import { Typography } from "@mui/material";
const User = () => {
  const [, setMenu] = useRecoilState(menuName);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setMenu('User Management')
    setTimeout(() => {
      setIsLoading(false);
    }, 3000)
  }, []);
  
  const matches = useMediaQuery('(max-width:600px)');
  return (
    <React.Fragment>
      {isLoading ? (
        <div className="skeleton-dashboard" style={{ width: "100%", padding: "20px" }}>

          <Grid container style={{ justifyContent: "space-between", marginTop: "40px" }}>
            <Skeleton style={{ width: "29%", height: 400, transform: "none" }} />
            <Grid container style={{ width: "70%", justifyContent: "flex-end" }}>
              <Grid container style={{ justifyContent: "flex-end" }}>
                <Skeleton style={{ width: "130px", height: 40, transform: "none" }} />
              </Grid>
              <Grid container style={{ justifyContent: "space-between", marginTop: "20px" }}>
                <Skeleton style={{ width: "100%", height: 400, transform: "none" }} />
              </Grid>
            </Grid>
          </Grid>
        </div>

      ) : (
        <div sm={12}>
          <Typography
            sx={{ fontSize: 22, padding: "20px", fontWeight: "bold", color: "#212121", marginBottom: 0 }}
            variant="subtitle1"
            color="text.secondary"
            gutterBottom
          >
            USER MANAGEMENT
          </Typography>
          <Grid container rowSpacing={1} spacing={2} style={{padding: '0 20px'}}>

            <Grid item xs={12} xl={3} sm={12} style={{marginBottom: '20px'}}>
              <LeftContainer />
            </Grid>
            <Grid item xs={12} xl={9} sm={12}>
              <RightContainer />
            </Grid>
          </Grid>
        </div>
      )}

    </React.Fragment>

  );
};

export default User;
