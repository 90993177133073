import React, { useEffect, useState } from "react";
import TextBox from "../input/TextBox";
import { Grid } from "@mui/material";
import Select from "../input/SelectItem";
import {
  concrete_mixdetails,
  errorHandleObj,
  visitType,
  error,
  optionalerror
} from "../recoil/atom";
import { useRecoilState } from "recoil";
import configParam from "../Config";
import gqlQueries from "../Queries/Queries";
import Selectautocomplete from "../input/Selectautocomplete";
import axios from "axios";
const ConcreteMixDetails = () => {
  const [stepperError, setStepperError] = useRecoilState(error);
  const [congreteDet, setcongreteDet] = useRecoilState(concrete_mixdetails);
  const [optionSteperror, setOptionSteperror] = useRecoilState(optionalerror);
  const [congDet, setCongDet] = useRecoilState(errorHandleObj);
  const [cementBrand, setCementBrand] = useState([]);
  const trialData = localStorage.getItem("ChrysoVisitData");
  const sgid = localStorage.getItem("UserID");
  const getItemNo = configParam.getVisitedItem;
  const [visitTypes] = useRecoilState(visitType);
  const trialApprovelData = localStorage.getItem("TrialApprovelData");
  const SavedTrialId = localStorage.getItem("SavedTrialId");
  const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));
  const ApiURL = configParam.API_URL;
  const SavedTrialDetailData = localStorage.getItem("SavedTrialDetail");
  const SavedTrialDetail = JSON.parse(SavedTrialDetailData);

  useEffect(() => {
    getCementBrand();
  }, []);

  const getCementBrand = () => {
    let url = ApiURL + "get_chryso_cement_brand"
    axios.get(url).then((res) => {
      if (res.data.length > 0) {
        setCementBrand(res.data)
      }
    })
    // configParam.RUN_GQL_API(gqlQueries.GET_CEMENT_BRAND, {}).then((data) => {
    //   setCementBrand(data.chryso_cement_brand);
    // });
  };

  let data =
    visitTypes === "1"
      ? trialData
        ? JSON.parse(trialData)
        : {}
      : trialApprovelData
        ? JSON.parse(trialApprovelData)
        : {};
  const getSavedVisitNo = configParam.getSavedTrial;

  const changeMinMax = (e, min, max) => {
    let tmpObj = deepCopy({ ...congreteDet });
    let temError = { ...optionSteperror }
    let obj = { ...temError.client_details }
    if (e.target.value < min || e.target.value > max) {
      obj[e.target.name] = true;
      temError["client_details"] = obj
      setOptionSteperror(temError);
    }
    else {
      setStepperError(false);
      obj[e.target.name] = false;
      temError["client_details"] = obj
      setOptionSteperror(temError);

    }
    tmpObj[e.target.name] = e.target.value;
    setcongreteDet(tmpObj);
  }

  const changeConcreteDet = (e) => {
    let tmpObj = deepCopy({ ...congreteDet });
    if (
      e.target.name === "fine_aggregate_natural_sand_content" ||
      e.target.name === "fine_aggregate_crushed_sand_content" ||
      e.target.name === "coarse_aggregate_ca10mm_content" ||
      e.target.name === "coarse_aggregate_ca20mm_content" ||
      e.target.name === "other_aggregate_content"
    ) {
      tmpObj["aggregates"][e.target.name] = e.target.value;
    }
    else {
      tmpObj[e.target.name] = e.target.value;
    }

    setcongreteDet(tmpObj);
  };

  const autocompleteChange = (key, value) => {
    let tmpObj = { ...congreteDet };
    tmpObj[key] = value;
    setcongreteDet(tmpObj);
  }


  useEffect(() => {
    let rowIndex;
    if (visitTypes === "1") {
      rowIndex = getItemNo();
    } else {
      rowIndex = getSavedVisitNo();
    }
    if (data[sgid]) {
      let obj = { ConcreteDetails: congreteDet };
      let merged = { ...data[sgid][rowIndex ? rowIndex : 0], ...obj };
      data[sgid][rowIndex] = merged;
    } else {
      data[sgid] = { 0: { ConcreteDetails: congreteDet } };
    }
    if (visitTypes === "1") {
      localStorage.setItem("ChrysoVisitData", JSON.stringify(data));
    } else {
      localStorage.setItem("TrialApprovelData", JSON.stringify(data));
    }
  }, [congreteDet]);

  return (
    <React.Fragment>
      <Grid container style={{ marginTop: 25 }}>
        <Grid container rowSpacing={1} spacing={{
          xs: 0,
          sm: 2,
          lg: 5,
          xl: 12
        }}>
          <Grid item xs={12} md={4} xl={3}>
            <Selectautocomplete
              label="Cement Brand/Grade/Source *"
              name="cement_brand"
              datakey="id"
              dataValue="type"
              value={congreteDet.cement_brand ? congreteDet.cement_brand : ""}
              myOptions={cementBrand}
              onChange={(e, value) => {
                autocompleteChange("cement_brand", value);
              }}
              error={stepperError && !congreteDet.cement_brand ? true : false}
            />
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
            <TextBox
              label="Cement Content (Kg) *"
              name="cement_content"
              value={congreteDet.cement_content ? congreteDet.cement_content === "0" ? "" : congreteDet.cement_content : ""}
              style={{ backgroundColor: congreteDet.cement_content === "0" ? "lightgray" : "", width: "80%" }}
              onChange={(e) => changeMinMax(e, 0, 700)}
              helperText={
                stepperError && !congreteDet.cement_content ? "Enter a value between 0 to 700" :
                  (optionSteperror.client_details.cement_content ? "Enter a value between 0 to 700" : "")}

              error={
                (stepperError && !congreteDet.cement_content) ? true :
                  (optionSteperror.client_details.cement_content ? true : false)
              }
            />
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
            <TextBox
              label="Flyash Content (Kg)"
              name="flyash"
              value={congreteDet.flyash ? congreteDet.flyash === "0" ? "" : congreteDet.flyash : ""}
              style={{ backgroundColor: congreteDet.flyash === "0" ? "lightgray" : "", width: "80%" }}
              onChange={(e) => changeMinMax(e, 0, 400)}
              helperText={optionSteperror.client_details.flyash ? "Enter a value between 0 to 400" : ""}
              error={
                optionSteperror.client_details.flyash ? true : false
              }
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} spacing={{
          xs: 0,
          sm: 2,
          lg: 5,
          xl: 12
        }} style={{ marginTop: 15 }}>
          <Grid item xs={12} md={4} xl={3}>
            <TextBox
              label="GGBFS Content (Kg)"
              name="ggbfs_content"
              value={congreteDet.ggbfs_content ? congreteDet.ggbfs_content === "0" ? "" : congreteDet.ggbfs_content : ""}
              style={{ backgroundColor: congreteDet.ggbfs_content === "0" ? "lightgray" : "", width: "80%" }}
              onChange={(e) => changeMinMax(e, 0, 400)}
              helperText={optionSteperror.client_details.ggbfs_content ? "Enter a value between 0 to 400" : ""}
              error={
                optionSteperror.client_details.ggbfs_content ? true : false
              }

            />
          </Grid>
          <Grid item md={4} xl={3} xs={12}>
            <TextBox
              label="Microsilica Content (Kg)"
              name="microsilica_content"
              value={congreteDet.microsilica_content ? congreteDet.microsilica_content === "0" ? "" : congreteDet.microsilica_content : ""}
              style={{ backgroundColor: congreteDet.microsilica_content === "0" ? "lightgray" : "", width: "80%" }}
              onChange={(e) => changeMinMax(e, 0, 100)}
              helperText={optionSteperror.client_details.microsilica_content ? "Enter a value between 0 to 100" : ""}
              error={
                optionSteperror.client_details.microsilica_content ? true : false
              }


            />
          </Grid>
          <Grid item sm={12} md={4} xl={3} xs={12}>
            <TextBox
              label="Fine Aggregate (Natural Sand) Content (Kg) *"
              name="fine_aggregate_natural_sand_content"
              value={congreteDet.aggregates && congreteDet.aggregates.fine_aggregate_natural_sand_content ? congreteDet.aggregates.fine_aggregate_natural_sand_content === "0" ? "" : congreteDet.aggregates.fine_aggregate_natural_sand_content : ""}
              style={{ backgroundColor: congreteDet.aggregates.fine_aggregate_natural_sand_content === "0" ? "lightgray" : "", width: "80%" }}
              onChange={changeConcreteDet}
              error={
                stepperError &&
                  !congreteDet.aggregates.fine_aggregate_natural_sand_content
                  ? true
                  : false
              }
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} spacing={{
          xs: 0,
          sm: 2,
          lg: 5,
          xl: 12
        }} style={{ marginTop: 15 }}>
          <Grid item xs={12} md={4} xl={3}>
            <TextBox
              label="Fine Aggregate (Crushed Sand) Content (Kg) *"
              name="fine_aggregate_crushed_sand_content"
              value={congreteDet.aggregates && congreteDet.aggregates.fine_aggregate_crushed_sand_content ? congreteDet.aggregates.fine_aggregate_crushed_sand_content === "0" ? "" : congreteDet.aggregates.fine_aggregate_crushed_sand_content : ""}
              style={{ backgroundColor: congreteDet.aggregates.fine_aggregate_crushed_sand_content === "0" ? "lightgray" : "", width: "80%" }}
              onChange={changeConcreteDet}
              error={
                stepperError &&
                  !congreteDet.aggregates.fine_aggregate_crushed_sand_content
                  ? true
                  : false
              }

            />
          </Grid>
          <Grid item sm={12} md={4} xl={3} xs={12}>
            <TextBox
              label="Coarse Aggregate (CA 10mm) Content (Kg) *"
              name="coarse_aggregate_ca10mm_content"
              value={congreteDet.aggregates && congreteDet.aggregates.coarse_aggregate_ca10mm_content ? congreteDet.aggregates.coarse_aggregate_ca10mm_content === "0" ? "" : congreteDet.aggregates.coarse_aggregate_ca10mm_content : ""}
              style={{ backgroundColor: congreteDet.aggregates.coarse_aggregate_ca10mm_content === "0" ? "lightgray" : "", width: "80%" }}
              onChange={changeConcreteDet}
              error={
                stepperError &&
                  !congreteDet.aggregates.coarse_aggregate_ca10mm_content
                  ? true
                  : false
              }
            />
          </Grid>

          <Grid item xs={12} md={4} xl={3}>
            <TextBox
              label="Coarse Aggregate (CA20mm) Content (Kg) *"
              name="coarse_aggregate_ca20mm_content"
              value={congreteDet.aggregates && congreteDet.aggregates.coarse_aggregate_ca20mm_content ? congreteDet.aggregates.coarse_aggregate_ca20mm_content === "0" ? "" : congreteDet.aggregates.coarse_aggregate_ca20mm_content : ""}
              style={{ backgroundColor: congreteDet.aggregates.coarse_aggregate_ca20mm_content === "0" ? "lightgray" : "", width: "80%" }}
              onChange={changeConcreteDet}
              error={
                stepperError &&
                  !congreteDet.aggregates.coarse_aggregate_ca20mm_content
                  ? true
                  : false
              }
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} spacing={{
          xs: 0,
          sm: 2,
          lg: 5,
          xl: 12
        }} style={{ marginTop: 15 }}>
          <Grid item xs={12} md={4} xl={3}>
            <TextBox
              label="Other Aggregate (Special if any) Content (Kg) *"
              name="other_aggregate_content"
              value={congreteDet.aggregates && congreteDet.aggregates.other_aggregate_content ? congreteDet.aggregates.other_aggregate_content === "0" && congreteDet.aggregates.other_aggregate_content ? "" : congreteDet.aggregates.other_aggregate_content : ""}
              style={{ backgroundColor: congreteDet.aggregates.other_aggregate_content === "0" ? "lightgray" : "", width: "80%" }}
              onChange={changeConcreteDet}
              error={
                stepperError && !congreteDet.aggregates.other_aggregate_content
                  ? true
                  : false
              }
            />
          </Grid>
          <Grid item sm={12} md={4} xl={3} xs={12}>
            <TextBox
              label="Free Water (Kg) *"
              name="free_water"
              value={congreteDet.free_water ? congreteDet.free_water === "0" ? "" : congreteDet.free_water : ""}
              style={{ backgroundColor: congreteDet.free_water === "0" ? "lightgray" : "", width: "80%" }}
              onChange={(e) => changeConcreteDet(e)}
              error={stepperError && !congreteDet.free_water ? true : false}
            />
          </Grid>
        </Grid>
      </Grid>

    </React.Fragment>
  );
};

export default ConcreteMixDetails;
