import { promiseSetRecoil } from "recoil-outside";
import { apiTimeout } from "./recoil/atom";
import decode from "jwt-decode";
import moment from 'moment';
const _get_app_url = function () {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return "https://dev-sgcc.saint-gobain.com";
    case "prod":
      return "https://sgcc.saint-gobain.com";
    default:
      return "http://localhost:3010";
  }
};
const _get_redirection_url = function () {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return "https://dev-sgcc.saint-gobain.com";
    case "prod":
      return "https://sgcc.saint-gobain.com";
    default:
      return "http://localhost:3000";
  }
};

const _get_api_url = function () {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return "https://dev-auth-sgcc.saint-gobain.com/";
    case "prod":
      return "https://sgcc-auth.saint-gobain.com/";
    default:
      return "http://localhost:3044/";
  }
};

const _get_sso_url = function () {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return 'https://uat.cloudgateway.saint-gobain.com/openam/oauth2/realms/root/realms/AccessManagement/authorize?response_type=code&client_id=POC_Chryso_Dashboard&redirect_uri=https://dev-auth-sgcc.saint-gobain.com/chryso/v2/auth/login&service=simple&scope=stGoSGI';
    case "prod":
      return 'https://cloudsso.saint-gobain.com/openam/oauth2/realms/root/realms/AccessManagement/authorize?response_type=code&client_id=Chryso_PROD&redirect_uri=https://sgcc-auth.saint-gobain.com/chryso/v2/auth/login&service=simple&scope=stGoSGI';
    default:
      return 'https://uat.cloudgateway.saint-gobain.com/openam/oauth2/realms/root/realms/AccessManagement/authorize?response_type=code&client_id=POC_Chryso_Dashboard&redirect_uri=http://localhost:3044/chryso/v2/auth/login&service=simple&scope=stGoSGI';
  }
}
const _get_auth_url = function () {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return "https://dev-auth-sgcc.saint-gobain.com/chryso/v2/auth/";
    case "prod":
      return "https://sgcc-auth.saint-gobain.com/chryso/v2/auth/";
    default:
      return "https://dev-auth-sgcc.saint-gobain.com/chryso/v2/auth/";
  }
};
const _get_graphql_url = () => {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return "https://quick-rat-20.hasura.app/v1/graphql";
    case "prod":
      return "https://evolving-seasnail-79.hasura.app/v1/graphql";
    default:
      return "https://quick-rat-20.hasura.app/v1/graphql";
  }
};
const _get_month_list = (fromDate, toDate, intravel) => {
  var startDate = moment(fromDate);
  var endDate = moment(toDate);

  var dates = [];
  endDate.subtract(1, intravel); //Substract one month to exclude endDate itself

  var month = moment(startDate); //clone the startDate
  while (month < endDate) {
    month.add(1, intravel);
    dates.push(month.format('YYYY-MM-DD'));
  }
  return dates;
}
const _run_rest_api = async (url, data, post, retries = 3) => {
  let controller = new AbortController();
  let signal = controller.signal;
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  setTimeout(() => controller.abort(), 60000);
  let reqOptions = {
    method: post ? "POST" : "GET",
    signal: signal,
  };

  let urlLink = _get_auth_url() + url;

  let urlApp = _get_app_url();

  if (url === "login") {
    urlLink += `?app=${urlApp}&tenant_id=fac735fa-66ab-4433-aef0-928c230401fe`;
  }
  if (post) {
    reqOptions.body = JSON.stringify(data);
    reqOptions.redirect = "follow";
  } else {
    let token = localStorage.getItem("ChrysoAccessToken");
    myHeaders.append("Authorization", "Bearer " + token.replace(/['"]+/g, ""));
  }

  reqOptions.headers = myHeaders;
  return fetch(urlLink, reqOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((e) => {
      if (retries > 0) return _run_rest_api(url, data, post, retries - 1);
      else {
        // promiseSetRecoil(apiTimeout, true);
        return e;
      }
    });
};

const _check_and_get_token = async (existingTokens) => {
  try {
    let decodedToken = decode(existingTokens, { complete: true });
    let dateNow = new Date();
    let ExpTime = new Date().getTime();
    let exptime = decodedToken.exp - parseInt(dateNow.getTime() / 1000)

    // console.log("currtime", exptime)
    // console.log("Math.round(decodedToken.exp * 1000)", Math.round(decodedToken.exp * 1000));
    // console.log("Math.round(decodedToken.exp * 1000)", Math.round(Date.now()));

    if (
      Math.round(decodedToken.exp * 1000) < Math.round(Date.now())
    ) {
      (async () => {
        let myHeaders = new Headers();
        myHeaders.append("Cache-Control", "no-cache");
        myHeaders.append("Content-Type", "application/json");
        let url = configParam.AUTH_URL + "refresh";
        await fetch(url, {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({ refresh_token: existingTokens }),
        })
          .then((response) => response.json())
          .then(async (result) => {
            if (Object.keys(result).length > 0) {
              localStorage.setItem("ChrysoAccessToken", result["access_token"]);
              return result["access_token"];
            } else {
              console.log("no response from refresh token hit");
            }
          })
          .catch((error) => console.log("error", error));
      })();
    } else {
      return existingTokens;
    }
  } catch (e) {
    return "";
  }
};
const _run_gql_api = async (query, variables, retries = 3) => {
  let accessToken = localStorage.getItem("ChrysoAccessToken");
  let controller = new AbortController();
  let signal = controller.signal;
  let token = await _check_and_get_token(accessToken.replace(/['"]+/g, ""));
  if (!token) {
    localStorage.setItem("ChrysoAccessToken", "");
    localStorage.setItem("ChrysoRefreshToken", "");
    localStorage.setItem("userDetails", "");
    window.location.pathname = "/login";
  }
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cache-Control", "no-cache");
  myHeaders.append("Authorization", "Bearer " + token);
  setTimeout(() => controller.abort(), 20000);
  return fetch(_get_graphql_url(), {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ query: query, variables: variables }),
    signal: signal,
  })
    .then((response) => response.json())
    .then((result) => {
      if (
        result.errorTitle === "Authentication error" &&
        (result.errorMessage === "invalid signature" ||
          result.errorMessage === "jwt expired")
      ) {
        localStorage.setItem("ChrysoAccessToken", "");
        localStorage.setItem("ChrysoRefreshToken", "");
        localStorage.setItem("userDetails", "");
        window.location.pathname = "/login";
      } else {
        return result["data"];
      }
    })
    .catch((e) => {
      if (retries > 0) return _run_gql_api(query, variables, retries - 1);
      else {
        promiseSetRecoil(apiTimeout, true);
        return e;
      }
    });
};

const setSgId = (id) => localStorage.setItem("UserID", id);
const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

const SGid = function () {
  const UserId = localStorage.getItem("UserID");
  return UserId;
};

const SetVisitNo = (VisitID) => localStorage.setItem("VisitID", VisitID);
const getData = () => localStorage.getItem("ChrysoVisitData");
const getVisitedData = () => localStorage.getItem("VisitID");
const getSavedTrial = () => localStorage.getItem("SavedTrialId");
const setVisitedData = (id) => localStorage.setItem("VisitID", id);
const getTrialVisitedData = () => localStorage.getItem("TrialVisitID");
// const Visitid = function () {
//   const VisitId = localStorage.getItem("VisitID");
//   return VisitId;
// };

// step
// updateStep: step => updateCurVisitData(step, "activeStep");
const _get_visit_no = function () {
  const ChrysoVisitData = localStorage.getItem("ChrysoVisitData");
  const UserId = localStorage.getItem("UserID");
  let data = JSON.parse(ChrysoVisitData);
  let keys = data ? data[UserId] : {};
  return keys ? Object.keys(keys).length : 0;
};
const _get_Trialvisit_no = function () {
  const approvalTrial = localStorage.getItem("ApprovalTrialData");
  const UserId = localStorage.getItem("UserID");
  let TrialData = JSON.parse(approvalTrial);
  const keys = TrialData ? TrialData[UserId] : {};
  return Object.keys(keys).length;
};

export const configParam = {
  primaryColor: "#FF6D04",
  lightBackground: "#FC8C200C",
  grey: "#D8D8D8",
  API_URL: _get_api_url(),
  DEEP_COPY: deepCopy,
  SG_ID: SGid(),
  // VISIT_ID: Visitid(),
  GET_DATA: getData(),
  VISIT_NO: _get_visit_no,
  CHECK_TOKEN: _check_and_get_token,
  TRIAL_VISIT_NO: _get_Trialvisit_no,
  getVisitedItem: getVisitedData,
  getSavedTrial: getSavedTrial,
  setVisitedItem: setVisitedData,
  getTrialVisit: getTrialVisitedData,
  RUN_REST_API: _run_rest_api,
  AUTH_URL: _get_auth_url(),
  REDIRECTION_URL: _get_redirection_url,
  SET_SG_ID: setSgId,
  APP_URL: _get_app_url(),
  GET_MONTH_LIST: _get_month_list,
  SetVisitNo: SetVisitNo,
  RUN_GQL_API: _run_gql_api,
  RUN_SSO_URL: _get_sso_url,
};

export default configParam;
