import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import TextBox from "../input/TextBox";
import gqlQueries from "../Queries/Queries";
import configParam from "../Config";
import Select from "../input/SelectItem";
import axios from "axios";

const UserForm = (props) => {
  const ApiURL = configParam.API_URL;
  const [zone, setZone] = useState([]);
  const [role, setRole] = useState([]);
  const [ZoneId, SetZoneId] = useState("");
  const [RoleId, SetRoleId] = useState("");
  const [error, setError] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const fnameRef = useRef();
  const lnameRef = useRef();
  const eMailRef = useRef();
  const sgIdRef = useRef();
  const zoneIdRef = useRef();
  const roleIdRef = useRef();
  useEffect(() => {
    let Zoneurl = ApiURL + 'zone_list';
    axios.get(Zoneurl).then((res) => {
      if (res.data.length > 0) {
        console.log("zone", res.data);
        setZone(res.data);
      } else {
        setZone([]);
      }
    });
    let url = ApiURL + 'getRoleList';
    axios.get(url).then((res) => {
      if (res.data.length > 0) {
        setRole(res.data);
        props.setStatus(true);
        props.handleClose();
      } else {
        setRole([]);
      }
    });
    // configParam.RUN_GQL_API(gqlQueries.GETRole, {}).then((role) => {
    //   if (role.chryso_roles.length > 0) {
    //     setRole(role.chryso_roles);
    //     props.setStatus(true);
    //     props.handleClose();
    //   } else {
    //     setRole([]);
    //   }
    // });
  }, []);

  const onHandleSave = () => {
    if (
      lnameRef.current.value === "" ||
      fnameRef.current.value === "" ||
      eMailRef.current.value === "" ||
      ZoneId === "" ||
      RoleId === ""
    ) {
      setError(true);
      return true;
    } else {
      setError(false);
    }
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!regex.test(eMailRef.current.value)) {
      setEmailErr(true);
      return true;
    } else {
      setEmailErr(false);
    }
    if (!props.data.id) {
      let url = ApiURL + 'create_user';
      const userDetails = {
        last_name: lnameRef.current.value,
        first_name: fnameRef.current.value,
        email_id: eMailRef.current.value,
        sgid: sgIdRef.current.value,
        zone_id: ZoneId,
        role_id: RoleId,
        // id: props.data.id,
      }
      axios.post(url, userDetails).then((response) => {
        if (response.data.raw.length) {
          props.setStatus(true);
          props.handleClose();
          props.onHandleAlert();
        }
      }).catch((error) => {
        console.log("get_current_users", error);
      });
    } else {
      let url = ApiURL + 'update_users';
      const userDetails = {
        last_name: lnameRef.current.value,
        first_name: fnameRef.current.value,
        email_id: eMailRef.current.value,
        sgid: sgIdRef.current.value,
        zone_id: ZoneId,
        role_id: RoleId,
        id: props.data.id,
      }
      axios.post(url, userDetails).then((response) => {
        if (response.data.affected) {
          props.setStatus(true);
          props.handleClose();
          props.onHandleAlert();
        }
      }).catch((error) => {
        console.log("get_current_users", error);
      });

    }
  };
  useEffect(() => {
    setError(false);
    setEmailErr(false);

    setTimeout(function () {
      setFeildInitialValue();
      props.setStatus(false);
    }, 50);
  }, [props.open]);

  const setFeildInitialValue = () => {
    if (props.open) {
      fnameRef.current.value = props.data.first_name ? props.data.first_name : "";
      lnameRef.current.value = props.data.last_name ? props.data.last_name : "";
      eMailRef.current.value = props.data.email_id ? props.data.email_id : "";
      sgIdRef.current.value = props.data.sgid ? props.data.sgid : "";
      SetZoneId(props.data.zone_id ? props.data.zone_id : "");
      SetRoleId(props.data.role_id ? props.data.role_id : "");
    }

  };
  const onChangeValue = (event) => {
    setError(false);
    setEmailErr(false);
    if (event.target.name === "ZoneId") {
      SetZoneId(event.target.value);
    } else {
      SetRoleId(event.target.value);
    }
  };
  return (
    <div>
      <Dialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
          {props.type}
        </DialogTitle>
        <DialogContent dividers>
          <div>
            <TextBox
              label="First Name"
              name="first_name"
              isString={true}
              onChange={props.onchange}
              inputRef={fnameRef}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <TextBox
              label="Last Name"
              name="last_name"
              isString={true}
              inputRef={lnameRef}
              onChange={props.onchange}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <TextBox
              label="Email"
              name="email_id"
              isString={true}
              inputRef={eMailRef}
              onChange={props.onchange}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <TextBox
              label="SGID"
              name="sgid"
              isString={true}
              inputRef={sgIdRef}
              onChange={props.onchange}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <Select
              label="Zone"
              name="ZoneId"
              value={ZoneId}
              inputRef={zoneIdRef}
              datakey="id"
              dataValue="name"
              onChange={onChangeValue}
              data={zone}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <Select
              label="Role"
              name="role_id"
              value={RoleId}
              datakey="id"
              dataValue="role"
              inputRef={roleIdRef}
              onChange={onChangeValue}
              data={role}
            />
          </div>
          {error ? (
            <Typography variant="subtitle2" color="red" style={{ fontSize: "12px" }}>
              {" "}
              Please Enter All Feilds
            </Typography>
          ) : null}
          {emailErr ? (
            <Typography variant="subtitle2" color="red">
              {" "}
              Invalid email format
            </Typography>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onHandleSave} variant="contained">
            Save
          </Button>
          <Button variant="outlined"
            onClick={() => {
              setError(false);
              setEmailErr(false);
              props.handleClose();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserForm;
