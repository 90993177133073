import React from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import useMediaQuery from '@mui/material/useMediaQuery';
const SearchInput = ({ value, onChange, placeholder, onKeyPress, clearInputField }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <div style={{marginTop:4}}>
    <TextField
    variant="outlined"
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    onKeyPress={onKeyPress}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          {value && 
            <IconButton onClick={clearInputField}>
              <ClearIcon />
            </IconButton>
           }
        </InputAdornment>
      )
    }}
    inputProps={{
      style: { 
        borderRadius: 5, 
        width: isMobile ? 150 :250, 
        maxHeight: 50, 
        overflowY: 'hidden', 
        backgroundColor: 'transparent', 
        lineHeight: 'unset', 
        padding: '10px' 
      }, 
      autoComplete: 'off' 
    }}
  />
  </div>
  );
};

export default SearchInput;




