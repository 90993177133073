import React from "react";
import { FormHelperText, TextField, Typography } from "@mui/material";


const TextBox = (props) => {
  return (
    <React.Fragment>
      <Typography style={{ fontSize: "12px", lineHeight: 1.75, fontWeight: 600, marginBottom: "5px" }}>{props.label}</Typography>
      <TextField
      style= {{ width: "100%",color: "#000" }}
        sx={{
          "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
            {
              WebkitAppearance: "none",
              margin: 0,
            },
          "input[type=number]": {
            MozAppearance: "textfield",
          },
          
        }}
        // inputProps={{ style: { color: '#000' } }}
        required={props.required ? props.required : null}
        size="small"
        id={props.id}
        type={props.isString ? "string" : "number"}
        isNumber={props.isNumber ? "number" : "text"}
        value={props.value}
        inputProps={props.inputProps}
        name={props.name}
        onChange={props.onChange}
        inputRef={props.inputRef}
        error={props.error ? props.error : false}
        // helperText={props.helperText ? props.helperText : ""}
        disabled={props.disabled ? props.disabled : false}
      />
      <FormHelperText style= {{ fontSize: "12px",color: "#d32f2f" }}>{props.helperText ? props.helperText : ""}</FormHelperText>
    </React.Fragment>
  );
};

export default TextBox;
