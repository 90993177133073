import { saveAs } from "file-saver";
import * as ExcelJS from "exceljs";


function printHeaders(worksheet, headerData, column, filterRow) {
  headerData.forEach((row, rowIndex) => {
    row.forEach((cellValue, columnIndex) => {
      const cell = worksheet.getCell(
        filterRow ? filterRow :1,
        columnIndex + column
      );
      cell.value = cellValue;
      cell.style = {
        font: { bold: true },
        alignment: { vertical: "middle", horizontal: "center" },
      };
      worksheet.getColumn(columnIndex + column).width = 25;
    });
  });
}

function getTrialDetails(worksheet) {
  const trialDetails = [
    [
      "Trial No",
      "Date of Trial",
      "Primary Technical Person",
      "Secondary Technical Person",
      "Laboratory Type",
    ],
  ];
  // worksheet.mergeCells("A3:E3");
  // worksheet.getCell("A3").value = "Trial Details";
  // worksheet.getCell("A3").style = {
  //   font: { bold: true, color: "ffffff" },
  //   fill: { type: "pattern", pattern: "solid", fgColor: { argb: "42a4f5" } },
  //   alignment: { vertical: "middle", horizontal: "center" }, // Centered text vertically and horizontally
  // };
  printHeaders(worksheet, trialDetails, 1);
}

function getClientDetails(worksheet) {
  const clientDetails = [
    [
      "Type of Project",
      "Type of Concrete",
      "Customer Name",
      "Enquiry No",
      "Project Details",
      "Project Location",
      "Grade of concrete",
      "Retention(Mins)",
      "Workability Flow/ Slump (mm)",
      "Air Content %",
      "Early Strength (Time)",
      "Early Strength(Mpa)",
      "Target Strength 28 days(Mpa)",
    ],
  ];
  // worksheet.mergeCells("F3:R3");
  // worksheet.getCell("F3").value = "Client Details & Parameters";
  // worksheet.getCell("F3").style = {
  //   font: { bold: true, color: "ffffff" },
  //   fill: { type: "pattern", pattern: "solid", fgColor: { argb: "f5c242" } },
  //   alignment: { vertical: "middle", horizontal: "center" }, // Centered text vertically and horizontally
  // };
  printHeaders(worksheet, clientDetails, 6);
}

function getConcreteMixDesignDetails(worksheet) {
  const concreteMixDesignDetails = [
    [
      "Cement Brand/Grade/Source",
      "Cement Content (Kg)",
      "Flyash Content (Kg)",
      "GGBFS Content (Kg)",
      "Microsilica Content Content (Kg)",
      "Fine Aggregate (Natural Sand) Content (Kg)",
      "Fine Aggregate (Crushed Sand) Content (Kg)",
      "Coarse Aggregate (CA 10mm) Content (Kg)",
      "Coarse Aggregate CA20mm) Content (Kg)",
      "Other Aggregate( Special if any) Content (Kg)",
      "Free Water(Kg)",
    ],
  ];

  // worksheet.mergeCells("S3:AC3");
  // worksheet.getCell("S3").value = "Concrete Mix Design Details";
  // worksheet.getCell("S3").style = {
  //   font: { bold: true, color: "ffffff" },
  //   fill: { type: "pattern", pattern: "solid", fgColor: { argb: "cef542" } },
  //   alignment: { vertical: "middle", horizontal: "center", wrapText: true }, // Centered text vertically and horizontally
  // };
  printHeaders(worksheet, concreteMixDesignDetails, 19);
}

function getSupportingInfo(worksheet) {
  const supportingInfo = [
    [
      "W/CM",
      "Alkali content in cement",
      "Sand Passing % in 600 µ",
      "Sand Passing % in 150 µ",
      "Sand Category",
      "Clear Test 10",
      "Clear Test 50",
      "Coarse Aggregate 150 micron passing 20mm",
      "Coarse Aggregate 150 micron passing 10mm",
      "Ambient Temperature",
      "Ambient Humidity",
    ],
  ];

  // worksheet.mergeCells("AD3:AN3");
  // worksheet.getCell("AD3").value = "Supporting Information (Mix Design)";
  // worksheet.getCell("AD3").style = {
  //   font: { bold: true, color: "ffffff" },
  //   fill: { type: "pattern", pattern: "solid", fgColor: { argb: "f5c842" } },
  //   alignment: { vertical: "middle", horizontal: "center", wrapText: true }, // Centered text vertically and horizontally
  // };
  printHeaders(worksheet, supportingInfo, 30);
}
function getAdmixtureComposition(worksheet) {
  const admixture = [
    [
      "P20",
      "P15",
      "P16",
      "P18/HR150",
      "P11",
      "P27/R650",
      "P25/A19",
      "P26",
      "PR1",
      "SNF/N5",
      "L1(Ligno50%)",
      "Ligno",
      "GN1",
      "GN2",
      "G1",
      "Gly",
      "DF1",
      "DF2",
      "DF3",
      "CF",
      "CR/1",
      "CA",
      "AC1",
      "AC2/AC3",
      "AC5/AC6",
      "AC6",
      "AR1",
      "AR2",
      "AR3",
      "AR4",
      "AR5",
      "AR6",
      "AR7",
      "A15/P24",
      "B29/ P23",
      "N43",
      "VMA1",
      "VMA2",
      "Other ",
      "Sample Ref No/Product Name",
    ],
  ];

  // worksheet.mergeCells("AO3:CB3");
  // worksheet.getCell("AO3").value =
  //   "Admixture Composition (In gm/Kg of Product)";
  // worksheet.getCell("AO3").style = {
  //   font: { bold: true, color: "ffffff" },
  //   fill: { type: "pattern", pattern: "solid", fgColor: { argb: "c2f542" } },
  //   alignment: { vertical: "middle", horizontal: "center", wrapText: true }, // Centered text vertically and horizontally
  // };
  printHeaders(worksheet, admixture, 41);
}
function getTrialResults(worksheet) {
  const trialResult = [
    [
      "Dosage %",
      "Retention(Mins)",
      "Workability(mm)",
      "Air Content %",
      "Early Strength (Mpa)",
      "Strength at 7 Days(Mpa)",
      "Strength at 28 Days(Mpa)",
    ],
  ];

  // worksheet.mergeCells("CC3:CI3");
  // worksheet.getCell("CC3").value = "Trial Results Obtained";
  // worksheet.getCell("CC3").style = {
  //   font: { bold: true, color: "ffffff" },
  //   fill: { type: "pattern", pattern: "solid", fgColor: { argb: "f5c842" } },
  //   alignment: { vertical: "middle", horizontal: "center", wrapText: true }, // Centered text vertically and horizontally
  // };
  printHeaders(worksheet, trialResult, 81);
}

function getComments(worksheet) {
  const comments = [
    [
      "Cost Factor Comparison",
      "Remarks",
      "Success / Fail",
      "Reference to previous trial data( Match)",
      "Rejection Notes",
    ],
  ];

  // worksheet.mergeCells("CJ3:CN3");
  // worksheet.getCell("CJ3").value = "Comments";
  // worksheet.getCell("CJ3").style = {
  //   font: { bold: true, color: "ffffff" },
  //   fill: { type: "pattern", pattern: "solid", fgColor: { argb: "42f593" } },
  //   alignment: { vertical: "middle", horizontal: "center", wrapText: true }, 
  // };
  printHeaders(worksheet, comments, 88);
}

function filterHeader(worksheet) {
  worksheet.mergeCells("A1:H1");
  worksheet.getCell("A1").value = "Selected Filters";
  worksheet.getCell("A1").style = {
    font: { bold: true },
    fill: { type: "pattern", pattern: "solid", fgColor: { argb: "42f593" } },
    alignment: { vertical: "middle", horizontal: "center", wrapText: true },
  };
  worksheet.getRow(1).height = 30;

}

const filterData = [
  "Customer Name",
  "Type of Project",
  "Type of Concrete",
  "Project Details",
  "Congrate Grade",
  "Cement Brand",
  "Tecnical person Name",
  "Enquiry Number",
  "Status",
  "Zone",
  "Min Max Filter",
  "DateFilter",
];

// function getFilterData(worksheet, row, data) {
//   console.log("worksheet",worksheet);
//   console.log("row",row);
//   console.log("data",data);

//   const filterHeaderData = JSON.parse(JSON.stringify(data));
//   filterHeaderData.forEach((item, index) => {
//     const rowIndex = row + index;
//     worksheet.getCell(rowIndex, 1).value = item;
//     worksheet.getRow(rowIndex).font = { bold: true };
//   });
// }
function getFilterData(worksheet, row, data) {
  console.log("worksheet", worksheet);
  console.log("row", row);
  console.log("data", data);

  const filterHeaderData = JSON.parse(JSON.stringify(data));
  filterHeaderData.forEach((item, index) => {
    const rowIndex = row + index;
    const rowData = Array.isArray(item) ? item : [item]; 

    rowData.forEach((cellValue, colIndex) => {
      const cell = worksheet.getCell(rowIndex, colIndex + 1);
      cell.value = cellValue;
      cell.font = { bold: true };
      worksheet.getColumn(colIndex + 1).width = 25;
    });
  });
}

function convertDateToMinutesIfDateTime(str) {
  const dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
  if (dateTimeRegex.test(str)) {
    const date = new Date(str);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const totalMinutes = hours * 60 + minutes;
    return totalMinutes ? Number(totalMinutes) : totalMinutes;
  } else {
    return str;
  }
}

function insertTrialDetails(worksheet, row, obj) {
  console.log("objobjobj",obj)
  worksheet.getCell(row, 1).value = obj.trial_number
    ? obj.trial_number
    : obj.trialno;
  worksheet.getCell(row, 2).value = obj.trial_date;
  worksheet.getCell(row, 3).value = (obj.technical_person_primary_fname ? obj.technical_person_primary_fname : "") + 
  (obj.technical_person_primary_lname ? ' ' + obj.technical_person_primary_lname : "");

worksheet.getCell(row, 4).value = (obj.technical_person_sec_fname ? obj.technical_person_sec_fname : "") +
  (obj.technical_person_sec_lname ? ' ' + obj.technical_person_sec_lname : "");

  worksheet.getCell(row, 5).value = obj.labtype;
  worksheet.getCell(row, 6).value = obj.projecttype;
  worksheet.getCell(row, 7).value = obj.concretetype;
  worksheet.getCell(row, 8).value = obj.customername
    ? obj.customername
    : obj.customer_name;
  worksheet.getCell(row, 9).value = obj.enquiry_number;
  worksheet.getCell(row, 10).value = obj.project_details;
  worksheet.getCell(row, 11).value = obj.project_location;
  worksheet.getCell(row, 12).value = obj.concrete_grade;
  worksheet.getCell(row, 13).value = convertDateToMinutesIfDateTime(
    obj.retention
  );
  worksheet.getCell(row, 14).value = obj.workability_flow_slump;
  worksheet.getCell(row, 15).value = obj.air_content;
  worksheet.getCell(row, 16).value = obj.earlystrengthtime;
  worksheet.getCell(row, 17).value = obj.early_strength_mpa;
  worksheet.getCell(row, 18).value = obj.target_strength_28_days;
  worksheet.getCell(row, 19).value = obj.cementbrand;
  worksheet.getCell(row, 20).value = obj.cement_content;
  worksheet.getCell(row, 21).value = obj.flyash;
  worksheet.getCell(row, 22).value = obj.ggbfs_content;
  worksheet.getCell(row, 23).value = obj.microsilica_content;
  worksheet.getCell(row, 24).value =
    obj.aggregates.fine_aggregate_natural_sand_content;
  worksheet.getCell(row, 25).value =
    obj.aggregates.fine_aggregate_crushed_sand_content;
  worksheet.getCell(row, 26).value =
    obj.aggregates.coarse_aggregate_ca10mm_content;
  worksheet.getCell(row, 27).value =
    obj.aggregates.coarse_aggregate_ca20mm_content;
  worksheet.getCell(row, 28).value = obj.aggregates.other_aggregate_content;
  worksheet.getCell(row, 29).value = obj.free_water;
  worksheet.getCell(row, 30).value = obj.w_cm;
  worksheet.getCell(row, 31).value = obj.alkali_content;
  worksheet.getCell(row, 32).value = obj.sand_passing600;
  worksheet.getCell(row, 33).value = obj.sand_passing150;
  worksheet.getCell(row, 34).value = obj.sandcategory;
  worksheet.getCell(row, 35).value = obj.clear_test10;
  worksheet.getCell(row, 36).value = obj.clear_test50;
  worksheet.getCell(row, 37).value = obj.coarse_aggregate_150micpass20;
  worksheet.getCell(row, 38).value = obj.coarse_aggregate_150micpass10;
  worksheet.getCell(row, 39).value = obj.ambient_temperature;
  worksheet.getCell(row, 40).value = obj.ambient_humidity;
  worksheet.getCell(row, 41).value = obj.compositions.p20;
  worksheet.getCell(row, 42).value = obj.compositions.p15;
  worksheet.getCell(row, 43).value = obj.compositions.p16;
  worksheet.getCell(row, 44).value = obj.compositions.p18_hr150;
  worksheet.getCell(row, 45).value = obj.compositions.p11;
  worksheet.getCell(row, 46).value = obj.compositions.p27_r650;
  worksheet.getCell(row, 47).value = obj.compositions.p25_a19;
  worksheet.getCell(row, 48).value = obj.compositions.p26;
  worksheet.getCell(row, 49).value = obj.compositions.pr1;
  worksheet.getCell(row, 50).value = obj.compositions.snf_n5;
  worksheet.getCell(row, 51).value = obj.compositions.l1_ligno_50;
  worksheet.getCell(row, 52).value = obj.compositions.ligno;
  worksheet.getCell(row, 53).value = obj.compositions.gn1;
  worksheet.getCell(row, 54).value = obj.compositions.gn2;
  worksheet.getCell(row, 55).value = obj.compositions.g1;
  worksheet.getCell(row, 56).value = obj.compositions.gly;
  worksheet.getCell(row, 57).value = obj.compositions.df1;
  worksheet.getCell(row, 58).value = obj.compositions.df2;
  worksheet.getCell(row, 59).value = obj.compositions.df3;
  worksheet.getCell(row, 60).value = obj.compositions.cf;
  worksheet.getCell(row, 61).value = obj.compositions.cr_1;
  worksheet.getCell(row, 62).value = obj.compositions.ca;
  worksheet.getCell(row, 63).value = obj.compositions.ac1;
  worksheet.getCell(row, 64).value = obj.compositions.ac2_ac3;
  worksheet.getCell(row, 65).value = obj.compositions.ac5_ac6;
  worksheet.getCell(row, 66).value = obj.compositions.ac6;
  worksheet.getCell(row, 67).value = obj.compositions.ar1;
  worksheet.getCell(row, 68).value = obj.compositions.ar2;
  worksheet.getCell(row, 69).value = obj.compositions.ar3;
  worksheet.getCell(row, 70).value = obj.compositions.ar4;
  worksheet.getCell(row, 71).value = obj.compositions.ar5;
  worksheet.getCell(row, 72).value = obj.compositions.ar6;
  worksheet.getCell(row, 73).value = obj.compositions.ar7;
  worksheet.getCell(row, 74).value = obj.compositions.a15_p24;
  worksheet.getCell(row, 75).value = obj.compositions.b29_p23;
  worksheet.getCell(row, 76).value = obj.compositions.n43;
  worksheet.getCell(row, 77).value = obj.compositions.vma1;
  worksheet.getCell(row, 78).value = obj.compositions.vma2;
  worksheet.getCell(row, 79).value = obj.compositions.other;
  worksheet.getCell(row, 80).value = obj.compositions.srno_Pname;
  worksheet.getCell(row, 81).value = obj.dosage;
  worksheet.getCell(row, 82).value = convertDateToMinutesIfDateTime(
    obj.trretention
  );
  worksheet.getCell(row, 83).value = obj.workability;
  worksheet.getCell(row, 84).value = obj.traircontent;
  worksheet.getCell(row, 85).value = obj.early_strength;
  worksheet.getCell(row, 86).value = obj.strength_at_7_days;
  worksheet.getCell(row, 87).value = obj.strength_at_28_days;
  worksheet.getCell(row, 88).value = obj.cost_factor_comparison;
  worksheet.getCell(row, 89).value = obj.remarks;
  worksheet.getCell(row, 90).value = obj.status;
  worksheet.getCell(row, 91).value = obj.ref_previous_trial_data;
  worksheet.getCell(row, 92).value = obj.rejection_notes;
}

function insertCustomer(worksheet, row, checkList, column) {
  const checkListData = JSON.parse(JSON.stringify(checkList));
  const desiredWidth = 20;
  checkListData.customer.forEach((item, index) => {
    const columnIndex = column + index;
    worksheet.getRow(row).getCell(columnIndex).value = item;
    worksheet.getRow(row).getCell(columnIndex).style = {
      font: { bold: false },
    };
    worksheet.getColumn(columnIndex).width = desiredWidth;
  });
}


function insertEnquiryNum(worksheet, row, checkList, column) {
  const checkListData = JSON.parse(JSON.stringify(checkList));
  const desiredWidth = 20; // Set your desired column width here
  
  checkListData.enqiryList.forEach((item, index) => {
    const columnIndex = column + index;
    worksheet.getRow(row).getCell(columnIndex).value = item;
    worksheet.getRow(row).getCell(columnIndex).style = {
      font: { bold: false },
    };
    worksheet.getColumn(columnIndex).width = desiredWidth;
  });
}


function insertProject(worksheet, row, checkList, column) {
  const checkListData = JSON.parse(JSON.stringify(checkList));
  const desiredWidth = 20; 
  checkListData.project.forEach((item, index) => {
    const columnIndex = column + index;
    worksheet.getRow(row).getCell(columnIndex).value = item;
    worksheet.getRow(row).getCell(columnIndex).style = {
      font: { bold: false },
    };
    worksheet.getColumn(columnIndex).width = desiredWidth;
  });
}


function insertCongrate(worksheet, row, checkList, column) {
  const checkListData = JSON.parse(JSON.stringify(checkList));
  const desiredWidth = 20; 
  checkListData.congrate.forEach((item, index) => {
    const columnIndex = column + index;
    worksheet.getRow(row).getCell(columnIndex).value = item;
    worksheet.getRow(row).getCell(columnIndex).style = {
      font: { bold: false },
    };
    worksheet.getColumn(columnIndex).width = desiredWidth;
  });
}
function insertProjectDetails(worksheet, row, checkList, column) {
  const checkListData = JSON.parse(JSON.stringify(checkList));
  const desiredWidth = 20; 
  checkListData.projectDet.forEach((item, index) => {
    const columnIndex = column + index;
    worksheet.getRow(row).getCell(columnIndex).value = item;
    worksheet.getRow(row).getCell(columnIndex).style = {
      font: { bold: false },
    };
    worksheet.getColumn(columnIndex).width = desiredWidth;
  });
}
function insertCongrateGrade(worksheet, row, checkList, column) {
  const checkListData = JSON.parse(JSON.stringify(checkList));
  const desiredWidth = 20; 
  checkListData.congreteGrade.forEach((item, index) => {
    const columnIndex = column + index;
    worksheet.getRow(row).getCell(columnIndex).value = item;
    worksheet.getRow(row).getCell(columnIndex).style = {
      font: { bold: false },
    };
    worksheet.getColumn(columnIndex).width = desiredWidth;
  });
}

function insertCementBrand(worksheet, row, checkList, column) {
  const checkListData = JSON.parse(JSON.stringify(checkList));
  const desiredWidth = 20; 
  checkListData.cementBrand.forEach((item, index) => {
    const columnIndex = column + index;
    worksheet.getRow(row).getCell(columnIndex).value = item;
    worksheet.getRow(row).getCell(columnIndex).style = {
      font: { bold: false },
    };
    worksheet.getColumn(columnIndex).width = desiredWidth;
  });
}

function insertTechPerson(worksheet, row, checkList, column) {
  const checkListData = JSON.parse(JSON.stringify(checkList));
  const desiredWidth = 20; 
  checkListData.tech.forEach((item, index) => {
    const columnIndex = column + index;
    worksheet.getRow(row).getCell(columnIndex).value = item;
    worksheet.getRow(row).getCell(columnIndex).style = {
      font: { bold: false },
    };
    worksheet.getColumn(columnIndex).width = desiredWidth;
  });
}

function insertStatus(worksheet, row, checkList, column) {
  const checkListData = JSON.parse(JSON.stringify(checkList));
  const desiredWidth = 20; 
  checkListData.status.forEach((item, index) => {
    const columnIndex = column + index;
    worksheet.getRow(row).getCell(columnIndex).value = item;
    worksheet.getRow(row).getCell(columnIndex).style = {
      font: { bold: false },
    };
    worksheet.getColumn(columnIndex).width = desiredWidth;
  });
}
function insertZone(worksheet, row, checkList, column) {
const checkListData = JSON.parse(JSON.stringify(checkList));
const desiredWidth = 20; 
checkListData.selectedZone.forEach((item, index) => {
  const columnIndex = column + index;
  worksheet.getRow(row).getCell(columnIndex).value =
    item === 2
      ? "West"
      : item === 3
      ? "North"
      : item === 4
      ? "South"
      : item === 1
      ? "East"
      : null;
  worksheet.getRow(row).getCell(columnIndex).style = {
    font: { bold: false },
  };
  worksheet.getColumn(columnIndex).width = desiredWidth;
});
}

function insertMinMaxFilter(worksheet, row, checkList, column) {
  const checkListData = JSON.parse(JSON.stringify(checkList));
  const desiredWidth = 20; 
  const keys = Object.keys(checkListData.serachOthers);
  const valuesArray = keys.map((key) => {
    const value = checkListData.serachOthers[key];
    return { key, min: value.min, max: value.max };
  });
  valuesArray.forEach((item, index) => {
    const columnIndex = column + index;
    worksheet
      .getRow(row)
      .getCell(columnIndex).value = `${item.key} (${item.min}-${item.max})`;
    worksheet.getRow(row).getCell(columnIndex).style = {
      font: { bold: false },
    };
    worksheet.getColumn(columnIndex).width = desiredWidth;
  });
}
function insertDateFilter(worksheet, row, checkList, column) {
  const checkListData = JSON.parse(JSON.stringify(checkList));
  const fromDateFilter = checkListData.fromDate;
  const toDateFilter = checkListData.toDate;
  const dateFilterText = fromDateFilter
    ? `From (${fromDateFilter}) To (${toDateFilter})`
    : "";
  worksheet.getCell(row, column).value = dateFilterText;
  worksheet.getCell(row, column).font = { bold: false };
  const desiredWidth = 30; 
  worksheet.getColumn(column).width = desiredWidth;
}

function generateExcelFile(resData, checkList) {
  console.log("resDataresDataEXXX",resData)
  const workbook = new ExcelJS.Workbook();

  // Add the first worksheet for filters data
  const filterWorksheet = workbook.addWorksheet("Selected Filters");
  // filterWorksheet.mergeCells("A1:CN1");
  // filterWorksheet.getCell("A1").value = "Chryso Filter Data";
  // filterWorksheet.getCell("A1").font = { bold: true };
  // filterWorksheet.getRow(1).height = 30;

  // Add filter-related functions to the first worksheet
  filterHeader(filterWorksheet);
  getFilterData(filterWorksheet, 2, filterData);
  insertEnquiryNum(filterWorksheet, 9, checkList, 2);
  insertStatus(filterWorksheet, 10, checkList, 2);
  insertZone(filterWorksheet, 11, checkList, 2);
  insertMinMaxFilter(filterWorksheet, 12, checkList, 2);
  insertDateFilter(filterWorksheet, 13, checkList, 2);
  insertCustomer(filterWorksheet, 2, checkList, 2);
  insertProject(filterWorksheet, 3, checkList, 2);
  insertCongrate(filterWorksheet, 4, checkList, 2);
  insertProjectDetails(filterWorksheet, 5, checkList, 2);
  insertCongrateGrade(filterWorksheet, 6, checkList, 2);
  insertCementBrand(filterWorksheet, 7, checkList, 2);
  insertTechPerson(filterWorksheet, 8, checkList, 2);

  // Add the second worksheet to the workbook for trial data
  const trialWorksheet = workbook.addWorksheet("Trial Data");
  // trialWorksheet.mergeCells("A1:CN1");
  // trialWorksheet.getCell("A1").value = "Chryso Trial Data Bank";
  // trialWorksheet.getCell("A1").font = { bold: true };
  // trialWorksheet.getRow(1).height = 30;

  // Add functions to the second worksheet
  getTrialDetails(trialWorksheet);
  getClientDetails(trialWorksheet);
  getConcreteMixDesignDetails(trialWorksheet);
  getSupportingInfo(trialWorksheet);
  getAdmixtureComposition(trialWorksheet);
  getTrialResults(trialWorksheet);
  getComments(trialWorksheet);

  resData.map((obj, index) => {
    insertTrialDetails(trialWorksheet, index + 2, obj);
  });

  // Save the workbook
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    var date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let currentDate = `${day}-${month}-${year}`;
    // Use the file-saver package to save the Blob as a file
    saveAs(blob, `Chryso_Trial_Data_Bank_${currentDate}.xlsx`);
  });
}

export default generateExcelFile;
