import React, { useEffect, useState } from "react";
import TextBox from "../input/TextBox";
import { Grid, Box, Typography, Button } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  Admixture_Composition,
  errorHandleObj,
  visitType,
  error, admixtorPopupState, trial_details, alertTrialNumber
} from "../recoil/atom";
import configParam from "../Config";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Modal } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SnackbarContent from '@mui/material/SnackbarContent';
const AdmixtorComposition = (props) => {
  const matches = useMediaQuery("(max-width:600px)");
  const [visitTypes] = useRecoilState(visitType);
  const [aCDet, setACDet] = useRecoilState(Admixture_Composition);
  const [admixDet, setAdmixDet] = useRecoilState(errorHandleObj);
  const trialData = localStorage.getItem("ChrysoVisitData");
  const trialApprovelData = localStorage.getItem("TrialApprovelData");
  const SavedTrialId = localStorage.getItem("SavedTrialId");
  const [stepperError] = useRecoilState(error);
  const SavedTrialDetailData = localStorage.getItem("SavedTrialDetail");
  const SavedTrialDetail = JSON.parse(SavedTrialDetailData);
  const [modalState, setModalState] = useState(false);
  const [popupShown, setPopupShown] = useState(false);
  const [message, setMessage] = useState("");
  const [alertMessage, setAlertMessage] = useRecoilState(admixtorPopupState);
  const trialNum = useRecoilValue(trial_details);
  const [, setAlertTrialNumbers] = useRecoilState(alertTrialNumber)
  const [trDet, setTrDet] = useRecoilState(trial_details);

  let data =
    visitTypes === "1"
      ? trialData
        ? JSON.parse(trialData)
        : {}
      : trialApprovelData
        ? JSON.parse(trialApprovelData)
        : {};
  const sgid = localStorage.getItem("UserID");
  const getItemNo = configParam.getVisitedItem;
  const getSavedVisitNo = configParam.getSavedTrial;
  const [open, setOpen] = useState(false);

  let typographyContent = null;

  useEffect(() => {
    // setAlertTrialNumbers(trialNum.trial_number)
    let conditionArr = ["condition1", "condition2", "condition3", "condition4", "condition5", "condition6", "condition7", "condition8"];
    conditionArr.forEach((condition, index) => {
      validateAlerts(condition, aCDet);
    });


    if (alertMessage.some((x) => x.status !== false)) {
      typographyContent = (
        <Typography variant="h3" gutterBottom>
          <span style={{ borderBottom: '2px solid black', fontWeight: 'bold', color: 'black' }}>
            Formulation Suggestion Alerts
          </span>
        </Typography>
      );
    }
  }, [aCDet]);

  const validateAlerts = (condition, arr) => {
    // console.log(condition, arr);
    switch (condition) {
      case "condition1":
        const arValues = ['ar1', 'ar2', 'ar3', 'ar4', 'ar5', 'ar6', 'ar7'];
        const sum = arValues.reduce((acc, curr) => {
          return acc + parseFloat(arr[curr] || 0); // Return the updated accumulator value
        }, 0);
        if (sum > 1) {
          updateAlertStatus(1, true)
        } else if (sum <= 1) {
          updateAlertStatus(1, false);
        }
        break;
      case "condition2":
        if (parseInt(arr.df1) === 0 && parseInt(arr.df2) === 0 && parseInt(arr.df3) === 0) {
          updateAlertStatus(7, true);
        } else {
          updateAlertStatus(7, false);
        }
        break;

      case "condition3":
        const pValues = ['p11', 'p15', 'p18_hr150', 'p20', 'b29_p23', 'a15_p24', "p25_a19", "p26"];
        const gnValue = ["gn1", "gn2"];
        const addAc3 = ["gn1", "gn2", "ac2_ac3"];
        const sumpSection = pValues.reduce((acc, curr) => acc + parseFloat(arr[curr] || 0), 0);
        const gnSection = gnValue.reduce((acc, curr) => acc + parseFloat(arr[curr] || 0), 0);
        const gnSecac3 = addAc3.reduce((acc, curr) => acc + parseFloat(arr[curr] || 0), 0);
        if (sumpSection > 400 && sumpSection < 500 && gnSection > 120) {
          updateAlertStatus(3, true);
        } else if (sumpSection > 500 && sumpSection <= 600 && gnSection > 90) {
          updateAlertStatus(4, true);
        } else if (sumpSection > 600 && sumpSection <= 700 && gnSection > 70) {
          updateAlertStatus(5, true);
        } else if (sumpSection > 700 && sumpSection <= 800 && gnSecac3 > 60) {
          updateAlertStatus(6, true);
        } else if (sumpSection > 800 && gnSecac3 > 20) {
          updateAlertStatus(11, true);
        } else {
          updateAlertStatus([3, 4, 5, 6, 11], false);
        }
        break;

      case "condition4":
        const pValues1 = ['p26', 'b29_p23',];
        const p2Value1 = ['p18_hr150', 'p27_r650'];
        const sumpSection1 = pValues1.reduce((acc, curr) => acc + parseFloat(arr[curr] || 0), 0);
        const multipliedSum = sumpSection1 * 3;
        const p2Sum = p2Value1.reduce((acc, curr) => acc + parseFloat(arr[curr] || 0), 0);
        if (p2Sum > multipliedSum) {
          updateAlertStatus(10, true);
        }
        else {
          updateAlertStatus(10, false);
        }
        break;
      case "condition5":
        const pValuesCondition5 = ['p11', 'p15', 'p18_hr150', 'p20', 'b29_p23', 'a15_p24', "p25_a19", "p26"];
        const snValueCheck = ["snf_n5", "n43"]
        const sumpSectionCheck = pValuesCondition5.reduce((acc, curr) => acc + parseFloat(arr[curr] || 0), 0);
        const snSection = snValueCheck.reduce((acc, curr) => acc + parseFloat(arr[curr] || 0), 0);
        if (sumpSectionCheck > 1 && snSection > 0) {
          updateAlertStatus(8, true);
        }

        else if (snSection > 1 && sumpSectionCheck > 0) {
          updateAlertStatus(12, true);
        }

        else {
          updateAlertStatus([8, 12], false);
        }
        break;

      case "condition7":
        const allValues = ['p20', 'p15', 'p16', 'p18_hr150', 'p11', 'snf_n5', 'l1_ligno_50', 'ligno', 'gn1', 'cf', 'g1', 'ac2_ac3',
          'ac1', 'ac5_ac6', 'df1', 'df2', 'df3', 'ar1', 'ar2', 'ar3', 'ar4', 'ar5', 'ar6', 'ar7', 'cr_1', 'b29_p23', 'p27_r650',
          'a15_p24', 'pr1', 'gn2', 'n43', 'gly', 'ca', 'ac6', 'p25_a19', 'p26', 'ar1', 'vma1', 'vma2'];
        const allSum = allValues.reduce((acc, curr) => acc + parseFloat(arr[curr] || 0), 0);
        if (allSum > 1000) {
          updateAlertStatus(9, true);
        } else {
          updateAlertStatus(9, false);
        }
        break;

      case "condition8":
        const specificKey = 'ac1'
        const value = arr[specificKey];
        if (value > 0) {
          updateAlertStatus(2, true)
        } else {
          updateAlertStatus(2, false);
        }
        break;
      default:
    }
  }
  const updateAlertStatus = (ids, value) => {
    ids = Array.isArray(ids) ? ids : [ids];

    // Update the state using the previous state
    setAlertMessage(prevAlerts => {
      // Create a new array with updated values based on the previous state
      const updatedAlerts = prevAlerts.map(item => {
        if (ids.includes(item.id)) {
          // For items whose id is included in the ids array, update the status property
          return { ...item, status: value };
        }
        // Return items whose id is not included in the ids array without modification
        return item;
      });

      // Check if the item with id 13 exists in the array
      const itemIndex = updatedAlerts.findIndex(item => item.id === 13);
      if (itemIndex !== -1) {
        // Create a new object with updated status and trialNumber
        const updatedItem = { ...updatedAlerts[itemIndex], status: true };
        updatedItem.trialNumber = trDet.trial_number;
        // Replace the item at the specified index with the updated object
        updatedAlerts[itemIndex] = updatedItem;
      }

      // Return the updated state
      return updatedAlerts;
    });
  }


  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };


  const onHandleChange = (e) => {
    const { name, value } = e.target;
    if (['ar1', 'ar2', 'ar3', 'ar4', 'ar5', 'ar6', 'ar7'].includes(name)) {
      validateAlerts("condition1", { ...aCDet, [name]: value })
    }
    else if (['df1', 'df2', 'df3'].includes(name)) {
      validateAlerts("condition2", { ...aCDet, [name]: value })
    }
    else if (['p18_hr150', 'p27_r650'].includes(name) || ['p26', 'b29_p23'].includes(name)) {
      validateAlerts("condition4", { ...aCDet, [name]: value });
    }
    else if (['p11', 'p15', 'p18_hr150', 'p20', 'b29_p23', 'a15_p24', 'p25_a19', 'p26', 'gn1', 'gn2', 'ac2_ac3'].includes(name) && !aCDet.n43 && !aCDet.snf_n5) {
      validateAlerts("condition3", { ...aCDet, [name]: value });
    }
    else if (['p11', 'p15', 'p18_hr150', 'p20', 'b29_p23', 'a15_p24', 'p25_a19', 'p26', 'gn1', 'gn2', 'ac2_ac3'].includes(name) && (aCDet.gn1 || aCDet.gn2)) {
      validateAlerts("condition3", { ...aCDet, [name]: value });
    }
    else if (['p11', 'p15', 'p18_hr150', 'p20', 'b29_p23', 'a15_p24', 'p25_a19', 'p26', 'gn1', 'gn2', 'ac2_ac3'].includes(name) && (aCDet.n43 || aCDet.snf_n5)) {
      validateAlerts("condition5", { ...aCDet, [name]: value });
    }
    else if (['snf_n5', 'n43'].includes(name)) {
      validateAlerts("condition5", { ...aCDet, [name]: value })
    }

    else {
      const allValues = ['p20', 'p15', 'p16', 'p18_hr150', 'p11', 'snf_n5', 'l1_ligno_50', 'ligno', 'gn1', 'cf', 'g1', 'ac2_ac3',
        'ac1', 'ac5_ac6', 'df1', 'df2', 'df3', 'ar1', 'ar2', 'ar3', 'ar4', 'ar5', 'ar6', 'ar7', 'cr_1', 'b29_p23', 'p27_r650',
        'a15_p24', 'pr1', 'gn2', 'n43', 'gly', 'ca', 'ac6', 'p25_a19', 'p26', 'ar1', 'vma1', 'vma2'];
      function isInArray(value, array) {
        for (let i = 0; i < array.length; i++) {
          if (array[i] === value) {
            return true;
          }
        }
        return false;
      }
      if (isInArray(name, allValues)) {
        validateAlerts("condition7", { ...aCDet, [name]: value });
      }

      else if (['ac1'].includes(name)) {
        validateAlerts("condition8", { ...aCDet, [name]: value })
      }
    }

    setACDet((prev) => ({
      ...prev,
      [name]: value
    }));
  };




  useEffect(() => {
    let rowIndex;
    if (visitTypes === "1") {
      rowIndex = getItemNo();
    } else {
      rowIndex = getSavedVisitNo();
    }
    if (data[sgid]) {
      let obj = { Admixture: aCDet };
      let merged = { ...data[sgid][rowIndex ? rowIndex : 0], ...obj };
      data[sgid][rowIndex] = merged;
    } else {
      data[sgid] = { 0: { Admixture: aCDet } };
    }
    if (visitTypes === "1") {
      localStorage.setItem("ChrysoVisitData", JSON.stringify(data));
    } else {
      localStorage.setItem("TrialApprovelData", JSON.stringify(data));
    }
  }, [aCDet]);



  return (

    <div>
      <Grid container spacing={2}>
        {/* <Grid container  spacing={2} style={{ marginTop: 5}}> */}
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="P20"
            name="p20"
            value={aCDet.p20 ? aCDet.p20 : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color: (alertMessage[2].status || alertMessage[3].status || alertMessage[4].status || alertMessage[5].status || alertMessage[10].status || alertMessage[7].status || alertMessage[11].status || alertMessage[8].status) === true ? 'white' : 'initial',
                backgroundColor: (alertMessage[2].status || alertMessage[3].status || alertMessage[4].status || alertMessage[5].status || alertMessage[10].status || alertMessage[7].status || alertMessage[11].status || alertMessage[8].status) === true ? 'red' : 'initial'
              }
            }}

          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2} >
          <TextBox
            label="P15"
            name="p15"
            value={aCDet.p15 ? aCDet.p15 : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color: (alertMessage[2].status || alertMessage[3].status || alertMessage[4].status || alertMessage[5].status || alertMessage[10].status || alertMessage[7].status || alertMessage[11].status || alertMessage[8].status) === true ? 'white' : 'initial',
                backgroundColor: (alertMessage[2].status || alertMessage[3].status || alertMessage[4].status || alertMessage[5].status || alertMessage[10].status || alertMessage[7].status || alertMessage[11].status || alertMessage[8].status) === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2} >
          <TextBox
            label="P16"
            name="p16"
            value={aCDet.p16 ? aCDet.p16 : ""}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color:  alertMessage[8].status === true ? 'white' : 'initial',
                backgroundColor:  alertMessage[8].status === true ? 'red' : 'initial'
              }
            }}

            onChange={onHandleChange}

          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2} >
          <TextBox
            label="P18/HR150"
            name="p18_hr150"
            value={aCDet.p18_hr150 ? aCDet.p18_hr150 : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color: (alertMessage[2].status || alertMessage[3].status || alertMessage[4].status || alertMessage[5].status || alertMessage[10].status || alertMessage[9].status || alertMessage[7].status || alertMessage[11].status || alertMessage[8].status) === true ? 'white' : 'initial',
                backgroundColor: (alertMessage[2].status || alertMessage[3].status || alertMessage[4].status || alertMessage[5].status || alertMessage[10].status || alertMessage[9].status || alertMessage[7].status || alertMessage[11].status || alertMessage[8].status) === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="P11"
            name="p11"
            value={aCDet.p11 ? aCDet.p11 : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color: (alertMessage[2].status || alertMessage[3].status || alertMessage[4].status || alertMessage[5].status || alertMessage[10].status || alertMessage[7].status || alertMessage[11].status || alertMessage[8].status) === true ? 'white' : 'initial',
                backgroundColor: (alertMessage[2].status || alertMessage[3].status || alertMessage[4].status || alertMessage[5].status || alertMessage[10].status || alertMessage[7].status || alertMessage[11].status || alertMessage[8].status) === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="B29/ P23"
            name="b29_p23"
            value={aCDet.b29_p23 ? aCDet.b29_p23 : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color: (alertMessage[2].status || alertMessage[3].status || alertMessage[4].status || alertMessage[5].status || alertMessage[10].status || alertMessage[9].status || alertMessage[7].status || alertMessage[11].status || alertMessage[8].status) === true ? 'white' : 'initial',
                backgroundColor: (alertMessage[2].status || alertMessage[3].status || alertMessage[4].status || alertMessage[5].status || alertMessage[10].status || alertMessage[9].status || alertMessage[7].status || alertMessage[11].status || alertMessage[8].status) === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="A15/P24"
            name="a15_p24"
            value={aCDet.a15_p24 ? aCDet.a15_p24 : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color: (alertMessage[2].status || alertMessage[3].status || alertMessage[4].status || alertMessage[5].status || alertMessage[10].status || alertMessage[7].status || alertMessage[11].status || alertMessage[8].status) === true ? 'white' : 'initial',
                backgroundColor: (alertMessage[2].status || alertMessage[3].status || alertMessage[4].status || alertMessage[5].status || alertMessage[10].status || alertMessage[7].status || alertMessage[11].status || alertMessage[8].status) === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="P25/A19"
            name="p25_a19"
            value={aCDet.p25_a19 ? aCDet.p25_a19 : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color: (alertMessage[2].status || alertMessage[3].status || alertMessage[4].status || alertMessage[5].status || alertMessage[10].status || alertMessage[7].status || alertMessage[11].status || alertMessage[8].status) === true ? 'white' : 'initial',
                backgroundColor: (alertMessage[2].status || alertMessage[3].status || alertMessage[4].status || alertMessage[5].status || alertMessage[10].status || alertMessage[7].status || alertMessage[11].status || alertMessage[8].status) === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="P26"
            name="p26"
            value={aCDet.p26 || ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color: (alertMessage[2].status || alertMessage[3].status || alertMessage[4].status || alertMessage[5].status || alertMessage[10].status || alertMessage[9].status || alertMessage[7].status || alertMessage[11].status || alertMessage[8].status) === true ? 'white' : 'initial',
                backgroundColor: (alertMessage[2].status || alertMessage[3].status || alertMessage[4].status || alertMessage[5].status || alertMessage[10].status || alertMessage[9].status || alertMessage[7].status || alertMessage[11].status || alertMessage[8].status) === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="P27/R650"
            name="p27_r650"
            value={aCDet.p27_r650 ? aCDet.p27_r650 : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color: (alertMessage[9].status || alertMessage[8].status) === true ? 'white' : 'initial',
                backgroundColor: (alertMessage[9].status || alertMessage[8].status) === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="PR1"
            name="pr1"
            value={aCDet.pr1 ? aCDet.pr1 : ""}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color:  alertMessage[8].status === true ? 'white' : 'initial',
                backgroundColor:  alertMessage[8].status === true ? 'red' : 'initial'
              }
            }}
            onChange={onHandleChange}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="SNF/N5"
            name="snf_n5"
            value={aCDet.snf_n5 ? aCDet.snf_n5 : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color: (alertMessage[7].status || alertMessage[11].status || alertMessage[8].status) === true ? 'white' : 'initial',
                backgroundColor: (alertMessage[7].status || alertMessage[11].status || alertMessage[8].status) === true ? 'red' : 'initial'
              }
            }}

          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="N43"
            name="n43"
            value={aCDet.n43 ? aCDet.n43 : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color: (alertMessage[7].status || alertMessage[11].status || alertMessage[8].status) === true ? 'white' : 'initial',
                backgroundColor: (alertMessage[7].status || alertMessage[11].status || alertMessage[8].status) === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>

        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="L1(Ligno50)"
            name="l1_ligno_50"
            value={aCDet.l1_ligno_50 ? aCDet.l1_ligno_50 : ""}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color:  alertMessage[8].status === true ? 'white' : 'initial',
                backgroundColor:  alertMessage[8].status === true ? 'red' : 'initial'
              }
            }}
            onChange={onHandleChange}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="Ligno"
            name="ligno"
            value={aCDet.ligno ? aCDet.ligno : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color:  alertMessage[8].status === true ? 'white' : 'initial',
                backgroundColor:  alertMessage[8].status === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="GN1"
            name="gn1"
            value={aCDet.gn1 ? aCDet.gn1 : ""}
            onChange={onHandleChange}
            inputProps={{
              style: {
                color: (alertMessage[2].status || alertMessage[3].status || alertMessage[4].status || alertMessage[5].status || alertMessage[10].status || alertMessage[8].status) === true ? 'white' : 'initial',
                backgroundColor: (alertMessage[2].status || alertMessage[3].status || alertMessage[4].status || alertMessage[5].status || alertMessage[10].status || alertMessage[8].status) === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="GN2"
            name="gn2"
            value={aCDet.gn2 ? aCDet.gn2 : ""}
            onChange={onHandleChange}
            inputProps={{
              style: {
                color: (alertMessage[2].status || alertMessage[3].status || alertMessage[4].status || alertMessage[5].status || alertMessage[10].status || alertMessage[8].status) === true ? 'white' : 'initial',
                backgroundColor: (alertMessage[2].status || alertMessage[3].status || alertMessage[4].status || alertMessage[5].status || alertMessage[10].status || alertMessage[8].status) === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="G1"
            name="g1"
            value={aCDet.g1 ? aCDet.g1 : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color:  alertMessage[8].status === true ? 'white' : 'initial',
                backgroundColor:  alertMessage[8].status === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="Gly"
            name="gly"
            value={aCDet.gly ? aCDet.gly : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color:  alertMessage[8].status === true ? 'white' : 'initial',
                backgroundColor:  alertMessage[8].status === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="DF1"
            name="df1"
            value={aCDet.df1 ? aCDet.df1 : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color: (alertMessage[6].status || alertMessage[8].status) === true ? 'white' : 'initial',
                backgroundColor: (alertMessage[6].status || alertMessage[8].status) === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="DF2"
            name="df2"
            value={aCDet.df2 ? aCDet.df2 : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color: (alertMessage[6].status || alertMessage[8].status) === true ? 'white' : 'initial',
                backgroundColor: (alertMessage[6].status || alertMessage[8].status) === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>

        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="DF3"
            name="df3"
            value={aCDet.df3 ? aCDet.df3 : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color: (alertMessage[6].status || alertMessage[8].status) === true ? 'white' : 'initial',
                backgroundColor:( alertMessage[6].status || alertMessage[8].status) === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="CF"
            name="cf"
            value={aCDet.cf ? aCDet.cf : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color:  alertMessage[8].status === true ? 'white' : 'initial',
                backgroundColor:  alertMessage[8].status === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="CR/1"
            name="cr_1"
            value={aCDet.cr_1 ? aCDet.cr_1 : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color:  alertMessage[8].status === true ? 'white' : 'initial',
                backgroundColor:  alertMessage[8].status === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>

        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="CA"
            name="ca"
            value={aCDet.ca ? aCDet.ca : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color:  alertMessage[8].status === true ? 'white' : 'initial',
                backgroundColor:  alertMessage[8].status === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="AC1"
            name="ac1"
            value={aCDet.ac1 ? aCDet.ac1 : ""}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color: (alertMessage[1].status || alertMessage[8].status) === true ? 'white' : 'initial',
                backgroundColor: (alertMessage[1].status || alertMessage[8].status) === true ? 'red' : 'initial'
              }
            }}
            onChange={onHandleChange}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="AC2/AC3"
            name="ac2_ac3"
            value={aCDet.ac2_ac3 ? aCDet.ac2_ac3 : ""}
            onChange={onHandleChange}
            inputProps={{
              style: {
                color: (alertMessage[5].status || alertMessage[10].status || alertMessage[8].status) === true ? 'white' : 'initial',
                backgroundColor: (alertMessage[5].status || alertMessage[10].status || alertMessage[8].status) === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>

        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="AC5/AC6"
            name="ac5_ac6"
            value={aCDet.ac5_ac6 ? aCDet.ac5_ac6 : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color:  alertMessage[8].status === true ? 'white' : 'initial',
                backgroundColor:  alertMessage[8].status === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="AC6"
            name="ac6"
            value={aCDet.ac6 ? aCDet.ac6 : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color:  alertMessage[8].status === true ? 'white' : 'initial',
                backgroundColor:  alertMessage[8].status === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="AR1"
            name="ar1"
            value={aCDet.ar1 ? aCDet.ar1 : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color: (alertMessage[0].status || alertMessage[8].status) === true ? 'white' : 'initial',
                backgroundColor: (alertMessage[0].status || alertMessage[8].status) === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="AR2"
            name="ar2"
            value={aCDet.ar2 ? aCDet.ar2 : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color: (alertMessage[0].status || alertMessage[8].status) === true ? 'white' : 'initial',
                backgroundColor: (alertMessage[0].status || alertMessage[8].status) === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="AR3"
            name="ar3"
            value={aCDet.ar3 ? aCDet.ar3 : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color: (alertMessage[0].status || alertMessage[8].status) === true ? 'white' : 'initial',
                backgroundColor: (alertMessage[0].status || alertMessage[8].status) === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="AR4"
            name="ar4"
            value={aCDet.ar4 ? aCDet.ar4 : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color: (alertMessage[0].status || alertMessage[8].status) === true ? 'white' : 'initial',
                backgroundColor: (alertMessage[0].status || alertMessage[8].status) === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="AR5"
            name="ar5"
            value={aCDet.ar5 ? aCDet.ar5 : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color: (alertMessage[0].status || alertMessage[8].status) === true ? 'white' : 'initial',
                backgroundColor: (alertMessage[0].status || alertMessage[8].status) === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="AR6"
            name="ar6"
            value={aCDet.ar6 ? aCDet.ar6 : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color: (alertMessage[0].status || alertMessage[8].status) === true ? 'white' : 'initial',
                backgroundColor: (alertMessage[0].status || alertMessage[8].status) === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="AR7"
            name="ar7"
            value={aCDet.ar7 ? aCDet.ar7 : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color: (alertMessage[0].status || alertMessage[8].status) === true ? 'white' : 'initial',
                backgroundColor: (alertMessage[0].status || alertMessage[8].status) === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="VMA1"
            name="vma1"
            value={aCDet.vma1 ? aCDet.vma1 : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color:  alertMessage[8].status === true ? 'white' : 'initial',
                backgroundColor:  alertMessage[8].status === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="VMA2"
            name="vma2"
            value={aCDet.vma2 ? aCDet.vma2 : ""}
            onChange={onHandleChange}
            error={alertMessage.status === true}
            inputProps={{
              style: {
                color:  alertMessage[8].status === true ? 'white' : 'initial',
                backgroundColor:  alertMessage[8].status === true ? 'red' : 'initial'
              }
            }}
          />
        </Grid>
        <Grid item xs={6} xl={1} md={3} lg={2}>
          <TextBox
            label="Other "
            name="other"
            value={aCDet.other ? aCDet.other : ""}
            onChange={onHandleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} xl={2} md={3} lg={3}>
          <TextBox
            label="Sample Ref No/Product Name *"
            name="srno_Pname"
            isString={true}
            value={aCDet.srno_Pname.toLocaleUpperCase()}
            onChange={onHandleChange}
            error={stepperError && !aCDet.srno_Pname ? true : false}
          />
        </Grid>
      </Grid>
  
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <SnackbarContent
          style={{ backgroundColor: 'red' }}
          message={message}
          action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </Snackbar>
      <div style={{ marginTop: 20 }}>
        {typographyContent}
        {
          alertMessage
            .filter((x) => x.status !== false && x.id !== 13)
            .map((item) => {
              return (
                <p style={{ color: "red" }} key={item.id}>
                  {item.value}
                </p>
              );
            })
        }
      </div>
    </div>


  );
};

export default AdmixtorComposition;
