import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Alert, Button, IconButton, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "@mui/material/Modal";
import configParam from "../Config";
import axios from "axios";
import DeletePopup from "../newVisit/DeletePopup";
import CloseIcon from "@mui/icons-material/Close";
import {
  DataGrid,
  GridEditSingleSelectCell,
  GridToolbar,
} from "@mui/x-data-grid";
import moment from "moment";
import Tooltip from '@mui/material/Tooltip';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const CustomTypeEditComponent = (props) => {
  return <GridEditSingleSelectCell {...props} />;
};
CustomTypeEditComponent.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default function LinkedFieldsRowEditing({
  data,
  columnGrade,
  rowData,
  open,
  heading,
  loadingData,
}) {
  const [show, setShow] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [tableAction, setTableAction] = useState("");
  const [popupFieldVal, setPopupFieldVal] = useState("");
  const [popupMsg, setPopupMsg] = useState("");

  // DeletePopUp
  const deletePopupClose = () => {
    setDeletePopup(false);

  };

  const showPopUp = (type) => {
    setShow(true);
    setTableAction("insert");
    setPopupMsg("");
  };
  const handleClose = () => {
    setShow(false);
    setPopupFieldVal("");
    setPopupMsg("");
  };

  const deleteRecord = (id) => {
    const ApiURL = configParam.API_URL;
    switch (heading) {
      case "Add Laboratory Type":
        let urlLabType = ApiURL + 'delete_lab_type';
        const deleteLabType = {
          userID: id
        }
        axios.post(urlLabType, deleteLabType).then((response) => {
          if (response.data.affected > 0) {
            loadingData();
            setDeletePopup(false);
            setPopupMsg("Data Successfully deleted");
            setTimeout(() => {
              setPopupMsg("")
            }, 3000);
          }
        }).catch((error) => {
          console.log("delete_laboratory_type", error);
        });
        break;
      case "Add Congrete Type":
        let urlConcreteType = ApiURL + 'delete_concrete_Type';
        const deleteConcreteType = {
          userID: id
        }
        axios.post(urlConcreteType, deleteConcreteType).then((response) => {
          if (response.data.affected > 0) {
            loadingData();
            setDeletePopup(false);
            setPopupMsg("Data Successfully Added");
            setTimeout(() => {
              setPopupMsg("")
            }, 3000);
          }
        }).catch((error) => {
          console.log("delete_concrete_Type", error);
        });
        break;
      case "Add Congrete Grade":
        let urlConcreteGrade = ApiURL + 'delete_congrete_Grade';
        const deleteConcreteGrade = {
          userID: id
        }
        axios.post(urlConcreteGrade, deleteConcreteGrade).then((response) => {
          if (Object.keys(response).length > 0) {
            loadingData();
            setDeletePopup(false);
            setPopupMsg("Data Successfully Added");
            setTimeout(() => {
              setPopupMsg("")
            }, 3000);
          }
        }).catch((error) => {
          console.log("delete_congrete_Grade", error);
        });
        break;
      case "Add Early Strength Time":
        let urlEarlyStrengthTime = ApiURL + 'delete_early_Strength_Time';
        const deleteEarlyStrength = {
          userID: id
        }
        axios.post(urlEarlyStrengthTime, deleteEarlyStrength).then((response) => {
          if (Object.keys(response).length > 0) {
            loadingData();
            setDeletePopup(false);
            setPopupMsg("Data Successfully Added");
            setTimeout(() => {
              setPopupMsg("")
            }, 3000);
          }
        }).catch((error) => {
          console.log("delete_early_Strength_Time", error);
        });
        break;
      case "Add Type of Project":
        let urlProjectType = ApiURL + 'delete_project_type';
        const deleteProjectType = {
          userID: id
        }
        axios.post(urlProjectType, deleteProjectType).then((response) => {
          if (Object.keys(response).length > 0) {
            loadingData();
            setDeletePopup(false);
            setPopupMsg("Data Successfully Added");
            setTimeout(() => {
              setPopupMsg("")
            }, 3000);
          }
        }).catch((error) => {
          console.log("delete_project_type", error);
        });
        break;
      case "Add sand Category":
        let url = ApiURL + 'delete_sand_category';
        const deleteSandDetails = {
          userID: id
        }
        axios.post(url, deleteSandDetails).then((response) => {
          if (Object.keys(response).length > 0) {
            loadingData();
            setDeletePopup(false);
            setPopupMsg("Data Successfully Added");
            setTimeout(() => {
              setPopupMsg("")
            }, 3000);
          }
        }).catch((error) => {
          console.log("delete_sand_category", error);
        });
        break;
      case "Add Cement Brand/Grade/Source":
        let url7 = ApiURL + 'delete_cement_brand';
        const deleteCementBrand = {
          userID: id
        }
        axios.post(url7, deleteCementBrand).then((response) => {
          if (Object.keys(response).length > 0) {
            loadingData();
            setDeletePopup(false);
            setPopupMsg("Data Successfully deleted");
            setTimeout(() => {
              setPopupMsg("")
            }, 3000);
          }
        }).catch((error) => {
          console.log("delete_cement_brand", error);
        });

        break;
      case "Add Customer Name":
        let url8 = ApiURL + 'delete_customer_name';
        const deleteCustomerName = {
          userID: id
        }
        axios.post(url8, deleteCustomerName).then((response) => {
          if (Object.keys(response).length > 0) {
            loadingData();
            setDeletePopup(false);
            setPopupMsg("Data Successfully deleted");
            setTimeout(() => {
              setPopupMsg("")
            }, 3000);
          }
        }).catch((error) => {
          console.log("delete_customer_name", error);
        });
        break;
      default:
    }
  };

  const getFieldValue = (val) => {
    if (
      heading === "Add Laboratory Type" ||
      heading === "Add Type of Project" ||
      heading === "Add Cement Brand/Grade/Source" ||
      heading === "Add Congrete Type"
    ) {
      setPopupFieldVal(val["type"]);
    } else if (heading === "Add Congrete Grade") {
      setPopupFieldVal(val["grade"]);
    } else if (heading === "Add Early Strength Time") {
      setPopupFieldVal(val["time"]);
    } else if (heading === "Add sand Category") {
      setPopupFieldVal(val["category"]);
    } else if (heading === "Add Customer Name") {
      setPopupFieldVal(val["name"]);
    }
  };

  const handleDeleteRow = (val) => {
    setCurrentRow(val);
    setDeletePopup(true);
  };

  const editRecord = (val) => () => {
    setShow(true);
    setTableAction("update");
    getFieldValue(val);
    setCurrentRow(val);
    setPopupMsg("");
  };
  const columns = [
    {
      field: "ID",
      headerName: "S. No",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "created_at",
      headerName: "Created Date",
      type: "date",
      width: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
      editable: false,
    },
    {
      field: columnGrade,
      headerName: "Type",
      width: 300,
      editable: false,
      renderEditCell: (params) => <CustomTypeEditComponent {...params} />,
    },
    {
      field: "id",
      headerName: "Actions",
      sortable: false,
      width: 160,
      disableClickEventBubbling: true,
      renderCell: (value) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Tooltip title="Edit">
              <EditIcon
                onClick={editRecord(value["row"])}
                style={{ marginLeft: 15 }}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <DeleteIcon
                onClick={() => handleDeleteRow(value)}
                style={{ marginLeft: 15 }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const savedData = (action, props) => {
    if (popupFieldVal.trim().length === 0) {
      return true;
    }
    const ApiURL = configParam.API_URL;
    if (action === "insert") {
      switch (heading) {
        case "Add Laboratory Type":
          let url9 = ApiURL + 'create_lab_type';
          const createLabType = {
            type: popupFieldVal
          }
          axios.post(url9, createLabType).then((response) => {
            if (Object.keys(response).length > 0) {
              loadingData();
              handleClose();
              setPopupMsg("Data Successfully Added");
              setTimeout(() => {
                setPopupMsg("")
              }, 3000);
            }
          }).catch((error) => {
            console.log("create_laboratory_type", error);
          });
          break;
        case "Add Congrete Grade":
          let url10 = ApiURL + 'create_congrete_Grade';
          const createConcreteGrade = {
            grade: popupFieldVal
          }
          axios.post(url10, createConcreteGrade).then((response) => {
            if (Object.keys(response).length > 0) {
              loadingData();
              handleClose();
              setPopupMsg("Data Successfully Added");
              setTimeout(() => {
                setPopupMsg("")
              }, 3000);
            }
          }).catch((error) => {
            console.log("create_congrete_Grade", error);
          });
          break;
        case "Add Type of Project":
          let url11 = ApiURL + 'create_project_type';
          const createProjectType = {
            type: popupFieldVal
          }
          axios.post(url11, createProjectType).then((response) => {
            if (Object.keys(response).length > 0) {
              loadingData();
              handleClose();
              setPopupMsg("Data Successfully Added");
              setTimeout(() => {
                setPopupMsg("")
              }, 3000);
            }
          }).catch((error) => {
            console.log("create_project_type", error);
          });
          break;

        case "Add Congrete Type":
          let url12 = ApiURL + 'create_concrete_Type';
          const createConcreteType = {
            type: popupFieldVal
          }
          axios.post(url12, createConcreteType).then((response) => {
            if (Object.keys(response).length > 0) {
              loadingData();
              handleClose();
              setPopupMsg("Data Successfully Added");
              setTimeout(() => {
                setPopupMsg("")
              }, 3000);
            }
          }).catch((error) => {
            console.log("create_concrete_Type", error);
          });

          break;
        case "Add Early Strength Time":

          let url13 = ApiURL + 'create_early_Strength_Time';
          const createEarlyStrengthTime = {
            time: popupFieldVal
          }
          axios.post(url13, createEarlyStrengthTime).then((response) => {
            if (Object.keys(response).length > 0) {
              loadingData();
              handleClose();
              setPopupMsg("Data Successfully Added");
              setTimeout(() => {
                setPopupMsg("")
              }, 3000);
            }
          }).catch((error) => {
            console.log("create_early_Strength_Time", error);
          });
          break;
        case "Add sand Category":
          let url14 = ApiURL + 'create_sand_category';
          const sandDetails = {
            category: popupFieldVal
          }
          axios.post(url14, sandDetails).then((response) => {
            if (Object.keys(response).length > 0) {
              loadingData();
              handleClose();
              setPopupMsg("Data Successfully Added");
              setTimeout(() => {
                setPopupMsg("")
              }, 3000);
            }
          }).catch((error) => {
            console.log("create_sand_category", error);
          });
          break;
        case "Add Cement Brand/Grade/Source":
          let url15 = ApiURL + 'create_cement_brand';
          const createCementBrand = {
            type: popupFieldVal
          }
          axios.post(url15, createCementBrand).then((response) => {
            if (Object.keys(response).length > 0) {
              loadingData();
              handleClose();
              setPopupMsg("Data Successfully Added");
              setTimeout(() => {
                setPopupMsg("")
              }, 3000);
            }
          }).catch((error) => {
            console.log("create_cement_brand", error);
          });
          break;
        case "Add Customer Name":
          let url16 = ApiURL + 'create_customer_name';
          const createCustomerName = {
            type: popupFieldVal
          }
          axios.post(url16, createCustomerName).then((response) => {
            if (Object.keys(response).length > 0) {
              loadingData();
              handleClose();
              setPopupMsg("Data Successfully Added");
              setTimeout(() => {
                setPopupMsg("")
              }, 3000);
            }
          }).catch((error) => {
            console.log("create_customer_name", error);
          });
          break;
        default:
          break;
      }
    } else if (action === "update") {
      switch (heading) {
        case "Add Laboratory Type":
          let url17 = ApiURL + 'update_lab_type';
          const updateLabType = {
            type: popupFieldVal,
            id: currentRow["id"],
          }
          axios.post(url17, updateLabType).then((response) => {
            console.log(response, "response")
            if (Object.keys(response).length > 0) {
              handleClose();
              loadingData();
              setPopupMsg("Data Successfully Updated");
              setTimeout(() => {
                setPopupMsg("")
              }, 3000);
            }
          }).catch((error) => {
            console.log("update_laboratory_type", error);
          });
          break;
        case "Add Congrete Grade":
          let url18 = ApiURL + 'update_congrete_Grade';
          const UpdateConcreteGrade = {
            grade: popupFieldVal,
            id: currentRow["id"],
          }
          axios.post(url18, UpdateConcreteGrade).then((response) => {
            console.log(response, "response")
            if (Object.keys(response).length > 0) {
              handleClose();
              loadingData();
              setPopupMsg("Data Successfully Updated");
              setTimeout(() => {
                setPopupMsg("")
              }, 3000);
            }
          }).catch((error) => {
            console.log("update_congrete_Grade", error);
          });

          break;
        case "Add Type of Project":
          let url19 = ApiURL + 'update_project_type';
          const updateProjectType = {
            type: popupFieldVal,
            id: currentRow["id"],
          }
          axios.post(url19, updateProjectType).then((response) => {
            console.log(response, "response")
            if (Object.keys(response).length > 0) {
              handleClose();
              loadingData();
              setPopupMsg("Data Successfully Updated");
              setTimeout(() => {
                setPopupMsg("")
              }, 3000);
            }
          }).catch((error) => {
            console.log("update_project_type", error);
          });
          break;
        case "Add Congrete Type":

          let url20 = ApiURL + 'update_concrete_Type';
          const updateConcreteType = {
            type: popupFieldVal,
            id: currentRow["id"],
          }
          axios.post(url20, updateConcreteType).then((response) => {
            console.log(response, "response")
            if (Object.keys(response).length > 0) {
              handleClose();
              loadingData();
              setPopupMsg("Data Successfully Updated");
              setTimeout(() => {
                setPopupMsg("")
              }, 3000);
            }
          }).catch((error) => {
            console.log("update_sand_category", error);
          });
          break;
        case "Add Early Strength Time":

          let url21 = ApiURL + 'update_early_Strength_Time';
          const updateEarlyStrengthTime = {
            time: popupFieldVal,
            id: currentRow["id"],
          }
          axios.post(url21, updateEarlyStrengthTime).then((response) => {
            console.log(response, "response")
            if (response && response.status === 200) {
              handleClose();
              loadingData();
              setPopupMsg("Data Successfully Updated");
              setTimeout(() => {
                setPopupMsg("")
              }, 3000);
            }
          }).catch((error) => {
            console.log("update_early_Strength_Time", error);
          });
          break;
        case "Add sand Category":
          let url22 = ApiURL + 'update_sand_category';
          const updateSandDetails = {
            category: popupFieldVal,
            id: currentRow["id"],
          }
          axios.post(url22, updateSandDetails).then((response) => {
            console.log(response, "response")
            if (Object.keys(response).length > 0) {
              handleClose();
              loadingData();
              setPopupMsg("Data Successfully Updated");
              setTimeout(() => {
                setPopupMsg("")
              }, 3000);
            }
          }).catch((error) => {
            console.log("update_sand_category", error);
          });
          break;
        case "Add Cement Brand/Grade/Source":

          let url23 = ApiURL + 'update_cement_brand';
          const updatecementBrand = {
            type: popupFieldVal,
            id: currentRow["id"],
          }
          axios.post(url23, updatecementBrand).then((response) => {
            console.log(response, "response")
            if (Object.keys(response).length > 0) {
              handleClose();
              loadingData();
              setPopupMsg("Data Successfully Updated");
              setTimeout(() => {
                setPopupMsg("")
              }, 3000);
            }
          }).catch((error) => {
            console.log("update_cement_brand", error);
          });
          break;
        case "Add Customer Name":

          let url24 = ApiURL + 'update_customer_name';
          const updateCustomerName = {
            category: popupFieldVal,
            id: currentRow["id"],
          }
          axios.post(url24, updateCustomerName).then((response) => {
            console.log(response, "response")
            if (Object.keys(response).length > 0) {
              handleClose();
              loadingData();
              setPopupMsg("Data Successfully Updated");
              setTimeout(() => {
                setPopupMsg("")
              }, 3000);
            }
          }).catch((error) => {
            console.log("update_customer_name", error);
          });
          break;
        default:
          break;
      }
    }
  };

  const handleTextField = (e) => {
    setPopupFieldVal(e.target.value);
  };

  return (
    <React.Fragment>
      {popupMsg && (
        <Alert
          style={{ width: "100%" }}
          variant="filled"
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                // setErr(false);
                setPopupMsg("");
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {popupMsg}
        </Alert>
      )}
      <Box sx={{ width: "100%", height: 500 }}>
        <DeletePopup
          open={deletePopup}
          onClose={deletePopupClose}
          handleDelete={() => deleteRecord(currentRow["id"])}
        />
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          style={{ marginBottom: 10 }}
          onClick={showPopUp}
        >
          Add Data
        </Button>
        <DataGrid
          style={{ height: 400 }}
          rows={data && data.length > 0 ? data : []}
          columns={columns}
          editMode="row"
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          experimentalFeatures={{ newEditingApi: true }}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            }
          }}
        />
      </Box>
      <div>
        <Modal
          keepMounted
          open={show}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <TextField
              value={heading === "Add Early Strength Time" ? popupFieldVal : popupFieldVal.toLocaleUpperCase()}
              type={heading === "Add Early Strength Time" ? "number" : "text"}
              id="outlined-basic"
              label={heading}
              variant="outlined"
              style={{ width: "100%" }}
              onChange={handleTextField}
            />
            <br />
            <div
              style={{ display: "flex", alignItems: "center", float: "right" }}
            >
              <Button
                variant="contained"
                style={{ marginTop: 20 }}
                onClick={() => savedData(tableAction)}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                style={{ marginTop: 20, marginLeft: 20 }}
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
    </React.Fragment>
  );
}
