import React, { forwardRef } from "react";

import {
  Grid,
  Card,
  TableCell,
  Table,
  TableRow,
  Box,
  TableContainer,
  TableBody,
  Typography,
  TableHead
} from "@mui/material";
import Logo from "../../asset/Logo.png";
import { customerList, projectType, typeofConcrete, projectDetailList, congreteGrade, cementBrand, techPerson, status, zoneFilter, searchList } from "../../recoil/atom";
import { useRecoilValue } from "recoil";

const PreviewPdf = forwardRef(({ data }, ref) => {
  console.log("data", data)

  const customerExcel = useRecoilValue(customerList);
  const projectTypeExcel = useRecoilValue(projectType);
  const congreteTypeExcel = useRecoilValue(typeofConcrete);
  const projectDetExcel = useRecoilValue(projectDetailList);
  const congrateExcel = useRecoilValue(congreteGrade);
  const cementBrandExcel = useRecoilValue(cementBrand);
  const techPersonExcel = useRecoilValue(techPerson);
  const statusExcel = useRecoilValue(status);
  const zoneExcel = useRecoilValue(zoneFilter);
  const serchExcel = useRecoilValue(searchList);

  let selectedZone = zoneExcel
    .filter((data) => data.ischecked === true)
    .map((val) => (val.zone === 3 ? "North" : val.zone === 2 ? "West" : val.zone === 1 ? "East" : val.zone === 4 ? "South" : null))
    .filter((val) => val !== null); // Filter out null values if necessary

  console.log("zoneExcel", zoneExcel)
  let customers = customerExcel.filter((data) => data.ischecked === true).map((val) => val.name);
  let projects = projectTypeExcel.filter((data) => data.ischecked === true).map((val) => val.type);
  let congrates = congreteTypeExcel.filter((data) => data.ischecked === true).map((val) => val.type);
  let projectDets = projectDetExcel.filter((data) => data.ischecked === true).map((val) => val.project_details);

  let congreteGrades = congrateExcel.filter((data) => data.ischecked === true).map((val) => val.grade);
  let cementBrands = cementBrandExcel.filter((data) => data.ischecked === true).map((val) => val.type);
  let statusData = statusExcel.filter((data) => data.ischecked === true).map((val) => val.status);
  let tech = techPersonExcel.filter((data) => data.ischecked === true).map((val) => val.first_name + " " + val.last_name);

  const checkListData = JSON.parse(JSON.stringify(serchExcel));
  const keys = Object.keys(checkListData);
  const valuesArray = keys.map(key => {
    const value = checkListData[key];
    return { key, min: value.min, max: value.max, minMax: `${value.min}-${value.max}` };
  });

  let serachOthers = valuesArray;

  const rows = [];
  const maxLength = Math.max(
    selectedZone.length,
    customers.length,
    projects.length,
    congrates.length,
    projectDets.length,
    congreteGrades.length,
    cementBrands.length,
    statusData.length,
    tech.length,
    serachOthers.length
  );

  for (let i = 0; i < maxLength; i++) {
    const row = [
      selectedZone[i] || '',
      customers[i] || '',
      projects[i] || '',
      congrates[i] || '',
      projectDets[i] || '',
      congreteGrades[i] || '',
      cementBrands[i] || '',
      statusData[i] || '',
      tech[i] || '',

      <React.Fragment key={`${i}-key`}>
        {serachOthers[i] ? serachOthers[i].key : ''}
        <br />
        {serachOthers[i] ? serachOthers[i].minMax : ''}
      </React.Fragment>

    ];
    rows.push(row);
  }

  return (
    <div ref={ref}>
      <table>
        <thead>
          <tr>
            <td>
              <Grid container style={{ marginBottom: 20, borderBottom: "1px solid black" }}>
                <Grid item xs={2} >
                  <img src={Logo} alt="Logo" style={{ width: 115 }} />
                </Grid>
                <Grid
                  item
                  xs={8}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // width: "100%",
                  }}
                >
                  <Typography
                    variant="h1"
                    style={{
                      textTransform: "uppercase",
                      textAlign: "center",
                      fontWeight: "bold",
                      maxWidth: "700px",
                      fontSize: "28px",
                    }}
                  >
                    CHRYSO - TRIAL DATA
                  </Typography>
                </Grid>
              </Grid>
            </td>
          </tr>

        </thead>
        <tbody>
          <tr>
            <td>
              <Grid>

                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="h1"
                      style={{
                        textTransform: "uppercase",
                        // textAlign: "center",
                        fontWeight: "bold",
                        maxWidth: "700px",
                        fontSize: "18px",
                      }}
                    >
                      Applied Filters
                    </Typography>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Zone</TableCell>
                            <TableCell>Customer Name</TableCell>
                            <TableCell>Project Type</TableCell>
                            <TableCell>Concrete Type</TableCell>
                            <TableCell>Project Details</TableCell>
                            <TableCell>Concrete Grade</TableCell>
                            <TableCell>Cement Brand</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Tech Person</TableCell>
                            <TableCell>Others</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row, index) => (
                            <TableRow key={index}>
                              {row.map((cell, cellIndex) => (
                                <TableCell key={cellIndex}>{cell}</TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={8}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    //  justifyContent: "center",
                    // width: "100%",
                  }}
                >
                  <Typography
                    variant="h1"
                    style={{
                      textTransform: "uppercase",
                      textAlign: "left",
                      fontWeight: "bold",
                      maxWidth: "700px",
                      fontSize: "18px",
                      marginTop: "20px"
                    }}
                  >
                    Suggested Formulation
                  </Typography>
                </Grid>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Formulation</TableCell>
                        <TableCell>Lower Limit</TableCell>
                        <TableCell>Upper Limit</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.Name}</TableCell>
                          <TableCell>{item['Formulation']}</TableCell>
                          <TableCell>{item['Lower Limit']}</TableCell>
                          <TableCell>{item['Upper Limit']}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>


                  </Table>
                </TableContainer>
              </Grid>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  );
});
export default PreviewPdf;