import React, { useState } from "react";
import NewVisitTable from "../newVisit/NewVisitTable";

const Visit = () => {
  const [newVisitTableOpen, setNewVisitTableOpen] = useState(true);

  const handleClickChange = () => {
    setNewVisitTableOpen(false);
  };

  return (
    <div>
      <NewVisitTable tableChange={handleClickChange} />
    </div>
  );
};

export default Visit;
