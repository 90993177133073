import React,{useEffect} from "react";
import VerticalTabs from "../../input/VerticalTabs";
import {menuName} from "../../recoil/atom"
import { useRecoilState } from "recoil";

const NewAddCategory = () => {
  const [,setMenu] = useRecoilState(menuName);
  
  useEffect(() => {
    setMenu('Data Management')
  }, []);
  return (
    <React.Fragment>
      <VerticalTabs />
    </React.Fragment>
  );
};

export default NewAddCategory;
