import React, { useEffect } from "react";
import TextBox from "../input/TextBox";
import { Grid } from "@mui/material";
import { useRecoilState } from "recoil";
import {
  trial_results,
  errorHandleObj,
  visitType,
  error,
  currentSteper,
  trial_details
} from "../recoil/atom";
import configParam from "../Config";
import HourMinInput from "../input/HourMinInput";

const TrialResult = () => {
  const [visitTypes] = useRecoilState(visitType);
  const [trRes, setTrRes] = useRecoilState(trial_results);
  const [trResDet, setTrResDet] = useRecoilState(errorHandleObj);
  const [stepperError] = useRecoilState(error);
  const trialData = localStorage.getItem("ChrysoVisitData");
  const trialApprovelData = localStorage.getItem("TrialApprovelData");
  const SavedTrialId = localStorage.getItem("SavedTrialId");
  const [value, setValue] = useRecoilState(currentSteper);
  const [trDet, setTrDet] = useRecoilState(trial_details);
  let data =
    visitTypes === "1"
      ? trialData
        ? JSON.parse(trialData)
        : {}
      : trialApprovelData
        ? JSON.parse(trialApprovelData)
        : {};
  const sgid = localStorage.getItem("UserID");
  const getItemNo = configParam.getVisitedItem;
  const getSavedVisitNo = configParam.getSavedTrial;

  const validateError = (value, key) => {
    if (value) {
      setTrResDet({ ...trResDet, [key]: { value: value, isChecked: false } });
    } else {
      setTrResDet({ ...trResDet, [key]: { value: "", isChecked: true } });
    }
  };

  const changeTrResDet = (e) => {
    let tmpObj = { ...trRes };
    tmpObj[e.target.name] = e.target.value;
    if (e.target.name === "dosage") {
      validateError(e.target.value, "dosageDet");
    }

    if (e.target.name === "workability") {
      validateError(e.target.value, "workabilityDet");
    }
    setTrRes(tmpObj);
  };

  const changeRetention1 = (mins) => {
    let tmpObj = { ...trRes };
    if (mins) {
      tmpObj["retention"] = mins;
    } else {
      if (mins === "retention") {
        validateError(mins, "retention1");
      }
    }
    setTrRes(tmpObj);
  };
  const timeConvert = (min) => {
    var num = min;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    var customMin = !isNaN(min) ? rhours + ":" + rminutes : "00:00";
    let value = new Date('July 1, 1999, ' + customMin + ':00');
    return value.toISOString();

  }

  useEffect(() => {
    let rowIndex;
    if (visitTypes === "1") {
      rowIndex = getItemNo();
    } else {
      rowIndex = getSavedVisitNo();
    }
    if (data[sgid]) {
      let obj = { TrialResult: trRes };
      let merged = { ...data[sgid][rowIndex ? rowIndex : 0], ...obj };
      data[sgid][rowIndex] = merged;
    } else {
      let obj = { TrialResult: trRes };
      data[sgid] = { 0: { ...obj } };
      console.log("datadata", data);
    }
    if (visitTypes === "1") {
      localStorage.setItem("ChrysoVisitData", JSON.stringify(data));
    } else {
      localStorage.setItem("TrialApprovelData", JSON.stringify(data));
    }
  }, [trRes]);
  return (
    <Grid container rowSpacing={1} style={{ marginTop: 25 }}>
      <Grid container rowSpacing={1} spacing={{
        xs: 0,
        sm: 2,
        lg: 5,
        xl: 12
      }}>
        <Grid item xl={3} md={4} xs={12}>
          <TextBox
            label="Dosage % *"
            name="dosage"
            value={trRes.dosage ? trRes.dosage === "0" ? "" : trRes.dosage : ""}
            style={{ backgroundColor: trRes.dosage === "0" ? "lightgray" : "", width: "80%" }}
            onChange={changeTrResDet}
            error={stepperError && !trRes.dosage ? true : false}
          // helperText={stepperError && !trRes.dosage ? "Pls Enter Dosage" : ""}
          />
        </Grid>
        <Grid item xl={3} md={4} xs={12}>
          <HourMinInput
            label="Retention(Hr : Min) *"
            name="retention"
            // value={trRes.retention}
            value={
              trRes.retention ?
                trRes.retention && typeof trRes.retention === 'object' ?
                  trRes.retention : trRes.retention && typeof trRes.retention === 'string' && trRes.retention.length <= 4 ?
                    timeConvert(trRes.retention) :
                    trRes.retention
                :
                ""
            }
            onChange={changeRetention1}
            error={stepperError && !trRes.retention ? true : false}
          // helperText={
          //   stepperError && !trRes.retention ? "Pls Enter Retention" : ""
          // }
          />
        </Grid>
        <Grid item xl={3} md={4} xs={12}>
          <TextBox
            label="Workability(mm) *"
            name="workability"
            value={trRes.workability ? trRes.workability === "0" ? "" : trRes.workability : ""}
            style={{ backgroundColor: trRes.workability === "0" ? "lightgray" : "", width: "80%" }}
            onChange={changeTrResDet}
            error={stepperError && !trRes.workability ? true : false}
          // helperText={
          //   stepperError && !trRes.workability ? "Pls Enter Workability" : ""
          // }
          />
        </Grid>
      </Grid>
      <Grid container rowSpacing={1} spacing={{
        xs: 0,
        sm: 2,
        lg: 5,
        xl: 12
      }} style={{ marginTop: 15 }}>
        <Grid item xl={3} md={4} xs={12}>
          <TextBox
            label="Air Content % "
            name="air_content"
            value={trRes.air_content ? trRes.air_content === "0" ? "" : trRes.air_content : ""}
            style={{ backgroundColor: trRes.air_content === "0" ? "lightgray" : "", width: "80%" }}
            onChange={changeTrResDet}
          />
        </Grid>
        <Grid item xl={3} md={4} xs={12}>
          <TextBox
            label="Early Strength (Mpa)"
            name="early_strength"
            value={trRes.early_strength ? trRes.early_strength === "0" ? "" : trRes.early_strength : ""}
            style={{ backgroundColor: trRes.early_strength === "0" ? "lightgray" : "", width: "80%" }}
            onChange={changeTrResDet}
          />
        </Grid>
        <Grid item xl={3} md={4} xs={12}>
          <TextBox
            label="Strength at 7 Days(Mpa)"
            name="strength_at_7_days"
            value={trRes.strength_at_7_days ? trRes.strength_at_7_days === "0" ? "" : trRes.strength_at_7_days : ""}
            style={{ backgroundColor: trRes.strength_at_7_days === "0" ? "lightgray" : "", width: "80%" }}
            onChange={changeTrResDet}
          />
        </Grid>
      </Grid>
      <Grid container rowSpacing={1} spacing={{
        xs: 0,
        sm: 2,
        lg: 5,
        xl: 12
      }} style={{ marginTop: 15 }}>
        <Grid item xl={3} md={4} xs={12}>
          <TextBox
            label="Strength at 28 Days(Mpa)"
            name="strength_at_28_days"
            value={trRes.strength_at_28_days ? trRes.strength_at_28_days === "0" ? "" : trRes.strength_at_28_days : ""}
            style={{ backgroundColor: trRes.strength_at_28_days === "0" ? "lightgray" : "", width: "80%" }}
            onChange={changeTrResDet}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TrialResult;
