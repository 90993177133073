import React from "react";
import { Outlet } from "react-router-dom";
// import Drawer from "../home/drawer/Drawer";
import LeftDrawer from "../home/drawer/LeftDrawer"
import { styled } from '@mui/material/styles';
const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    backgroundColor: "red",
  },

}));
const Home = () => {
  return (
    <React.Fragment>
      {/* <Drawer /> */}
      <LeftDrawer />
      <main id="chryso-main-element" style={{
          float: "right",
          width: "calc(100% - 260px)",
          marginTop: "90px",
          backgroundColor: "#ffffff"

        }}>
        <Outlet />
      </main>
    </React.Fragment>
  );
};

export default Home;
