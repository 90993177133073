import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TableContainer,
    Paper,
    Chip,
    Alert,
    Grid,
    Card,
    CardContent,
    Typography
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, GridVirtualizationHelper } from "@mui/x-data-grid";
import { useDemoData } from '@mui/x-data-grid-generator';
import { json, useNavigate } from "react-router-dom";
import { selectedVisitObj } from "../../recoil/atom";
import { ReactComponent as Edit } from "../../asset/Edit.svg";
import { ReactComponent as Replace } from "../../asset/replace_existing.svg";
import { useRecoilState, useRecoilValue } from "recoil";
import configParam from "../../Config";
import Button from "@mui/material/Button";
import SearchInput from "../../input/SearchInput";
import { debounce } from 'lodash';

import { getConvertedJSON } from "../../recoil/Selector";
import {
    trial_details,
    client_details,
    concrete_mixdetails,
    supporting_info,
    comments,
    trial_results,
    Admixture_Composition,
    user,
    visitType,
    trialNo,
    currentSteper,
    searchValue,
    isReadOnly,
    AdvanceSearchLoading,
    selectedTrialDetails,
    advancefilterTotal,
    isFilter,
    projectType,
    typeofConcrete,
    customerList,
    projectDetailList,
    congreteGrade,
    cementBrand,
    status,
    searchList,
    techPerson,
    totlaTableCount,
    AdvanceFilterCount,
    filterDataArray,
    allExcelDataArray, filterPagination, filterExcelData, zoneFilter, filterTableLoader, resetTotlaTableCount, searchQueryCount, inputSearchList, filterInputSearchList,filterFromDate,filterToDate,enquiryNumberList
} from "../../recoil/atom";
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import PendingIcon from '@mui/icons-material/Pending';
import CancelIcon from '@mui/icons-material/Cancel';
import AddTaskIcon from '@mui/icons-material/AddTask';
import Tooltip from '@mui/material/Tooltip';
import axios from "axios";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import moment from "moment";
import generateExcelFile from "../../newVisit/GenerateExcel";
import DownloadIcon from "@mui/icons-material/Download";
import CircularProgress from '@mui/material/CircularProgress';
import useMediaQuery from '@mui/material/useMediaQuery';


const VisitTable = ({ isTabDisabled, onHandleFilterSlice, onSearch  }) => {
    const [tablePage, setTablePage] = React.useState({
        page: 0,
        pageSize: 50
    });

    const [totalcount, setTotalCount] = useRecoilState(resetTotlaTableCount);
    const [totalSearchcount, setTotalSearchCount] = useRecoilState(searchQueryCount);
    const [shouldSearch, setShouldSearch] = useState(false);
    const convertedJSON = useRecoilValue(getConvertedJSON);
    // const filterRowCountState = useRecoilValue(AdvanceFilterCount);
    const [filterRowCountState, setFilterRowtotalcount] = useRecoilState(AdvanceFilterCount);
    const [serachRowCount, setSearchRowCount] = useState(0)
    const filterRowCountView = useRecoilValue(totlaTableCount);
    const [showButton, setShowButton] = useState(false);
    const [visitTypeValue, setVisitType] = useRecoilState(visitType);
    const [err, setErr] = useState(false);
    const [msg, setMsg] = useState("");
    const getTrialVisitNo = configParam.TRIAL_VISIT_NO;
    const getVisitNo = configParam.VISIT_NO;
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [visitResData, setVisitResData] = useRecoilState(searchValue);
    const [searchDatas, setSearchDatas] = useState(visitResData)
    const [excelSheetData, setExcelSheetData] = useRecoilState(allExcelDataArray);
    const [selectVisitData, setSelectVisitData] = useRecoilState(selectedTrialDetails);
    const [value, setValue] = useRecoilState(currentSteper);
    const userDet = localStorage.getItem("user");
    let roleID = JSON.parse(userDet);
    const filterArrayData = useRecoilValue(filterDataArray);
    const filterAllExcelArrayData = useRecoilValue(filterExcelData);
    const userID = Number(localStorage.getItem("userDetails"));
    const [, setReadonly] = useRecoilState(isReadOnly);
    const [isLoader, setIsLoader] = useState(false);
    const tableLoader = useRecoilValue(filterTableLoader);
    const navigate = useNavigate();
    const customerExcel = useRecoilValue(customerList);
    const enquiryNumExcel = useRecoilValue(enquiryNumberList);
    const projectTypeExcel = useRecoilValue(projectType);
    const congreteTypeExcel = useRecoilValue(typeofConcrete);
    const projectDetExcel = useRecoilValue(projectDetailList);
    const congrateExcel = useRecoilValue(congreteGrade);
    const cementBrandExcel = useRecoilValue(cementBrand);
    const techPersonExcel = useRecoilValue(techPerson);
    const statusExcel = useRecoilValue(status);
    const zoneExcel = useRecoilValue(zoneFilter);
    const serchExcel = useRecoilValue(searchList);
    const getFromDate=useRecoilValue(filterFromDate);
    const getToDate=useRecoilValue(filterToDate);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const [filteredPage, setFilteredPage] = useRecoilState(filterPagination);
    // const [searchQuery, setSearchQuery] = useState("");
    const [searchTerm, setSearchTerm] = useRecoilState(inputSearchList);
    const [filterSearchTerm, setFilterSearchTerm] = useRecoilState(filterInputSearchList);
  
    const userRole = localStorage.getItem("user");
  let currUser = JSON.parse(userRole);

    const ApiURL = configParam.API_URL;
    const getSavedVisitNo = configParam.getSavedTrial;
    const apiRef = useRef(null);

    const getVisitStatus = (userRole, index) => {
        if (index["value"] === "Awaiting Approval") {
            return {
                icon: <HourglassTopIcon style={{ color: "red" }} />,
                label: index["value"],
                style: {
                    borderColor: "red"
                }
            }
        }
        else if (index["value"] === "Rejected") {
            return {
                icon: <CancelIcon style={{ color: "red" }} />,
                label: index["value"],
                style: {
                    borderColor: "red"
                }
            };
        }
        else if (index["value"] === "Approved") {
            return {
                icon: <CheckCircleIcon style={{ color: "green" }} />,
                label: index["value"],
                style: {
                    borderColor: "green"
                }
            };

        }
        else if (index["value"] === "resubmitted") {
            return {
                icon: <AddTaskIcon style={{ color: "orange" }} />,
                label: 'Re submitted',
                style: {
                    borderColor: "orange"
                }
            };

        }
        else if (index["value"] === "pending") {
            return {
                icon: <PendingIcon style={{ color: "orange" }} />,
                label: 'Pending',
                style: {
                    borderColor: "orange"
                }
            };

        }
        else if (index["value"] === "ReSubmit") {
            return {
                icon: <AutorenewIcon style={{ color: "blue" }} />,
                label: index["value"],
                style: {
                    borderColor: "blue"
                }
            };

        }
        else {
            <Chip
                size="small"
                label={index["value"]}
                style={{ width: "130px", textTransform: "capitalize" }}
            />
        }
    }
    const columns = [
        {
            field: "trial_number",
            headerName: "Trial No",
            width: 180,
            editable: false,
        },
        {
            field: "Action",
            headerName: "Actions",
            sortable: false,
            width: 100,
            disableClickEventBubbling: false,
            renderCell: (index, row) => {
                return (
                    <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ cursor: "pointer" }}
                    >
                        <Tooltip title="view">
                            <RemoveRedEyeIcon
                                id={index}
                                style={{
                                    marginRight: "20px",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setReadonly(true);
                                    onHandleEdit(index);
                                }}
                            />
                        </Tooltip>

                    </div>
                );
            },
        },
        {
            field: "tdate",
            headerName: "Trial Date",
            width: 150,
            editable: false,
            renderCell: (params) => {
                const { trial_date } = params.row;
                const fullName = `${trial_date ? moment(trial_date).format("YYYY-MM-DD") : ""}`;
                return <span>{fullName}</span>;
            }
        },
        {
            field: "customer_name",
            headerName: "Customer Name",
            width: 210,
            editable: false,
        },
        {
            field: "enquiry_number",
            headerName: "Enquiry Number",
            width: 210,
            editable: false,
            hide: currUser.role_id === 3,
        },

        {
            field: "tech1",
            headerName: "Technical Person Primary",
            width: 210,
            editable: false,
            renderCell: (params) => {
                const { technical_person_primary_fname, technical_person_primary_lname } = params.row;
                const fullName = `${technical_person_primary_fname ? technical_person_primary_fname : "--"} ${technical_person_primary_lname ? technical_person_primary_lname : "--"}`;
                return <span>{fullName}</span>;
            }
        },
        {
            field: "tech2",
            headerName: "Technical Person Secondary",
            width: 210,
            editable: false,
            renderCell: (params) => {
                const { technical_person_sec_fname, technical_person_sec_lname } = params.row;
                const fullName = `${technical_person_sec_fname ? technical_person_sec_fname : "--"} ${technical_person_sec_lname ? technical_person_sec_lname : "--"}`;
                return <span>{fullName}</span>;
            }
        },
        {
            field: "submittedname",
            headerName: "Submitted By",
            width: 190,
            editable: false,
            // renderCell: (params) => {
            //     const { c_first_name, c_last_name } = params.row;
            //     const fullName = `${c_first_name ? c_first_name : "--"} ${c_last_name ? c_last_name : "--"}`;
            //     return <span>{fullName}</span>;
            // }
        },
        {
            field: "status",
            headerName: "Result",
            width: 190,
            editable: false,
        },
        {
            field: "approvername",
            headerName: "Approved By",
            width: 190,
            editable: false,
            // renderCell: (params) => {
            //     const { a_first_name, a_last_name } = params.row;
            //     const fullName = `${a_first_name ? a_first_name : "--"} ${a_last_name ? a_last_name : "--"}`;
            //     return <span>{fullName}</span>;
            // }
        },
        {
            field: "submittedon",
            headerName: "Submitted On",
            width: 150,
            editable: false,
            // renderCell: (params) => {
            //     const { created_at } = params.row;
            //     const fullName = `${created_at ? moment(created_at).format("DD-MMM-YYYY") : ""}`;
            //     return <span>{fullName}</span>;
            // }
        },
        {
            field: "submission_status",
            headerName: "Status",
            width: 200,
            editable: false,
            renderCell: (status) => {
                return <Chip variant="outlined" {...getVisitStatus(userID, status)} />

            }
        },

    ];

    const getSelectedVisitData = async (data) => {
        setIsLoader(true);
        let url = ApiURL + 'DasbordTrialList';
        try {
            const response = await axios.post(url, {
                page: tablePage.page,
                limit: tablePage.pageSize,
                searchValue: data === "empty" ? "" : searchTerm

            });
            if (response.data.data.length > 0) {
                setFilterRowtotalcount(1)
                setVisitResData(response.data.data);
                setSearchDatas(response.data.data);
                setTotalCount(response.data.count);
                setTotalSearchCount(response.data.searchCount)

            } else {
                setFilterRowtotalcount(2);
                setTotalSearchCount(0)
                setVisitResData([]);
                setSearchDatas([])
            }

            setIsLoader(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoader(false);
        }
    };
    // Download ALL Data Single Sheet
    const getAllExcelData = async () => {
        // setIsLoading(true);
        let url = ApiURL + 'DownloadAllExcelDataList';
        try {
            const response = await axios.post(url, {
                // ZoneId: roleID.zone_id
            });

            if (response.data.data.length > 0) {
                setExcelSheetData(response.data.data);

            } else {
                setExcelSheetData([]);
            }

            // setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            // setIsLoading(false);
        }
    };

    const filterData = () => {
        const filteredObject = {
            customer: customerExcel.filter((data) => data.ischecked === true).map((val) => val.name),
            project: projectTypeExcel.filter((data) => data.ischecked === true).map((val) => val.type),
            congrate: congreteTypeExcel.filter((data) => data.ischecked === true).map((val) => val.type),
            projectDet: projectDetExcel.filter((data) => data.ischecked === true).map((val) => val.project_details),
            enqiryList:enquiryNumExcel.filter((data) => data.ischecked === true).map((val) => val.enquiry_number),
            congreteGrade: congrateExcel.filter((data) => data.ischecked === true).map((val) => val.grade),
            cementBrand: cementBrandExcel.filter((data) => data.ischecked === true).map((val) => val.type),
            status: statusExcel.filter((data) => data.ischecked === true).map((val) => val.status),
            tech: techPersonExcel.filter((data) => data.ischecked === true).map((val) => val.first_name + " " + val.last_name),
            selectedZone: zoneExcel.filter((data) => data.ischecked === true).map((val) => val.zone),
            serachOthers: serchExcel,
            fromDate:getFromDate,
            toDate:getToDate
        };

        return filteredObject;
    }


    const filterCheckBox = filterData();
    useEffect(() => {
        if (isTabDisabled || shouldSearch) {
            getSelectedVisitData();
            setShouldSearch(false);
        }
        getAllExcelData();
    }, [tablePage.page, tablePage.pageSize, shouldSearch,]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowButton(true);
        }, 5000);
        return () => clearTimeout(timer);
    }, []);

    const getEditDetails = (id) => {
        let url = ApiURL + 'getTrialDetails/' + id["row"]["id"];
        axios.get(url).then((res) => {
            if (res.data.length > 0) {
                setSelectVisitData(res.data[0]);
                // const trialVisit = getTrialVisitNo();
                setVisitType("2");
                localStorage.setItem("SavedTrialDetail", JSON.stringify(res.data[0]));
                navigate("/NewVisit");
            }
        });

    };

    const onHandleEdit = (id) => {
        setVisitType("2");
        setValue(0)
        localStorage.setItem("SavedTrialId", id["row"]["id"]);
        localStorage.setItem("DbVisitID", id["row"]["id"]);
        if (
            userID === 3 &&
            (id["row"]["submission_status"] === "" ||
                id["row"]["submission_status"] === "Awaiting Approval" ||
                id["row"]["submission_status"] === "pending" ||
                id["row"]["submission_status"] === "ReSubmit")
        ) {
            setErr(true);
            setMsg("pending trials are not accessible");
            return true;
        } else if (
            (userID === 2 || userID === 1) &&
            (id["row"]["submission_status"] === "Rejected")
        ) {
            setErr(true);
            setMsg("pending trials are not accessible");
            return true;
        } else {
            getEditDetails(id);
        }
    };



    const handleExportClick = () => {
        if (filterRowCountState !== 1) {
            console.log("callingINNNN");
            console.log("filterAllExcelArrayData",filterAllExcelArrayData)
            generateExcelFile(filterAllExcelArrayData, filterCheckBox);
        }
        else {
            console.log("callingOut")
            generateExcelFile(excelSheetData, filterCheckBox);
        }

    };




    const handlePageChange = (newPage) => {
        if (filterRowCountState !== 1) {
            setFilteredPage({ ...filteredPage, page: newPage + 1 });
            onHandleFilterSlice()
        } else {
            setTablePage({ ...tablePage, page: newPage + 1 });
        }
    };

    const handlePageSizeChange = (newPageSize) => {
        if (filterRowCountState !== 1) {
            const newPage = Math.min(filteredPage.page, Math.ceil(filterRowCountView / newPageSize) - 1);
            setFilteredPage({ ...filteredPage, pageSize: newPageSize, page: newPage });

        } else {
            const newPage = Math.min(tablePage.page, Math.ceil(totalcount / newPageSize) - 1);
            setTablePage({ ...tablePage, pageSize: newPageSize, page: newPage });
        }
    };

    const debouncedHandleKeyPress = debounce((value) => {
            getSelectedVisitData(value);

    }, 300);

    const handleKeyPress = (event) => {
        if (filterArrayData.length > 0) {
            if (event.key === 'Enter') {
                onSearch(event.target.value);
                // debouncedHandleKeyPress(event.target.value);
            }
        }
        else {
            if (event.key === 'Enter') {
                setSearchTerm(event.target.value);
                debouncedHandleKeyPress(event.target.value);
            }
        }


    };
    const handleChange = e => {
        if (filterArrayData.length > 0) {
            setFilterSearchTerm(e.target.value)
        }
        else {
            setSearchTerm(e.target.value);
            setShouldSearch(false);
        }

    };

    const clearInputField = () => {
        if (!filterArrayData.length > 0) {
            setSearchTerm("");
            getSelectedVisitData("empty");
            setTablePage({
                page: 0,
                pageSize: 50,
            })
            
        }
        else {
            setFilterSearchTerm("");
            // searchValue("")
            onSearch(null);
        }
      
    }

 
    return (
        <React.Fragment>
            {err && (
                <Alert
                    style={{ width: "90%" }}
                    variant="filled"
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setErr(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    {msg}
                </Alert>
            )}

            <Box sx={{ height: 500, width: "100%" }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                    <div>
                        {showButton && (
                            (visitResData.length > 0 || filterArrayData.length > 0) && roleID.role_id !== 3 && (
                                <Button onClick={handleExportClick}>
                                    {isMobile ? (
                                        <>
                                            <DownloadIcon />
                                        </>
                                    ) : (
                                        <><DownloadIcon /> <span>Download Excel</span></>
                                    )}
                                </Button>
                            )
                        )}
                    </div>
                    <div style={{ marginBottom: 5 }}>
                        <SearchInput placeholder="Search..." 
                        value={filterArrayData.length > 0 ?  filterSearchTerm : searchTerm} 
                        clearInputField={clearInputField} onChange={handleChange}
                            onKeyPress={(event) => handleKeyPress(event, getSelectedVisitData)}
                        />
                    </div>
                </div>

                {tableLoader && <div className='loader'><CircularProgress /> </div>}

                {isLoader && <CircularProgress style={{ position: 'fixed', top: '70%', left: '50%', transform: 'translate(-50%, -50%)' }} />}

                <DataGrid
                    className="visitTable"
                    rows={
                        filterRowCountState === 1
                            ? (searchDatas && searchDatas.length > 0 ? searchDatas : []) :
                            (filterArrayData && filterArrayData.length > 0 ? filterArrayData : [])

                    }
                    getRowId={row => row.id}
                    columns={columns}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: false,
                            csvOptions: { disableToolbarButton: true },
                            printOptions: { disableToolbarButton: true },
                        }
                    }}
                    style={{
                        fontSize: '12px',
                    }}
                    page={Math.max(0, filterRowCountState === 1 ? tablePage.page - 1 : filteredPage.page - 1)}
                    pageSize={Math.min(filterRowCountState === 1 ? tablePage.pageSize : filteredPage.pageSize, 100)}
                    rowsPerPageOptions={[10, 20, 30]}
                    rowCount={
                        searchTerm.length ? totalSearchcount :
                            filterRowCountState === 1 ? totalcount :
                                filterRowCountState === 3 ? filterRowCountView :
                                    filterRowCountState === 2 ? 0 :
                                        totalcount

                    }

                    apiRef={apiRef}
                    paginationMode="server"
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}

                />

            </Box>
        </React.Fragment>
    );
}

export default VisitTable;
