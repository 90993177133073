import * as React from "react";
import { Typography, FormControl } from "@mui/material";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function TrialDate(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Typography style={{ fontSize: 12, marginBottom: "5px", fontWeight: 600, lineHeight: 1.75 }}>{props.label}</Typography>
      <FormControl >
        {!props.isDate ? (
          <TimePicker
            ampm={false}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            error={props.error ? props.error : false}
            helperText={props.helperText ? props.helperText : ""}
            maxDate={props.maxLimit ? props.maxLimit : null}
            renderInput={(params) => <TextField size="small" {...params} />}
            disabled={props.readOnly ? props.readOnly : false}
          />
        ) : (
          <DatePicker
            views={props.graphYear&& ['year']}
            openTo={props.graphYear&& "year"}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
           
            helperText={props.helperText ? props.helperText : ""}
            maxDate={props.maxLimit ? props.maxLimit : null}
            disabled={props.true ? true : false}
            renderInput={(params) => <TextField size="small" {...params}   error={props.value === "" ? false : props.error} />}
          // disabled={props.readOnly ? props.readOnly : false}
          />
        )}
      </FormControl>
    </LocalizationProvider>
  );
}
