import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CancelIcon from "@mui/icons-material/Cancel";

const SuccessPopup = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div>
      {props.popupError ? (
        <Dialog
          fullScreen={fullScreen}
          open={props.open}
          onClose={props.onClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <CancelIcon
              style={{ color: "red", width: '100px', height: '100px' }}
              fontSize="large"
            />
            <DialogContentText>
              <p style={{ color: "Red", fontWeight: "bold" }}>
                {" "}
                Unable to submit!!, Please try again
              </p>
            </DialogContentText>
            <DialogActions>
            <Button color="error" variant="contained" onClick={props.onClose} autoFocus>
              Close
            </Button>
          </DialogActions>
          </DialogContent>
          
        </Dialog>
      ) : (
        <Dialog
          fullScreen={fullScreen}
          open={props.open}
          onClose={props.onClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <TaskAltIcon
              fontSize="large"
              color="success"
              style={{ width: '100px', height: '100px' }}
            />
            <DialogContentText>
              <p style={{ color: "green", fontWeight: "bold" }}>
                {props.msg ? props.msg : "Form successfully submitted"}
              </p>
            </DialogContentText>
            <DialogActions>
            <Button color="success" variant="contained" onClick={props.onClose} autoFocus>
              Close
            </Button>
          </DialogActions>
          </DialogContent>
          
        </Dialog>
      )}
    </div>
  );
};

export default SuccessPopup;
