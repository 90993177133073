const gqlQueries = {
  GET_PROJECT_TYPE: `
  query GetProjectType {
    chryso_project_type(where: {isdelete: {_eq: false}}) {
      id
      type
    }
  }
  `,
  GET_CONCRETE_GRADE: `
  query MyQuery {
    chryso_concrete_grade(where: {isdelete: {_eq: false}}) {
      id
      grade
    }
  }
  `,
  GET_CUSTOMER: `
  query getCustomer {
    chryso_customer(where: {isdelete: {_eq: false}}) {
      name
      id
    }
  }`,
  GET_CONCRETE_TYPE: `
  query GetconcreteType {
    chryso_concrete_type(where: {isdelete: {_eq: false}}) {
      id
      type
    }
  }
  `,
  GET_LAB_TYPE: `
  query GetLabType {
    chryso_lab_type(where: {isdelete: {_eq: false}}) {
      id
      type
    }
  }`,
  GET_SAND_CATEGORY: `
  query GetSandCategory {
    chryso_sand_category(where: {isdelete: {_eq: false}}) {
      id
      category
    }
  }`,
  GET_EARLY_TIME: `
  query GetEarlyTime {
    chryso_early_strength_time(where: {isdelete: {_eq: false}}) {
      time
      id
    }
  }
  `,
  GET_CEMENT_BRAND: `
  query MyQuery {
    chryso_cement_brand(where: {isdelete: {_eq: false}}) {
      id
      type
    }
  }
  `,
  GET_USER_DET: `
  query GetUsers {
    chryso_users(where: {isdelete: {_eq: false}}) {
      id
      first_name
      last_name
    }
  }
  `,
  CHECK_USERS: `
  query checkusers($sgid: String) {
    chryso_users(where: {sgid: {_eq: $sgid}, isdelete: {_eq: false}}) {
      sgid
      role_id
      email_id
      first_name
      last_name
      zone {
        name
        id
      }
      user_role_lines {
        role {
          role
          id
        }
      }
      id
    }
  }
      `,
  INSERT_USER: `
  mutation insert_chryso_users($email_id: String, $first_name: String, $last_name: String, $role_id: Int, $sgid: String, $zone_id: Int) {
    insert_chryso_user_role_line(objects: {user: {data: {created_at: "now()", email_id: $email_id, first_name: $first_name, last_name: $last_name, role_id: $role_id, sgid: $sgid, zone_id: $zone_id, isdelete: false}}, role_id: $role_id, line_id: "fac735fa-66ab-4433-aef0-928c230401fe", created_at: "now()"}) {
      affected_rows
    }
  }
  
     `,
  UPDATE_USER: `
  mutation update_chryso_users($id: uuid, $email_id: String, $first_name: String, $last_name: String, $role_id: Int, $sgid: String, $zone_id: Int) {
    update_chryso_users(where: {id: {_eq: $id}}, _set: {email_id: $email_id, first_name: $first_name, last_name: $last_name, role_id: $role_id, sgid: $sgid, zone_id: $zone_id}) {
      affected_rows
    }
    update_chryso_user_role_line(where: {user_id: {_eq: $id}}, _set: {role_id: $role_id}) {
      affected_rows
    }
  }
`,
  DEL_USER: `
  mutation update_chryso_users($id: uuid) {
    update_chryso_users(where: {id: {_eq: $id}}, _set: {isdelete: true}) {
      affected_rows
    }
  }
  
`,
  USERS_LIST: `
  query checkusers {
    chryso_users(where: {isdelete: {_eq: false}}) {
      sgid
      role_id
      email_id
      first_name
      last_name
      id
      zone {
        name
        id
      }
      role {
        role
        id
      }
    }
  }
  `,
  GET_ZONE_COUNT: `
  query chryso_zone_count {
    chryso_zone {
      trials_aggregate(where: {isdelete: {_eq: false}}) {
        aggregate {
          count
        }
      }
      name
    }
  }`,
  GETRole: `
      query chryso_roles {
        chryso_roles {
          id
          role
        }
      }
      `,
  GET_ZONE: `
      query chryso_zone {
        chryso_zone {
          id
          name
        }
      }
      `,
  INSERT_VISIT_DATA: `
  mutation InsertVisitData($cost_factor_comparison: numeric, $ref_previous_trial_data: String, $remarks: String, $status: String, $laboratory_type_id: uuid, $tech_person_id_1: uuid, $tech_person_id_2: uuid, $trial_date: timestamptz, $trial_number: String, $aggregates: jsonb, $cement_brand: uuid, $cement_content: numeric, $flyash: numeric, $free_water: numeric, $ggbfs_content: numeric, $microsilica_content: numeric, $alkali_content: numeric, $ambient_humidity: numeric, $ambient_temperature: numeric, $clear_test10: numeric, $clear_test50: numeric, $coarse_aggregate_150micpass10: numeric, $coarse_aggregate_150micpass20: numeric, $sand_category_id: uuid, $sand_passing150: numeric, $sand_passing600: numeric, $w_cm: numeric, $air_content1: numeric, $concrete_grade_id: uuid, $concrete_type_id: uuid, $customer_name: uuid, $early_strength_mpa: numeric, $early_strength_time_id: uuid, $enquiry_number: numeric, $project_details: String, $project_location: String, $project_type_id: uuid, $retention1: String, $target_strength_28_days: numeric, $workability_flow_slump: numeric, $compositions: jsonb, $sample_reference_no: String, $air_content2: numeric, $dosage: numeric, $early_strength2: numeric, $retention2: String, $strength_at_28_days: numeric, $strength_at_7_days: numeric, $workability: numeric, $created_by: uuid) {
    insert_chryso_trials(objects: {comment: {data: {cost_factor_comparison: $cost_factor_comparison, ref_previous_trial_data: $ref_previous_trial_data, remarks: $remarks, status: $status}}, trial_detail: {data: {laboratory_type_id: $laboratory_type_id, tech_person_id_1: $tech_person_id_1, tech_person_id_2: $tech_person_id_2, trial_date: $trial_date, trial_number: $trial_number}}, concrete_mix_detail: {data: {aggregates: $aggregates, cement_brand: $cement_brand, cement_content: $cement_content, flyash: $flyash, free_water: $free_water, ggbfs_content: $ggbfs_content, microsilica_content: $microsilica_content}}, supporting_info: {data: {alkali_content: $alkali_content, ambient_humidity: $ambient_humidity, ambient_temperature: $ambient_temperature, clear_test10: $clear_test10, clear_test50: $clear_test50, coarse_aggregate_150micpass10: $coarse_aggregate_150micpass10, coarse_aggregate_150micpass20: $coarse_aggregate_150micpass20, sand_category_id: $sand_category_id, sand_passing150: $sand_passing150, sand_passing600: $sand_passing600, w_cm: $w_cm}}, client_detail: {data: {air_content: $air_content1, concrete_grade_id: $concrete_grade_id, concrete_type_id: $concrete_type_id, customer_name: $customer_name, early_strength_mpa: $early_strength_mpa, early_strength_time_id: $early_strength_time_id, enquiry_number: $enquiry_number, project_details: $project_details, project_location: $project_location, project_type_id: $project_type_id, retention: $retention1, target_strength_28_days: $target_strength_28_days, workability_flow_slump: $workability_flow_slump}}, admixture_composition: {data: {compositions: $compositions, sample_reference_no: $sample_reference_no}}, trial_result: {data: {air_content: $air_content2, dosage: $dosage, early_strength: $early_strength2, retention: $retention2, strength_at_28_days: $strength_at_28_days, strength_at_7_days: $strength_at_7_days, workability: $workability}}, created_by: $created_by}) {
      returning {
        comments_id
        id
        trial_details_id
        concrete_mix_details_id
        supporting_info_id
        client_details_id
        admixture_composition_id
        trial_results_id
      }
      affected_rows
    }
  }
  `,
  TRIAL_SOFT_DEL: `
  mutation TRIAL_SOFT_DEL($id: uuid) {
    update_chryso_trials(where: {id: {_eq: $id}}, _set: {isdelete: true}) {
      affected_rows
    }
  }
  
  `,
  INSERT_TRIAL_APPROVEL: `
  mutation insertTrialApprovel($trial_id: uuid, $approvel_id: uuid, $status: String, $user_id: uuid, $submission_status: String, $rejection_notes: String, $zone_id: numeric = "") {
    update_chryso_trials(where: {id: {_eq: $trial_id}}, _set: {submission_status: $submission_status, rejection_notes: $rejection_notes, zone_id: $zone_id, user_id: $user_id,approver_id: $approvel_id}) {
      affected_rows
    }
    insert_chryso_trial_approval(objects: {approval_id: $approvel_id, status: $status, trial_id: $trial_id, user_id: $user_id}) {
      affected_rows
    }
  }
  `,
  UPDATE_VISIT_DATA: `
  mutation updateVisitData($laboratory_type_id: uuid , $tech_person_id_1: uuid, $tech_person_id_2: uuid, $trial_date: timestamptz , $trial_number: String, $air_content1: numeric, $concrete_grade_id: uuid, $concrete_type_id: uuid, $customer_name: uuid, $early_strength_mpa: numeric, $early_strength_time_id: uuid, $enquiry_number: numeric, $project_details: String, $project_location: String, $project_type_id: uuid, $retention1: String , $target_strength_28_days: numeric , $workability_flow_slump: numeric, $aggregates: jsonb, $cement_brand: uuid, $cement_content: numeric, $flyash: numeric, $free_water: numeric, $ggbfs_content: numeric, $microsilica_content: numeric, $alkali_content: numeric, $ambient_humidity: numeric, $ambient_temperature: numeric, $clear_test10: numeric, $clear_test50: numeric, $coarse_aggregate_150micpass10: numeric, $coarse_aggregate_150micpass20: numeric, $sand_category_id: uuid , $sand_passing150: numeric, $sand_passing600: numeric, $w_cm: numeric, $compositions: jsonb, $sample_reference_no: String , $air_content2: numeric, $dosage: numeric, $early_strength2: numeric, $retention2: String, $strength_at_28_days: numeric, $strength_at_7_days: numeric, $workability1: numeric, $cost_factor_comparison: numeric, $ref_previous_trial_data: String , $remarks: String , $status: String,$trialdetid:uuid,$clientid:uuid,$congrateid:uuid,$supinfoid:uuid,$admixureid:uuid,$trialResid:uuid,$commentid:uuid) {
    trial_detail: update_chryso_trial_details(where: {id: {_eq: $trialdetid}}, _set: {laboratory_type_id: $laboratory_type_id, tech_person_id_1: $tech_person_id_1, tech_person_id_2: $tech_person_id_2, trial_date: $trial_date, trial_number: $trial_number}) {
      affected_rows
    }
    client_detail: update_chryso_client_details(where: {id: {_eq: $clientid}}, _set: {air_content: $air_content1, concrete_grade_id: $concrete_grade_id, concrete_type_id: $concrete_type_id, customer_name: $customer_name, early_strength_mpa: $early_strength_mpa, early_strength_time_id: $early_strength_time_id, enquiry_number: $enquiry_number, project_details: $project_details, project_location: $project_location, project_type_id: $project_type_id, retention: $retention1, target_strength_28_days: $target_strength_28_days, workability_flow_slump: $workability_flow_slump}) {
      affected_rows
    }
    concrete_mix_detail: update_chryso_concrete_mix_details(where: {id: {_eq: $congrateid}}, _set: {aggregates: $aggregates, cement_brand: $cement_brand, cement_content: $cement_content, flyash: $flyash, free_water: $free_water, ggbfs_content: $ggbfs_content, microsilica_content: $microsilica_content}) {
      affected_rows
    }
    supporting_info: update_chryso_supporting_info(where: {id: {_eq: $supinfoid}}, _set: {alkali_content: $alkali_content, ambient_humidity: $ambient_humidity, ambient_temperature: $ambient_temperature, clear_test10: $clear_test10, clear_test50: $clear_test50, coarse_aggregate_150micpass10: $coarse_aggregate_150micpass10, coarse_aggregate_150micpass20: $coarse_aggregate_150micpass20, sand_category_id: $sand_category_id, sand_passing150: $sand_passing150, sand_passing600: $sand_passing600, w_cm: $w_cm}) {
      affected_rows
    }
    admixture_composition: update_chryso_admixture_composition(where: {id: {_eq: $admixureid}}, _set: {compositions: $compositions, sample_reference_no: $sample_reference_no}) {
      affected_rows
    }
    trial_result: update_chryso_trial_results(where: {id: {_eq: $trialResid}}, _set: {air_content: $air_content2, dosage: $dosage, early_strength: $early_strength2, retention: $retention2, strength_at_28_days: $strength_at_28_days, strength_at_7_days: $strength_at_7_days, workability: $workability1}) {
      affected_rows
    }
    comment: update_chryso_comments(where: {id: {_eq: $commentid}}, _set: {cost_factor_comparison: $cost_factor_comparison, ref_previous_trial_data: $ref_previous_trial_data, remarks: $remarks, status: $status}) {
      affected_rows
    }
  }
  `,
  GET_ADVANCE_SEARCH_DATA: `
  query GET_FILTER_RESULT {
    chryso_trials(where: {isdelete: {_eq: false}}) {
      trial_detail {
        laboratory_type_id
        tech_person_id_1
        tech_person_id_2
        trial_date
        trial_number
        id
        user {
          first_name
          last_name
          id
        }
      }
      id
      submission_status
      client_detail {
        enquiry_number
        customer {
          name
        }
      }
      admixture_composition {
        sample_reference_no
      }
      user {
        first_name
        last_name
      }
      approver_id
      user_id
      created_at
      created_by
      comment {
        status
      }
    }
  }
   
  `,
  GET_ALL_ZONE_VISIT: `
  query getVisitData {
    chryso_trials{
      trial_detail {
        laboratory_type_id
        tech_person_id_1
        tech_person_id_2
        trial_date
        trial_number
        id
        user {
          first_name
          last_name
          id
        }
      }
      id
      submission_status
      client_detail {
        enquiry_number
        customer {
          name
        }
      }
      admixture_composition {
        sample_reference_no
      }
      user {
        first_name
        last_name
      }
      approver_id
      user_id
      created_at
    }
  }
  `,
  GET_SELECTED_VISIT_DATA: `
  query getVisitData($zoneId: numeric) {
    chryso_trials(where: {zone_id: {_eq: $zoneId}, isdelete: {_eq: false}}) {
      trial_detail {
        laboratory_type_id
        tech_person_id_1
        tech_person_id_2
        trial_date
        trial_number
        id
        user {
          first_name
          last_name
          id
        }
      }
      id
      submission_status
      client_detail {
        enquiry_number
        customer {
          name
        }
      }
      admixture_composition {
        sample_reference_no
      }
      user {
        first_name
        last_name
      }
      approver_id
      user_id
      created_at
      created_by
    }
  }
  
  
  
  `,
  GET_FILTER_RESULT: `
  query GET_FILTER_RESULT($project_type: [uuid!], $concrete_type: [uuid!], $customer: [uuid!], $concrete_grade_id: [uuid!], $frmWorkabilityFlowSlump: numeric, $toWorkabilityFlowSlump: numeric, $toAirContent: numeric, $frmAirContent: numeric, $frmEalyStrengthTimeMpa: numeric, $toEalyStrengthTimeMpa: numeric, $fromTargetStrength28Days: numeric, $toTargetStrength28Days: numeric, $cementBrand: [uuid!], $frmCementContent: numeric, $toCementContent: numeric, $frmFlyash: numeric, $toFlyash: numeric, $frmGGBFS: numeric, $toGGBFS: numeric, $frmMicroSilica: numeric, $toMicroSilica: numeric, $frmWCM: numeric, $toWCM: numeric, $frmAlkaliContent: numeric, $toAlkaliContent: numeric, $fromSandPassing600: numeric, $toSandPassing600: numeric, $frmSandPassing150: numeric, $toSandPassing150: numeric, $frmClearTest10: numeric, $toClearTest10: numeric, $frmClearTest50: numeric, $toClearTest50: numeric, $frm_coarse_aggregate_150micpass10: numeric, $to_coarse_aggregate_150micpass10: numeric, $frm_coarse_aggregate_150micpass20: numeric, $to_coarse_aggregate_150micpass20: numeric, $frm_ambient_humidity: numeric, $to_ambient_humidity: numeric, $frm_ambient_temperature: numeric, $to_ambient_temperature: numeric, $status: [String!], $chryso_client_details: [String!], $project_location: [String!], $frmRetention: String, $toRetention: String) {
    chryso_trials(where: {client_detail: {project_type_id: {_in: $project_type}, concrete_type_id: {_in: $concrete_type}, customer_name: {_in: $customer}, concrete_grade_id: {_in: $concrete_grade_id}, workability_flow_slump: {_gte: $frmWorkabilityFlowSlump, _lte: $toWorkabilityFlowSlump}, air_content: {_gte: $frmAirContent, _lte: $toAirContent}, early_strength_mpa: {_gte: $frmEalyStrengthTimeMpa, _lte: $toEalyStrengthTimeMpa}, target_strength_28_days: {_gte: $fromTargetStrength28Days, _lte: $toTargetStrength28Days}}, concrete_mix_detail: {cement_brand: {_in: $cementBrand}, cement_content: {_gte: $frmCementContent, _lte: $toCementContent}, flyash: {_gte: $frmFlyash, _lte: $toFlyash}, ggbfs_content: {_gte: $frmGGBFS, _lte: $toGGBFS}, microsilica_content: {_gte: $frmMicroSilica, _lte: $toMicroSilica}}, supporting_info: {alkali_content: {_gte: $frmAlkaliContent, _lte: $toAlkaliContent}, sand_passing600: {_gte: $fromSandPassing600, _lte: $toSandPassing600}, sand_passing150: {_gte: $frmSandPassing150, _lte: $toSandPassing150}, clear_test10: {_gte: $frmClearTest10, _lte: $toClearTest10}, clear_test50: {_gte: $frmClearTest50, _lte: $toClearTest50}, coarse_aggregate_150micpass10: {_gte: $frm_coarse_aggregate_150micpass10, _lte: $to_coarse_aggregate_150micpass10}, coarse_aggregate_150micpass20: {_gte: $frm_coarse_aggregate_150micpass20, _lte: $to_coarse_aggregate_150micpass20}, ambient_humidity: {_gte: $frm_ambient_humidity, _lte: $to_ambient_humidity}, ambient_temperature: {_gte: $frm_ambient_temperature, _lte: $to_ambient_temperature}}, comment: {status: {_in: $status}, trials: {isdelete: {_eq: false}}}}) {
      trial_detail {
        laboratory_type_id
        tech_person_id_1
        tech_person_id_2
        trial_date
        trial_number
        id
        user {
          first_name
          last_name
          id
        }
      }
      id
      submission_status
      client_detail {
        enquiry_number
        customer {
          name
        }
      }
      admixture_composition {
        sample_reference_no
      }
      user {
        first_name
        last_name
      }
      approver_id
      user_id
      created_at
      created_by
      comment {
        status
      }
    }
  }
  
  
  `,
  GET_USER_LIST: `
  query GET_USER_LIST {
    chryso_users {
      first_name
      last_name
      id
    }
  }
  
  `,
  REPLACE_VALUE: `
  query REPLACE_VALUE($trialNumber: String ) {
    chryso_trial_details(where: {trial_number: {_eq: $trialNumber}}) {
      trials {
         trial_detail {
          laboratory_type_id
          tech_person_id_1
          tech_person_id_2
          trial_date
          trial_number
          id
        }
        client_detail {
          air_content
          concrete_grade_id
          concrete_type_id
          customer_name
          early_strength_mpa
          early_strength_time_id
          enquiry_number
          project_details
          project_location
          project_type_id
          retention
          target_strength_28_days
          workability_flow_slump
          id
        }
        concrete_mix_detail {
          aggregates
          cement_brand
          cement_content
          created_at
          flyash
          free_water
          ggbfs_content
          microsilica_content
          id
        }
        supporting_info {
          alkali_content
          ambient_humidity
          ambient_temperature
          clear_test10
          clear_test50
          coarse_aggregate_150micpass10
          coarse_aggregate_150micpass20
          sand_category_id
          sand_passing150
          sand_passing600
          w_cm
          created_at
          id
        }
        admixture_composition {
          sample_reference_no
          compositions
          id
        }
        trial_result {
          air_content
          dosage
          early_strength
          retention
          strength_at_28_days
          strength_at_7_days
          workability
          id
        }
        comment {
          cost_factor_comparison
          ref_previous_trial_data
          remarks
          status
          id
        }
        submission_status
        rejection_notes
      }
    }
  }
  `,
  GET_SELECTED_VISIT_EDIT_DATA: `
  query getVisitData($id: uuid) {
    chryso_trials(where: {id: {_eq: $id}}) {
      trial_detail {
        laboratory_type_id
        tech_person_id_1
        tech_person_id_2
        trial_date
        trial_number
        id
      }
      client_detail {
        air_content
        concrete_grade_id
        concrete_type_id
        customer_name
        early_strength_mpa
        early_strength_time_id
        enquiry_number
        project_details
        project_location
        project_type_id
        retention
        target_strength_28_days
        workability_flow_slump
        id
      }
      concrete_mix_detail {
        aggregates
        cement_brand
        cement_content
        created_at
        flyash
        free_water
        ggbfs_content
        microsilica_content
        id
      }
      supporting_info {
        alkali_content
        ambient_humidity
        ambient_temperature
        clear_test10
        clear_test50
        coarse_aggregate_150micpass10
        coarse_aggregate_150micpass20
        sand_category_id
        sand_passing150
        sand_passing600
        w_cm
        created_at
        id
      }
      admixture_composition {
        sample_reference_no
        compositions
        id
      }
      trial_result {
        air_content
        dosage
        early_strength
        retention
        strength_at_28_days
        strength_at_7_days
        workability
        id
      }
      comment {
        cost_factor_comparison
        ref_previous_trial_data
        remarks
        status
        id
      }
      submission_status
      rejection_notes
    }
  }
  `,
  // insert Table base Id
  INSERT_VISIT: `
mutation insertTableId($admixture_composition_id: uuid , $concrete_mix_details_id: uuid , $supporting_info_id: uuid , $trial_details_id: uuid, $comments_id: uuid , $client_details_id: uuid, $trial_results_id: uuid) {
  insert_chryso_trials(objects: {trial_details_id: $trial_details_id, client_details_id: $client_details_id, supporting_info_id: $supporting_info_id, concrete_mix_details_id: $concrete_mix_details_id, admixture_composition_id: $admixture_composition_id, comments_id: $comments_id, trial_results_id: $trial_results_id}) {
    affected_rows
  }
}`,
  // AddDropdownCategory
  GET_ADD_DROPDOWN_CATEGORY: `
  query MyQuery {
    chryso_sand_category(where: {isdelete: {_eq: false}}) {
      category
      id
      created_at
    }
    chryso_concrete_grade(where: {isdelete: {_eq: false}}) {
      created_at
      grade
      id
    }
    chryso_project_type(where: {isdelete: {_eq: false}}) {
      created_at
      id
      type
    }
    chryso_lab_type(where: {isdelete: {_eq: false}}) {
      id
      type
      created_at
    }
    chryso_concrete_type(where: {isdelete: {_eq: false}}) {
      created_at
      id
      type
    }
    chryso_early_strength_time(where: {isdelete: {_eq: false}}) {
      time
      created_at
      id
    }
    chryso_customer(where: {isdelete: {_eq: false}}) {
      created_at
      id
      name
    }
    chryso_cement_brand(where: {isdelete: {_eq: false}}) {
      id
      type
      created_at
    }
  }
  `,
  REMOVE_TRIAL: `
  mutation MyMutation($id: uuid) {
    delete_chryso_trials(where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
  `,
  // INSERT DROPDOWN DATA START
  INSERT_ADD_LABTYPE_DATA: `
  mutation MyMutation($created_at: timestamptz , $type: String ) {
    insert_chryso_lab_type(objects: {created_at: $created_at, type: $type}) {
      affected_rows
    }
  }
  `,
  GET_ADVANCED_SEARCH: `
  query GET_ADVANCED_SEARCH {
    chryso_project_type(where: {isdelete: {_eq: false}}) {
      type
      id
    }
    chryso_concrete_type(where: {isdelete: {_eq: false}}) {
      type
      created_at
      id
    }
    chryso_customer(where: {isdelete: {_eq: false}}) {
      name
      id
    }
    chryso_concrete_grade(where: {isdelete: {_eq: false}}) {
      grade
      id
    }
    chryso_sand_category(where: {isdelete: {_eq: false}}) {
      id
      category
    }
    chryso_cement_brand(where: {isdelete: {_eq: false}}) {
      id
      type
    }
    chryso_early_strength_time(where: {isdelete: {_eq: false}}) {
      time
      id
    } 
    chryso_client_details(distinct_on: project_details) {
      project_details
    }
  }
  
  `,
  GET_PROJECT_LOCATION: `
  query getProjectLocation {
    chryso_client_details(distinct_on: project_location) {
      project_location
    }
  }
  `,
  INSERT_ADD_CEMENT_BRAND_DATA: `
  mutation MyMutation($created_at: timestamptz , $type: String ) {
    chryso_cement_brand(objects: {created_at: $created_at, type: $type}) {
      affected_rows
    }
  }
  `,
  INSERT_CONGRETEGRADE_DATA: `
  mutation MyMutation($created_at: timestamptz, $grade: String) {
    insert_chryso_concrete_grade(objects: {created_at: $created_at, grade: $grade}) {
      affected_rows
    }
  }`,
  INSERT_PROJECTTYPE_DATA: `
  mutation MyMutation($type: String , $created_at: timestamptz) {
    insert_chryso_project_type(objects: {type: $type, created_at: $created_at}) {
    affected_rows
    }
  }`,
  INSERT_CONGRETETYPE_DATA: `
  mutation MyMutation($created_at: timestamptz , $type: String) {
    insert_chryso_concrete_type(objects: {created_at: $created_at, type: $type}) {
      affected_rows
    }
  }`,
  INSERT_EARLYSTRENGTH_TIME_DATA: `
  mutation MyMutation($created_at: timestamptz, $time: numeric) {
    insert_chryso_early_strength_time(objects: {created_at: $created_at, time: $time}) {
      affected_rows
    }
  }`,
  INSERT_SANDCATEGORY: `
  mutation MyMutation($created_at: timestamptz, $category: String) {
    insert_chryso_sand_category(objects: {created_at: $created_at, category: $category}) {
      affected_rows
    }
  }`,
  INSERT_CEMENT_BRAND: `
  mutation MyMutation($created_at: timestamptz, $type: String) {
    insert_chryso_cement_brand(objects: {created_at: $created_at, type: $type}) {
      affected_rows
    }
  }`,
  // Update Query Start
  UPDATE_ADD_LABTYPE_DATA: `
  mutation MyMutation($id: uuid, $type: String, $updated_at: timestamptz) {
    update_chryso_lab_type(where: {id: {_eq: $id}}, _set: {type: $type, updated_at: $updated_at}) {
      affected_rows
    }
  }
  `,
  INSERT_CUSTOMER_NAME: `
  mutation MyMutation($created_at: timestamptz, $name: String) {
    insert_chryso_customer(objects: {created_at: $created_at, name: $name}) {
      affected_rows
    }
  }`,
  UPDATE_PROJECT_TYPE: `
  mutation MyMutation($updated_at: timestamptz, $type: String, $id: uuid) {
     update_chryso_project_type(where: {id: {_eq: $id}}, _set: {updated_at: $updated_at, type: $type}) {
        affected_rows
     }
  }
  `,
  UPDATE_CONCRETE_GRADE: `
  mutation MyMutation($id: uuid, $grade: String, $updated_at: timestamptz) {
      update_chryso_concrete_grade(where: {id: {_eq: $id}}, _set: {grade: $grade, updated_at: $updated_at}) {
        affected_rows
      }
    }
  `,
  UPDATE_CONCRETE_TYPE: `
  mutation MyMutation($id: uuid , $type: String, $updated_at: String) {
    update_chryso_concrete_type(where: {id: {_eq: $id}}, _set: {type: $type, updated_at: $updated_at}) {
      affected_rows
    }
  }
  `,
  UPDATE_SAND_CATEGORY: `
  mutation MyMutation($id: uuid , $category: String, $updated_at: timestamptz) {
    update_chryso_sand_category(where: {id: {_eq: $id}}, _set: {category: $category, updated_at: $updated_at}) {
      affected_rows
    }
  }
  `,
  UPDATE_CEMENT_BRAND: `
  mutation MyMutation($id: uuid , $type: String, $updated_at: timestamptz) {
    update_chryso_cement_brand(where: {id: {_eq: $id}}, _set: {type: $type, updated_at: $updated_at}) {
      affected_rows
    }
  }
  `,
  UPDATE_EARLY_STRENGTH_TIME: `
  mutation MyMutation($id: uuid , $time: numeric, $updated_at: timestamptz ) {
    update_chryso_early_strength_time(where: {id: {_eq: $id}}, _set: {time: $time, updated_at: $updated_at}) {
      affected_rows
    }
  }
  `,
  UPDATE_CUSTOMER_NAME: `
  mutation MyMutation($id: uuid , $name: String, $updated_at: timestamptz) {
    update_chryso_customer(where: {id: {_eq: $id}}, _set: {name: $name, updated_at: $updated_at}) {
      affected_rows
    }
  }
  `,
  // DELETE QUERY START
  DELETE_LABTYPE_DATA: `
  mutation MyMutation($id: uuid) {
    update_chryso_lab_type(where: {id: {_eq: $id}}, _set: {isdelete: true}) {
      affected_rows
    }
  }
  `,
  DELETE_PROJECT_TYPE: `
  mutation MyMutation($id: uuid) {
    update_chryso_project_type(where: {id: {_eq: $id}}, _set: {isdelete: true}) {
      affected_rows
    }
  }
  `,
  DELETE_CONCRETE_GRADE: `
  mutation MyMutation($id: uuid) {
    update_chryso_concrete_grade(where: {id: {_eq: $id}}, _set: {isdelete: true}) {
      affected_rows
    }
  }
  `,
  DELETE_CONCRETE_TYPE: `
  mutation MyMutation($id: uuid) {
    update_chryso_concrete_type(where: {id: {_eq: $id}}, _set: {isdelete: true}) {
      affected_rows
    }
  }
  `,
  DELETE_EARLY_STRENGTH_TIME: `
  mutation MyMutation($id: uuid) {
    update_chryso_early_strength_time(where: {id: {_eq: $id}}, _set: {isdelete: true}) {
      affected_rows
    }
  }
  `,
  DELETE_SAND_CATEGORY: `
  mutation MyMutation($id: uuid) {
    update_chryso_sand_category(where: {id: {_eq: $id}}, _set: {isdelete: true}) {
      affected_rows
    }
  }
  `,
  DELETE_CEMENT_BRAND: `
  mutation MyMutation($id: uuid) {
    update_chryso_cement_brand(where: {id: {_eq: $id}}, _set: {isdelete: true}) {
      affected_rows
    }
  }
  `,
  DELETE_CUSTOMER: `
  mutation MyMutation($id: uuid) {
    update_chryso_customer(where: {id: {_eq: $id}}, _set: {isdelete: true}) {
      affected_rows
    }
  }
  `,
  GET_MONTH_WISE_VISIT_COUNT: `
  query GET_MONTH_WISE_VISIT_COUNT($frmDate: timestamptz, $toDate: timestamptz) {
    chryso_zone_aggregate {
      nodes {
        name
        trials_aggregate(where: {created_at: {_gte: $frmDate, _lte: $toDate}, isdelete: {_eq: false}}) {
          aggregate {
            count
          }
        }
      }
    }
  } 
  `,
  MONTH_WISE_VISIT_COUNT_BY_FILTER: `
  query GET_MONTH_WISE_VISIT_COUNT($frmDate: timestamptz, $toDate: timestamptz, $status: [String!], $project_type: [uuid!], $concrete_type: [uuid!], $customer: [uuid!], $concrete_grade_id: [uuid!], $frmWorkabilityFlowSlump: numeric, $toWorkabilityFlowSlump: numeric, $frmAirContent: numeric, $toAirContent: numeric, $frmEalyStrengthTimeMpa: numeric, $toEalyStrengthTimeMpa: numeric, $fromTargetStrength28Days: numeric, $toTargetStrength28Days: numeric, $cementBrand: [uuid!], $frmCementContent: numeric, $toCementContent: numeric, $frmFlyash: numeric, $toFlyash: numeric, $frmGGBFS: numeric, $toGGBFS: numeric, $frmMicroSilica: numeric, $toMicroSilica: numeric, $frmAlkaliContent: numeric, $toAlkaliContent: numeric, $fromSandPassing600: numeric, $toSandPassing600: numeric, $frmSandPassing150: numeric, $toSandPassing150: numeric, $frmClearTest10: numeric, $toClearTest10: numeric, $frmClearTest50: numeric, $toClearTest50: numeric, $frm_coarse_aggregate_150micpass10: numeric, $to_coarse_aggregate_150micpass10: numeric, $frm_coarse_aggregate_150micpass20: numeric, $to_coarse_aggregate_150micpass20: numeric, $frm_ambient_humidity: numeric, $to_ambient_humidity: numeric, $frm_ambient_temperature: numeric , $to_ambient_temperature: numeric ) {
    chryso_zone_aggregate {
      nodes {
        name
        trials_aggregate(where: {created_at: {_gte: $frmDate, _lte: $toDate}, isdelete: {_eq: false}, comment: {status: {_in: $status}}, client_detail: {project_type_id: {_in: $project_type}, concrete_type_id: {_in: $concrete_type}, customer_name: {_in: $customer}, concrete_grade_id: {_in: $concrete_grade_id}, workability_flow_slump: {_gte: $frmWorkabilityFlowSlump, _lte: $toWorkabilityFlowSlump}, air_content: {_gte: $frmAirContent, _lte: $toAirContent}, early_strength_mpa: {_gte: $frmEalyStrengthTimeMpa, _lte: $toEalyStrengthTimeMpa}, target_strength_28_days: {_gte: $fromTargetStrength28Days, _lte: $toTargetStrength28Days}}, concrete_mix_detail: {cement_brand: {_in: $cementBrand}, cement_content: {_gte: $frmCementContent, _lte: $toCementContent}, flyash: {_gte: $frmFlyash, _lte: $toFlyash}, ggbfs_content: {_gte: $frmGGBFS, _lte: $toGGBFS}, microsilica_content: {_gte: $frmMicroSilica, _lte: $toMicroSilica}}, supporting_info: {alkali_content: {_gte: $frmAlkaliContent, _lte: $toAlkaliContent}, sand_passing600: {_gte: $fromSandPassing600, _lte: $toSandPassing600}, sand_passing150: {_gte: $frmSandPassing150, _lte: $toSandPassing150}, clear_test10: {_gte: $frmClearTest10, _lte: $toClearTest10}, clear_test50: {_gte: $frmClearTest50, _lte: $toClearTest50}, coarse_aggregate_150micpass10: {_gte: $frm_coarse_aggregate_150micpass10, _lte: $to_coarse_aggregate_150micpass10}, coarse_aggregate_150micpass20: {_gte: $frm_coarse_aggregate_150micpass20, _lte: $to_coarse_aggregate_150micpass20}, ambient_humidity: {_gte: $frm_ambient_humidity, _lte: $to_ambient_humidity}, ambient_temperature: {_gte: $frm_ambient_temperature, _lte: $to_ambient_temperature}}}) {
          aggregate {
            count
          }
        }
      }
    }
  }`,
  GET_ZONE_LIST: `
  query GET_ZONE_LIST {
    chryso_zone {
      name
    }
  }
  
  `,
};
export default gqlQueries;
