import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import configParam from "../Config";
import gqlQueries from "../Queries/Queries";
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from "axios";
const LeftContainer = () => {
  const matches = useMediaQuery('(max-width:600px)');
  const sgid = localStorage.getItem("UserID");
  const ApiURL = configParam.API_URL;
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    getUserDetails(sgid);
  }, []);
  const getUserDetails = (sgid) => {
    let url = ApiURL + 'get_current_users/' + sgid;
    axios.get(url).then((response) => {
      console.log("fgdgdfg", response);
      setUserDetails(response.data[0]);
    }).catch((error) => {
      setUserDetails([]);
      console.log("get_current_users", error);
    });
    // configParam
    //   .RUN_GQL_API(gqlQueries.CHECK_USERS, { sgid: sgid })
    //   .then((data) => {
    //     if (data.chryso_users.length > 0) {
    //       setUserDetails(data.chryso_users[0]);
    //     } else {
    //       setUserDetails([]);
    //     }
    //   });
  };

  return (
    <React.Fragment>
      <div style={{}}>
        <Card>
          <CardContent>
            <Avatar
              alt="Remy Sharp"
              src="/broken-image.jpg"
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                margin: "auto",
                textAlign: "center",
                background: "#FFC400",
                fontSize: "40px",
                fontWeight: "bold",
                textTransform: 'uppercase'
              }}
            >

              {

                (Object.keys(userDetails).length > 0 && userDetails.first_name ? userDetails.first_name.charAt(0) : "") + "" + (Object.keys(userDetails).length > 0 && userDetails.first_name ? userDetails.last_name.charAt(0) : "")
              }
            </Avatar>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              style={{ fontSize: "12px", textAlign: "center", marginTop: "10px" }}
            >
              {Object.keys(userDetails).length > 0 ? userDetails.sgid : ""}
            </Typography>
            <Typography
              variant="subtitle1"
              component="div"
              style={{ fontSize: "22px", textAlign: "center", fontWeight: 700 }}
            >
              {Object.keys(userDetails).length > 0 ? userDetails.first_name + " " + userDetails.last_name : ""} <br />
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" style={{ fontSize: "12px", textAlign: "center" }}>
              Application Engineer
            </Typography>


            <Typography variant="subtitle1" color="text.secondary" style={{ fontSize: "12px", marginTop: "20px", borderTop: "1px solid #ccc", paddingTop: "10px" }}>
              Zone
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              style={{ fontWeight: 700 }}
            >
              {Object.keys(userDetails).length > 0 ? userDetails.role ? userDetails.name : "--" : ""}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" style={{ fontSize: "12px" }}>
              E-mail ID
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              style={{ fontWeight: 700 }}
            >
              {Object.keys(userDetails).length > 0 ? userDetails.email_id : ""}
              {/* {userDetails.email_id + "@saint-gobain.com"} */}
            </Typography>
          </CardContent>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default LeftContainer;
