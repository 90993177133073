import React from "react";
import { Typography, FormControl } from "@mui/material";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
const HourMinInput = (props) => {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Typography style={{ fontSize: "12px", lineHeight: 1.75, fontWeight: 600, marginBottom: "5px" }}>{props.label}</Typography>
        <FormControl style={{ width: "100%" }}>
          <TimePicker
            ampm={false}
            name={props.name}
            value={props.value}
            error={props.error}
            onChange={props.onChange}
            maxDate={props.maxLimit ? props.maxLimit : null}
            renderInput={(params) => <TextField size="small" {...params} error={props.error} />}
          />
        </FormControl>
      </LocalizationProvider>
    </div>
  );
};

export default HourMinInput;
