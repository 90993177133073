import React, { useEffect, useState, useRef } from "react";
import Chart from "react-apexcharts";
import { Box, Typography, Grid, Divider, Button } from "@mui/material";
import axios from "axios";
import { styled } from "@mui/system";
import configParam from "../../Config";
import SelectItem from "../../input/SelectItem";
import { useReactToPrint } from "react-to-print";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"; // Adjust the path if necessary
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Logo from "../../asset/Logo.png";

const Root = styled("div")({
  "@media print": {
    "& .noPrint": {
      display: "none !important",
    },
    "& .printChart": {
      width: "100% !important",
      display: "block !important",
    },
    "& .printView": {
      width: "100% !important",
    },
  },
});
const containerStyle = {
  textAlign: "center",
  padding: "0 8px",
};

const trialHeadingStyle = {
  fontWeight: 700,
  fontSize: "14px",
  color: "#000000",
  padding: "0 8px",
};

const textStyle = {
  color: "#616161",
  fontSize: "14px",
  fontFamily: "DM Sans, sans-serif",
};

const textValueStyle = {
  fontWeight: 800,
  fontSize: "18px",
  fontFamily: "DM Sans, sans-serif",
};
const totalTextValueStyle = {
  fontWeight: 800,
  fontSize: "18px",
  fontFamily: "DM Sans, sans-serif",
  color: "#0086DC",
};

const gridItemStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  borderRight: "1px solid #cfcfcf",
};

const dividerStyle = {
  marginTop: "10px",
  marginBottom: "15px",
};

const ZoneAnalytics = () => {
  const ApiURL = configParam.API_URL;
  const [year, setYear] = useState("2024");
  // Data for the year options
  const data = [
    { id: "2022", name: "2022" },
    { id: "2023", name: "2023" },
    { id: "2024", name: "2024" },
  ];
  const [zone, setZone] = useState([]);
  const [zoneId, setZoneId] = useState(1);
  const [showPdf, setShowPdf] = useState(false);

  const [Q1RMCData, setQ1RMCData] = useState([]);
  const [Q2RMCData, setQ2RMCData] = useState([]);
  const [Q3RMCData, setQ3RMCData] = useState([]);
  const [Q4RMCData, setQ4RMCData] = useState([]);

  const [Q1JobSiteData, setQ1JobSiteData] = useState([]);
  const [Q2JobSiteData, setQ2JobSiteData] = useState([]);
  const [Q3JobSiteData, setQ3JobSiteData] = useState([]);
  const [Q4JobSiteData, setQ4JobSiteData] = useState([]);

  // State for RMC success counts
  const [Q1RMCSuccessCount, setQ1RMCSuccessCount] = useState(0);
  const [Q2RMCSuccessCount, setQ2RMCSuccessCount] = useState(0);
  const [Q3RMCSuccessCount, setQ3RMCSuccessCount] = useState(0);
  const [Q4RMCSuccessCount, setQ4RMCSuccessCount] = useState(0);

  // State for JobSite success counts
  const [Q1JobSiteSuccessCount, setQ1JobSiteSuccessCount] = useState(0);
  const [Q2JobSiteSuccessCount, setQ2JobSiteSuccessCount] = useState(0);
  const [Q3JobSiteSuccessCount, setQ3JobSiteSuccessCount] = useState(0);
  const [Q4JobSiteSuccessCount, setQ4JobSiteSuccessCount] = useState(0);

  const zoneIdRef = useRef();

  useEffect(() => {
    let Zoneurl = ApiURL + "zone_list";
    axios.get(Zoneurl).then((res) => {
      if (res.data.length > 0) {
        // Filter out the 'NA' item from the response data
        const filteredData = res.data.filter((item) => item.name !== "NA");
        setZone(filteredData);
      } else {
        setZone([]);
      }
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiURL}get_zone_quarter_data/`, {
          params: {
            year: year ? `${year}` : "",
            zoneId: zoneId,
          },
        });

        if (response.data.length > 0) {
          // Filter data by quarter
          const q1Data = response.data.filter((item) =>
            item.quarter.includes("Q1")
          );
          const q2Data = response.data.filter((item) =>
            item.quarter.includes("Q2")
          );
          const q3Data = response.data.filter((item) =>
            item.quarter.includes("Q3")
          );
          const q4Data = response.data.filter((item) =>
            item.quarter.includes("Q4")
          );

          // Function to calculate RMC and Jobsite data and success count
          const getFilteredDataAndSuccessCount = (
            data,
            projectTypeCondition
          ) => {
            const filteredData = data.filter((item) =>
              projectTypeCondition(item)
            );
            const successCount = filteredData.reduce((total, item) => {
              return total + (item.success_count > 0 ? 1 : 0);
            }, 0);
            return { filteredData, successCount };
          };

          // Quarter-wise calculations for RMC and Jobsite
          const projectTypeConditions = {
            rmc: (item) =>
              item.project_type.includes("RMC") &&
              !item.project_type.includes("Project RMC"),
            jobsite: (item) =>
              !item.project_type.includes("RMC") ||
              item.project_type.includes("Project RMC"),
          };

          // Q1 Data
          const { filteredData: q1RMCData, successCount: q1RMCSuccessCount } =
            getFilteredDataAndSuccessCount(q1Data, projectTypeConditions.rmc);
          setQ1RMCData(q1RMCData);
          setQ1RMCSuccessCount(q1RMCSuccessCount);

          const {
            filteredData: q1JobSiteData,
            successCount: q1JobSiteSuccessCount,
          } = getFilteredDataAndSuccessCount(
            q1Data,
            projectTypeConditions.jobsite
          );
          setQ1JobSiteData(q1JobSiteData);
          setQ1JobSiteSuccessCount(q1JobSiteSuccessCount);

          // Q2 Data
          const { filteredData: q2RMCData, successCount: q2RMCSuccessCount } =
            getFilteredDataAndSuccessCount(q2Data, projectTypeConditions.rmc);
          setQ2RMCData(q2RMCData);
          setQ2RMCSuccessCount(q2RMCSuccessCount);

          const {
            filteredData: q2JobSiteData,
            successCount: q2JobSiteSuccessCount,
          } = getFilteredDataAndSuccessCount(
            q2Data,
            projectTypeConditions.jobsite
          );
          setQ2JobSiteData(q2JobSiteData);
          setQ2JobSiteSuccessCount(q2JobSiteSuccessCount);

          // Q3 Data
          const { filteredData: q3RMCData, successCount: q3RMCSuccessCount } =
            getFilteredDataAndSuccessCount(q3Data, projectTypeConditions.rmc);
          setQ3RMCData(q3RMCData);
          setQ3RMCSuccessCount(q3RMCSuccessCount);

          const {
            filteredData: q3JobSiteData,
            successCount: q3JobSiteSuccessCount,
          } = getFilteredDataAndSuccessCount(
            q3Data,
            projectTypeConditions.jobsite
          );
          setQ3JobSiteData(q3JobSiteData);
          setQ3JobSiteSuccessCount(q3JobSiteSuccessCount);

          // Q4 Data
          const { filteredData: q4RMCData, successCount: q4RMCSuccessCount } =
            getFilteredDataAndSuccessCount(q4Data, projectTypeConditions.rmc);
          setQ4RMCData(q4RMCData);
          setQ4RMCSuccessCount(q4RMCSuccessCount);

          const {
            filteredData: q4JobSiteData,
            successCount: q4JobSiteSuccessCount,
          } = getFilteredDataAndSuccessCount(
            q4Data,
            projectTypeConditions.jobsite
          );
          setQ4JobSiteData(q4JobSiteData);
          setQ4JobSiteSuccessCount(q4JobSiteSuccessCount);
        }
      } catch (error) {
        resetGraphData();
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [year, zoneId]);

  const resetGraphData = () => {
    setQ1RMCData(0);
    setQ2RMCData(0);
    setQ3RMCData(0);
    setQ4RMCData(0);
    setQ1JobSiteData(0);
    setQ2JobSiteData(0);
    setQ3JobSiteData(0);
    setQ4JobSiteData(0);
    setQ1RMCSuccessCount(0);
    setQ2RMCSuccessCount(0);
    setQ3RMCSuccessCount(0);
    setQ4RMCSuccessCount(0);
    setQ1JobSiteSuccessCount(0);
    setQ2JobSiteSuccessCount(0);
    setQ3JobSiteSuccessCount(0);
    setQ4JobSiteSuccessCount(0);
  };

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => setShowPdf(false),
  });

  const contentToPrint = useRef(null);

  const getTotal = (val1, val2) => {
    let total = 0;
    let num1 = Number(val1);
    let num2 = Number(val2);
    if (num1 && num2) {
      total = num1 + num2;
    } else if (num1 && !num2) {
      total = num1;
    } else if (!num1 && num2) {
      total = num2;
    }

    return total;
  };

  const chartData = [
    {
      name: "No.of Trials - Q1",
      RMX: Q1RMCData.length,
      JobSite: Q1JobSiteData.length,
      Total: getTotal(Q1RMCData.length, Q1JobSiteData.length),
    },
    {
      name: "No.of Successful Trials - Q1",
      RMX: Q1RMCSuccessCount,
      JobSite: Q1JobSiteSuccessCount,
      Total: getTotal(Q1RMCSuccessCount, Q1JobSiteSuccessCount),
    },
    {
      name: "No.of Trials - Q2",
      RMX: Q2RMCData.length,
      JobSite: Q2JobSiteData.length,
      Total: getTotal(Q2RMCData.length, Q2JobSiteData.length),
    },
    {
      name: "No.of Successful Trials - Q2",
      RMX: Q2RMCSuccessCount,
      JobSite: Q2JobSiteSuccessCount,
      Total: getTotal(Q2RMCSuccessCount, Q2JobSiteSuccessCount),
    },
    {
      name: "No.of Trials - Q3",
      RMX: Q3RMCData.length,
      JobSite: Q3JobSiteData.length,
      Total: getTotal(Q3RMCData.length, Q3JobSiteData.length),
    },
    {
      name: "No.of Successful Trials - Q3",
      RMX: Q3RMCSuccessCount,
      JobSite: Q3JobSiteSuccessCount,
      Total: getTotal(Q3RMCSuccessCount, Q3JobSiteSuccessCount),
    },
    {
      name: "No.of Trials - Q4",
      RMX: Q4RMCData.length,
      JobSite: Q4JobSiteData.length,
      Total: getTotal(Q4RMCData.length, Q4JobSiteData.length),
    },
    {
      name: "No.of Successful Trials - Q4",
      RMX: Q4RMCSuccessCount,
      JobSite: Q4JobSiteSuccessCount,
      Total: getTotal(Q4RMCSuccessCount, Q4JobSiteSuccessCount),
    },
  ];
  const onChangeValue = (event) => {
    setZoneId(event.target.value);
  };

  const handleYearChange = (e) => {
    setYear(e.target.value);
  };

  // For RMX & JobSite
  const getQuarterPercentage = (val1, val2) => {
    let result = 0;
    let num1 = Number(val1);
    let num2 = Number(val2);
    if (num1 & num2) {
      result = (num1 / num2) * 100;
    }
    return result.toFixed(0);
  };

  const getTotalPercentage = (val1, val2, val3, val4) => {
    let result = 0;
    let num1 = Number(val1);
    let num2 = Number(val2);
    let num3 = Number(val3);
    let num4 = Number(val4);
    if (num1 & num2 & num3 & num4) {
      result = ((num1 + num2) / (num3 + num4)) * 100;
    }
    return result.toFixed(0);
  };

  const getZoneName = (id) => {
    const selectedZone = zone.find((z) => z.id === id);
    return selectedZone ? selectedZone.name.toUpperCase() : "EAST";
  };

  const QuarterGrid = ({ title, RMXCount, JobSiteCount, Total, prevTotal }) => {
    const difference =
      prevTotal !== null ? (Total - prevTotal).toFixed(0) : null;

    const differenceStyle = {
      color: difference > 0 ? "green" : difference < 0 ? "red" : "gray",
      marginLeft: "10px",
    };
    // Determine the symbol for increase or decrease
    const symbol = difference > 0 ? "↑T" : difference < 0 ? "↓T" : "";

    return (
      <Grid
        item
        xs={3}
        style={
          title.includes("4")
            ? {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }
            : gridItemStyle
        }
      >
        <Grid container direction="column" style={{ height: "100%" }}>
          <Grid item>
            <Typography style={trialHeadingStyle}>{title}</Typography>
          </Grid>
          <Grid item style={{ marginTop: "10px" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <Grid item style={containerStyle}>
                <Typography style={textStyle}>RMX</Typography>
                <Typography style={textValueStyle}>
                  {RMXCount + "%" || 0 + "%"}
                </Typography>
              </Grid>
              <Grid item style={containerStyle}>
                <Typography style={textStyle}>JOB SITE</Typography>
                <Typography style={textValueStyle}>
                  {JobSiteCount + "%" || 0 + "%"}
                </Typography>
              </Grid>
              <Grid item style={containerStyle}>
                <Typography style={textStyle}>TOTAL</Typography>
                <Typography style={totalTextValueStyle}>
                  {Total + "%" || "0%"}
                  {difference !== null && (
                    <span style={differenceStyle}>
                      {symbol} {Math.abs(difference)}%
                    </span>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const CustomXAxisTick = ({ x, y, payload }) => {
    const label = payload.value; // Get the label
    const words = label.split(" "); // Split the label into words
    const lines = []; // Initialize lines array
    let currentLine = ""; // Current line being constructed

    // Construct lines based on spaces
    for (const word of words) {
      const testLine = currentLine ? `${currentLine} ${word}` : word; // Add space for subsequent words
      // If the length exceeds a certain limit, push the current line to lines and reset
      if (testLine.length > 20) {
        // You can adjust this limit based on your font size and requirements
        lines.push(currentLine);
        currentLine = word; // Start a new line
      } else {
        currentLine = testLine; // Update current line
      }
    }
    // Push any remaining text as the last line
    if (currentLine) {
      lines.push(currentLine);
    }

    return (
      <g transform={`translate(${x},${y})`}>
        {lines.map((line, index) => (
          <text
            key={index}
            x={0}
            y={index * 15} // Increase space between lines if necessary
            textAnchor="middle"
            fill="#666"
            fontSize={12}
            angle={-30} // Rotate the label for better visibility
            dy={10} // Adjust vertical positioning if necessary
          >
            {line}
          </text>
        ))}
      </g>
    );
  };

  return (
    <Root>
      <div style={{ padding: "0px 10px 0px 10px" }} ref={printRef}>
        <Box
          sx={{
            display: "none", // Hide by default
            "@media print": {
              display: "block", // Show when printing
            },
            borderBottom: "1px solid black",
          }}
        >
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={2}>
              <img src={Logo} alt="Logo" style={{ width: 115, padding: 10 }} />
            </Grid>
            <Grid
              item
              xs={8}
              style={{
                display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <Typography
                variant="h1"
                style={{
                  textTransform: "uppercase",
                  textAlign: "center",
                  fontWeight: "bold",
                  maxWidth: "700px",
                  fontSize: "28px",
                  paddingBottom: "10px",
                  marginTop: 0,
                }}
              >
                Quarter Wise Trial Performance Analytics
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between", // Space between left and right sections
            alignItems: "center", // Vertically align items
          }}
        >
          {/* Left side: Zone text */}
          <Typography
            style={{
              textAlign: "left", // Align text to the left
              color: "#272D36",
              fontWeight: 800,
              fontFamily: "DM Sans, sans-serif",
              fontSize: "18px",
            }}
          >
            {getZoneName(zoneId)} ZONE - {year}
          </Typography>

          {/* Right side: Selects and Button */}
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            {/* Select Year */}
            <SelectItem
              label="Select Year"
              name="year"
              value={year}
              onChange={handleYearChange}
              data={data} // Year options
              datakey="id"
              dataValue="name"
            />

            {/* Select Zone */}
            <SelectItem
              label="Select Zone"
              name="zoneId"
              value={zoneId}
              inputRef={zoneIdRef}
              datakey="id"
              dataValue="name"
              onChange={onChangeValue}
              data={zone}
            />

            {/* Save PDF Button */}
            <Button
              variant="contained"
              style={{
                marginBottom: "10px",
                backgroundColor: "#FFC400",
                color: "#1A1B1F",
                fontFamily: "DM Sans, sans-serif",
                marginTop: "30px",
              }}
              className="hide-when-printing"
              onClick={() => {
                handlePrint(null, () => contentToPrint.current);
              }}
            >
              Save PDF
            </Button>
          </div>
        </div>

        <Divider style={dividerStyle} />
        <Typography
          style={{
            color: "#1B1F26B8",
            fontWeight: 800,
            fontFamily: "DM Sans, sans-serif",
            fontSize: "18px",
          }}
        >
          Percentage(%) of Successful Trials
        </Typography>

        <Grid
          container
          spacing={2}
          style={{
            marginTop: "10px",
            background: "#EFEFEF", // Example background color
            borderRadius: "8px", // Adjust the radius as needed
            padding: "16px",
          }}
        >
          {/* Quarter-wise Grid Items */}
          <QuarterGrid
            title="Quarter 1"
            RMXCount={getQuarterPercentage(Q1RMCSuccessCount, Q1RMCData.length)}
            JobSiteCount={getQuarterPercentage(
              Q1JobSiteSuccessCount,
              Q1JobSiteData.length
            )}
            Total={getTotalPercentage(
              Q1RMCSuccessCount,
              Q1JobSiteSuccessCount,
              Q1RMCData.length,
              Q1JobSiteData.length
            )}
            prevTotal={null} // No previous value for Quarter 1
          />
          <QuarterGrid
            title="Quarter 2"
            RMXCount={getQuarterPercentage(Q2RMCSuccessCount, Q2RMCData.length)}
            JobSiteCount={getQuarterPercentage(
              Q2JobSiteSuccessCount,
              Q2JobSiteData.length
            )}
            Total={getTotalPercentage(
              Q2RMCSuccessCount,
              Q2JobSiteSuccessCount,
              Q2RMCData.length,
              Q2JobSiteData.length
            )}
            prevTotal={getTotalPercentage(
              Q1RMCSuccessCount,
              Q1JobSiteSuccessCount,
              Q1RMCData.length,
              Q1JobSiteData.length
            )} // Passing previous quarter's total
          />
          <QuarterGrid
            title="Quarter 3"
            RMXCount={getQuarterPercentage(Q3RMCSuccessCount, Q3RMCData.length)}
            JobSiteCount={getQuarterPercentage(
              Q3JobSiteSuccessCount,
              Q3JobSiteData.length
            )}
            Total={getTotalPercentage(
              Q3RMCSuccessCount,
              Q3JobSiteSuccessCount,
              Q3RMCData.length,
              Q3JobSiteData.length
            )}
            prevTotal={getTotalPercentage(
              Q2RMCSuccessCount,
              Q2JobSiteSuccessCount,
              Q2RMCData.length,
              Q2JobSiteData.length
            )} // Passing previous quarter's total
          />
          <QuarterGrid
            title="Quarter 4"
            RMXCount={getQuarterPercentage(Q4RMCSuccessCount, Q4RMCData.length)}
            JobSiteCount={getQuarterPercentage(
              Q4JobSiteSuccessCount,
              Q4JobSiteData.length
            )}
            Total={getTotalPercentage(
              Q4RMCSuccessCount,
              Q4JobSiteSuccessCount,
              Q4RMCData.length,
              Q4JobSiteData.length
            )}
            prevTotal={getTotalPercentage(
              Q3RMCSuccessCount,
              Q3JobSiteSuccessCount,
              Q3RMCData.length,
              Q3JobSiteData.length
            )} // Passing previous quarter's total
          />
        </Grid>

        <Divider style={dividerStyle} />
        <ResponsiveContainer width="100%" height={490}>
          <BarChart
            data={chartData}
            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              tick={<CustomXAxisTick />}
              interval={0}
              dy={30} // Adjust this value to move the X-axis title down
              fontSize={12} // Optional: Adjust the font size for clarity
            />
            <YAxis
              label={{
                value: "Trial Counts",
                angle: -90,
                position: "insideLeft",
                style: {
                  // Additional styles for the label
                  fontSize: "16px",
                  fontWeight: "bold",
                },
              }}
            />
            <Tooltip />
            <Legend
              align="right"
              verticalAlign="top"
              iconType="circle"
              iconSize={12}
              wrapperStyle={{ paddingBottom: "20px" }}
            />
            <Bar
              dataKey="RMX"
              stackId="a"
              fill="#8884d8"
              animationDuration={200}
              animationEasing="ease-in-out"
            >
              <LabelList position="center" fill="#ffffff" />
            </Bar>
            <Bar
              dataKey="JobSite"
              stackId="b"
              fill="#82ca9d"
              animationDuration={200}
              animationEasing="ease-in-out"
            >
              <LabelList position="center" fill="#ffffff" />
            </Bar>
            <Bar
              dataKey="Total"
              stackId="c"
              fill="#129CF3"
              animationDuration={200}
              animationEasing="ease-in-out"
            >
              <LabelList position="center" fill="#ffffff" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Root>
  );
};

export default ZoneAnalytics;
