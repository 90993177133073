import React from "react";
import PropTypes from "prop-types";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TrialDetails from "../PageVisit/TrialDetails";
import ClientDetails from "../PageVisit/ClientDetails";
import ConcreteMixDetails from "../PageVisit/ConcreteMixDetails";
import SupportInformation from "../PageVisit/SupportInformation";
import AdmixtureComposition from "../PageVisit/AdmixtureComposition";
import TrialResult from "../PageVisit/TrialResult";
import Comments from "../PageVisit/Comments";
import { useRecoilState } from "recoil";
import AppBar from "@mui/material/AppBar";
import moment from 'moment';
import {
  trial_details,
  errorHandle,
  errorHandleObj,
  error,
  client_details,
  concrete_mixdetails,
  supporting_info,
  Admixture_Composition,
  trial_results,
  currentSteper,
  optionalerror,
  isReadOnly
} from "../recoil/atom";
import { styled } from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";

const MyComponent = styled(Tabs)({
  "& .MuiButtonBase-root": {
    fontSize: "0.45em !important",
    fontWeight: "bold",
    overFlow: "hidden",
  },
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const navigate = useNavigate();
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}

export default function BasicTabs() {
  const [value, setValue] = useRecoilState(currentSteper);
  const [trDetails] = useRecoilState(trial_details);
  const [clientDet] = useRecoilState(client_details);
  const [congreteDet] = useRecoilState(concrete_mixdetails);
  const [supInfo] = useRecoilState(supporting_info);
  const [aCDet] = useRecoilState(Admixture_Composition);
  const [trRes] = useRecoilState(trial_results);
  const [stepper, setStepper] = useRecoilState(errorHandle);
  const [validError, setValidError] = useRecoilState(errorHandleObj);
  const matches = useMediaQuery('(max-width:600px)');
  const [stepperError, setStepperError] = useRecoilState(error);
  const [optionalError,] = useRecoilState(optionalerror);
  const [readOnly,] = useRecoilState(isReadOnly);
  const navigate = useNavigate();

  let clientDetOptionValue = ["workability_flow_slump", "target_strength_28_days"]
  let concreteOptionValue = ["cement_content"]
  let supOptionValue = ["w_cm", "sand_passing600", "ambient_temperature", "ambient_humidity"]


  const handleChange = (event, newValue) => {
    let validation;
    let mandatoryFields = [];
    // if (readOnly) {
    //   console.log("readOnly",readOnly)
    //   setValue(newValue);
    //   setStepperError(false);
    // }
    switch (newValue) {
      case 1:
        mandatoryFields = ["tech_person_1", "laboratory_type"];
        validation = checkAllValues(trDetails, mandatoryFields);

        break;
      case 2:
        mandatoryFields = [
          "project_type",
          "concrete_type",
          "customer_name",
          "enquiry_number",
          "project_details",
          "project_location",
          "concrete_grade",
          "workability_flow_slump",
          "target_strength_28_days",
          "retention",
        ];
        validation = checkAllValues(clientDet, mandatoryFields);
        if (!validation) {
          validation = optionErrorSet(optionalError["client_details"], clientDetOptionValue);
        }
        break;
      case 3:
        mandatoryFields = [
          "cement_brand",
          "cement_content",
          "free_water",
          "fine_aggregate_natural_sand_content",
          "fine_aggregate_crushed_sand_content",
          "coarse_aggregate_ca10mm_content",
          "coarse_aggregate_ca20mm_content",
          "other_aggregate_content",
        ];
        validation = checkAllValues(congreteDet, mandatoryFields);
        if (!validation) {
          validation = optionErrorSet(optionalError["client_details"], concreteOptionValue);
        }
        break;
      case 4:
        mandatoryFields = [
          "w_cm",
          "sand_passing600",
          "sand_category",
          "ambient_temperature",
          "ambient_humidity",
        ];
        validation = checkAllValues(supInfo, mandatoryFields);
        if (!validation) {
          validation = optionErrorSet(optionalError["client_details"], supOptionValue);
        }
        break;
      case 5:
        mandatoryFields = ["srno_Pname"];
        validation = checkAllValues(aCDet, mandatoryFields);
        break;
      case 6:
        mandatoryFields = ["dosage", "retention", "workability"];
        validation = checkAllValues(trRes, mandatoryFields);
        break;
      default:
        break
    }
    if (validation) {
      setStepper(newValue);
      setStepperError(true);
      setValidError()

    } else {
      setValue(newValue);
      setStepperError(false);
    }
  };
  const optionErrorSet = (data, value) => {
    let validation = false;
    for (let i = 0; i < value.length; i++) {
      if (data[value[i]]) {
        return true;
      }

    }
    return validation;
  }
  const checkAllValues = (value, mandatoryFields) => {
    let validation = false;
    for (let i = 0; i < mandatoryFields.length; i++) {
      if (value[mandatoryFields[i]] === null) {
        return true;
      }
      if (
        mandatoryFields[i] === "fine_aggregate_natural_sand_content" ||
        mandatoryFields[i] === "fine_aggregate_crushed_sand_content" ||
        mandatoryFields[i] === "coarse_aggregate_ca10mm_content" ||
        mandatoryFields[i] === "coarse_aggregate_ca20mm_content" ||
        mandatoryFields[i] === "other_aggregate_content"
      ) {
        if (typeof value.aggregates[mandatoryFields[i]] === "string" && !value.aggregates[mandatoryFields[i]] && value.aggregates[mandatoryFields[i]].length === 0 || typeof value.aggregates[mandatoryFields[i]] === "object" && Object.keys(value.aggregates[mandatoryFields[i]]).length === 0) {
          return true;
        }
      } else {

        if (typeof value[mandatoryFields[i]] === "string" && !value[mandatoryFields[i]] && value[mandatoryFields[i]].length === 0 || typeof value[mandatoryFields[i]] === "object" && Object.keys(value[mandatoryFields[i]]).length === 0 || mandatoryFields[i] === "retention" && moment(value[mandatoryFields[i]].$d).format('HH:mm') === "00:00") {
          return true;

        }
      }
    }

    return validation;
  };

  return (
    <React.Fragment>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '10px' }}>
        <Link
          onClick={() => navigate("/home")}
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            color: "#000000",
            marginLeft: "5px",
          }}
          color="inherit"
        >
          Home
        </Link>
        <Typography style={{ marginLeft: "25px" }}>Trial</Typography>
      </Breadcrumbs>
      <Box sx={{ width: "100%", fontSize: 14, marginTop: "15px" }}>

        <AppBar position="static" color="default" style={{
          backgroundColor: "#FFFFFF",
          boxShadow: "none"
        }}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons
            aria-label="visible arrows tabs example"

            xs={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 },
              },
            }}
          >
            <Tab label="Trial Details" {...a11yProps(0)} />
            <Tab label="Client Details & Parameters" {...a11yProps(1)} />
            <Tab label="Concrete Mix Design Details" {...a11yProps(2)} />
            <Tab
              label="Supporting Information ( Mix Design )"
              {...a11yProps(3)}
            />
            <Tab label="Admixture Composition ( In gm / Kg of Product)" {...a11yProps(4)} />
            <Tab label="Trial Results Obtained" {...a11yProps(5)} />
            <Tab label="Comments" {...a11yProps(6)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <TrialDetails />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ClientDetails />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ConcreteMixDetails />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <SupportInformation />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <AdmixtureComposition TabVal={value} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <TrialResult />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <Comments />
        </TabPanel>
      </Box>
    </React.Fragment>
  );
}
