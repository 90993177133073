import { atom } from "recoil";

export const apiTimeout = atom({
  key: "apiTimeout",
  default: false,
});
export const visitType = atom({
  key: "visitType",
  default: "1",
});
export const filterFromDate = atom({
  key: "filterFromDate",
  default: null,
});
export const filterToDate = atom({
  key: "filterToDate",
  default: null,
});

export const advancefilterTotal = atom({
  key: "advancefilterTotal",
  default: "0",
});
export const isFilter = atom({
  key: "isFilter",
  default: false,
});
export const isExternal = atom({
  key: "isExternal",
  default: false,
});
//Trial Details
export const trial_details = atom({
  key: "trial_details",
  default: {
    trial_number: "",
    trial_date: "",
    tech_person_1: "",
    tech_person_2: "",
    laboratory_type: "",
  },
});

//Client Details
export const client_details = atom({
  key: "client_details",
  default: {
    project_type: "",
    concrete_type: "",
    customer_name: "",
    enquiry_number: "",
    project_details: "",
    project_location: "",
    concrete_grade: "",
    retention: "",
    workability_flow_slump: "",
    air_content: "",
    early_strength_time: "",
    early_strength_mpa: "",
    target_strength_28_days: "",
  },
});

// Admixture Composition
export const Admixture_Composition = atom({
  key: "Admixture_Composition",
  default: {
    p20: "",
    p15: "",
    p16: "",
    p18_hr150: "",
    p11: "",
    snf_n5: "",
    l1_ligno_50: "",
    ligno: "",
    gn1: "",
    cf: "",
    g1: "",
    ac2_ac3: "",
    ac1: "",
    ac5_ac6: "",
    df2: "",
    df1: "",
    df3: "",
    ar2: "",
    ar5: "",
    ar6: "",
    cr_1: "",
    b29_p23: "",
    p27_r650: "",
    a15_p24: "",
    pr1: "",
    gn2: "",
    n43: "",
    ar7: "",
    gly: "",
    ar3: "",
    ar4: "",
    ca: "",
    ac6: "",
    p25_a19: "",
    p26: "",
    ar1: "",
    vma1: "",
    vma2: "",
    other: "",
    srno_Pname: "",
  },
});

// aggregates
export const aggregates = () => {
  return {
    fine_aggregate_natural_sand_content: "",
    fine_aggregate_crushed_sand_content: "",
    coarse_aggregate_ca10mm_content: "",
    coarse_aggregate_ca20mm_content: "",
    other_aggregate_content: "",
  };
};

// CONCRETE MIX DESIGN DETAILS
export const concrete_mixdetails = atom({
  key: "concrete_mixdetails",
  default: {
    cement_brand: "",
    cement_content: "",
    flyash: "",
    ggbfs_content: "",
    microsilica_content: "",
    free_water: "",
    aggregates: aggregates(),
  },
});

// SUPPORTING INFORMATION ( Mix Design )
export const supporting_info = atom({
  key: "supporting_info",
  default: {
    w_cm: "",
    alkali_content: "",
    sand_passing600: "",
    sand_passing150: "",
    sand_category: "",
    clear_test10: "",
    clear_test50: "",
    coarse_aggregate_150micpass10: "",
    coarse_aggregate_150micpass20: "",
    ambient_temperature: "",
    ambient_humidity: "",
  },
});

export const errorHandle = atom({
  key: "errorHandle",
  default: 0,
});
export const checkboxList = atom({
  key: "checkboxList",
  default: [],
});
export const projectLocationList = atom({
  key: "projectLocationList",
  default: [],
});
export const rangeFilterValue = atom({
  key: "rangeFilterValue",
  default: {
    "Workability  Flow / Slump (mm)": {
      "min": 0,
      "max": 800,
    },
    "Air Content %": {
      "min": 0,
      "max": 10,
    },
    "Early Strength ( Time) ": {
      "min": 10,
      "max": 72,
    },
    "Early Strength (Mpa)": {
      "min": 10,
      "max": 60,
    },
    "Target Strength 28 days (Mpa)": {
      "min": 10,
      "max": 200,
    },
    "Cement Content ( Kg)": {
      "min": 0,
      "max": 700,
    },
    "Flyash Content (Kg)": {
      "min": 0,
      "max": 400,
    },
    "GGBFS Content (Kg)": {
      "min": 0,
      "max": 400,
    },
    "Microsilica  Content (Kg)": {
      "min": 0,
      "max": 100,
    },
    "W/CM (%)": {
      "min": 0.10,
      "max": 0.60,
    },
    "Alkali content in cement(%)": {
      "min": 0,
      "max": 5,
    },
    "Sand Passing % in 600 µ": {
      "min": 20,
      "max": 100,
    },
    "Sand Passing % in 150 µ": {
      "min": 0,
      "max": 100,
    },
    "Sand Category": {
      "min": 1,
      "max": 4,
    },
    "Clear Test 10": {
      "min": 0,
      "max": 30,
    },
    "Clear Test 50": {
      "min": 0,
      "max": 30,
    },
    "Coarse Aggregate 150 micron passing 20 mm": {
      "min": 0,
      "max": 20,
    },
    "Coarse Aggregate 150 micron passing 10 mm": {
      "min": 0,
      "max": 20,
    },
    "Ambient Temperature (°C)": {
      "min": '-10',
      "max": 60,
    },
    "Ambient Humidity (%)": {
      "min": 20,
      "max": 100,
    },
  },
});
export const StatusList = atom({
  key: "StatusList",
  default: [],
});

export const isReadOnly = atom({
  key: "isReadOnly",
  default: false,
});
export const trialNo = atom({
  key: "trialNo",
  default: "",
});
export const frmRetentionFilter = atom({
  key: "frmRetentionFilter",
  default: "",
});
export const toRetentionFilter = atom({
  key: "toRetentionFilter",
  default: "",
});
export const AdvanceSearchLoading = atom({
  key: "AdvanceSearchLoading",
  default: false,
});

export const filterTableLoader = atom({
  key: "filterTableLoader",
  default: false,
});
export const resetTable = atom({
  key: "resetTable",
  default: false,
});


export const AdvanceFilterCount = atom({
  key: "AdvanceFilterCount",
  default: 0,
});
export const currentSteper = atom({
  key: "currentSteper",
  default: 0,
});
export const isReplace = atom({
  key: "isReplace",
  default: 0,
});
export const TabValue = atom({
  key: "TabValue",
  default: "1",
});
export const checkBoxCount = atom({
  key: "checkBoxCount",
  default: {},
});
export const checkBoxFilterValue = atom({
  key: "checkBoxFilterValue",
  default: {},
});
export const projectType = atom({
  key: "projectType",
  default: [],
});
export const filterDataArray = atom({
  key: "filterDataArray",
  default: [],
});
export const allExcelDataArray = atom({
  key: "allExcelDataArray",
  default: [],
});

export const filterExcelData = atom({
  key: "filterExcelData",
  default: [],
});

export const filterPagination = atom({
  key: "filterPagination",
  default: {
    page: 0,
    pageSize: 10,
  }
});

export const typeofConcrete = atom({
  key: "typeofConcrete",
  default: [],
});
export const enquiryNumberList = atom({
  key: "enquiryNumberList",
  default: [],
});

export const customerList = atom({
  key: "customerList",
  default: [],
});
export const projectDetailList = atom({
  key: "projectDetailList",
  default: [],
});
export const congreteGrade = atom({
  key: "congreteGrade",
  default: [],
});
export const cementBrand = atom({
  key: "cementBrand",
  default: [],
});
export const techPerson = atom({
  key: "techPerson",
  default: [],
});
export const totlaTableCount = atom({
  key: "totlaTableCount",
  default: 0,
});
export const excelTotlaTableCount = atom({
  key: "excelTotlaTableCount",
  default: 0,
});
export const resetTotlaTableCount = atom({
  key: "resetTotlaTableCount",
  default: 0,
});

export const searchList = atom({
  key: "searchList",
  default: [],
});
export const status = atom({
  key: "status",
  default: [{ "status": "Success", "ischecked": false }, { "status": "Fail", "ischecked": false }],
});

export const inputSearchList = atom({
  key: "inputSearchList",
  default: "",
});
export const filterInputSearchList = atom({
  key: "filterInputSearchList",
  default: "",
});

export const zoneFilter = atom({
  key: "zone",
  default: [{ "zone": 1, "ischecked": false }, { "zone": 2, "ischecked": false }, { "zone": 3, "ischecked": false }, { "zone": 4, "ichecked": false }],
});
export const LocationCount = atom({
  key: "LocationCount",
  default: "0",
});
export const menuName = atom({
  key: "menuName",
  default: "Trial Visits",
});

export const errorHandleObj = atom({
  key: "errorHandleObj",
  default: {
    trialNo: { value: "", isChecked: false },
    techName1: { value: "", isChecked: false },
    trialDate: { value: "", isChecked: false },
    laboratoryType: { value: "", isChecked: false },

    // client_details
    projectType: { value: "", isChecked: false },
    concreteType: { value: "", isChecked: false },
    customerName: { value: "", isChecked: false },
    projectDet: { value: "", isChecked: false },
    enquiryNo: { value: "", isChecked: false },
    projectLocation: { value: "", isChecked: false },
    concreteGrade: { value: "", isChecked: false },
    retention1: { value: "", isChecked: false },
    workability1: { value: "", isChecked: false },
    strength: { value: "", isChecked: false },

    // Congrete_details
    cementBrand: { value: "", isChecked: false },
    cementContent: { value: "", isChecked: false },
    freeWater: { value: "", isChecked: false },
    aggregates: {
      fineAggreNatural: { value: "", isChecked: false },
      fineAggreCrush: { value: "", isChecked: false },
      coarseAggre10: { value: "", isChecked: false },
      coarseAggre20: { value: "", isChecked: false },
      otherAggre: { value: "", isChecked: false },
    },

    // supporting_info
    wcm: { value: "", isChecked: false },
    sandPass: { value: "", isChecked: false },
    sandCategory: { value: "", isChecked: false },
    ambientTem: { value: "", isChecked: false },
    ambienthuman: { value: "", isChecked: false },
    //  Admixture_Composition
    sampleRef: { value: "", isChecked: false },
    //  TrialResult Mandatory
    dosageDet: { value: "", isChecked: false },
    retentionDet: { value: "", isChecked: false },
    workabilityDet: { value: "", isChecked: false },

    // comments
    statusDet: { value: "", isChecked: false },
    reference: { value: "", isChecked: false },
  },
});

export const admixtorPopupState = atom({
  key: "admixtorPopupState",
  default: [
    { id: 1, value: "Exceeds the threshold value (Sum of (AR1+AR2+AR3+AR4+AR5+AR6+AR7) should be <1)", status: false },
    { id: 2, value: "Approval needed from Manager (AC1 is > 0)", status: false },
    { id: 3, value: "May have solubility issue /need to check sample stability (Sum of (P11+P15+P18+P20+P23+P24+P25+P26)is > 400 then the sum of(GN1+GN2)should be < 120)", status: false },
    { id: 4, value: "May have solubility issue /need to check sample stability (Sum of (P11+P15+P18+P20+P23+P24+P25+P26)is > 500 then the sum of(GN1+GN2)should be < 90)", status: false },
    { id: 5, value: "May have solubility issue /need to check sample stability (Sum of (P11+P15+P18+P20+P23+P24+P25+P26)is > 600 then the sum of(GN1+GN2)should be < 70)", status: false },
    { id: 6, value: "May have solubility issue /need to check sample stability (Sum of (P11+P15+P18+P20+P23+P24+P25+P26)is > 700 then the sum of(GN1+GN2+AC3)should be < 60)", status: false },
    { id: 7, value: "Defoamers missing  sum of (DF1+DF2+DF3)= 0  ", status: false },
    { id: 8, value: "May have viscosity issue /need to check sample stability  sum of (P11+P15+P18+P20+P23+P24+P25+P26)is > 1 , then the sum of(SNF/N5+N43)should be =0 ", status: false },
    { id: 9, value: "Formula error (sum of all the RMs  Exceeds 1000 )", status: false },
    { id: 10, value: "Exceeds the threshold value sum of (P26+P23)= X, then Sum of ( P18+P27) should be less than 3X", status: false },
    { id: 11, value: "May have solubility issue /need to check sample stability (Sum of (P11+P15+P18+P20+P23+P24+P25+P26)is > 800 then the sum of(GN1+GN2+AC3)should be < 20)", status: false },
    { id: 12, value:  "May have viscosity issue /need to check sample stability Sum of (SNF/N5+N43)is > 1 , then the sum of (P11+P15+P18+P20+P23+P24+P25+26)should be =0", status: false },
    { id: 13, value: "trial number", status: "false", trialNumber: ""}
  ]
})

export const alertTrialNumber = atom({
  key: "alertTrialNumber",
  default: ""
});


export const error = atom({
  key: "error",
  default: false
});
export const graphSeriesData = atom({
  key: "graphSeriesData",
  default: {}
});
export const optionalerror = atom({
  key: "optionalerror",
  default: {
    client_details: {
      air_content: false,
      early_strength_time: false,
      early_strength_mpa: false,
      flyash: false,
      ggbfs_content: false,
      microsilica_content: false,
      project_type: false,
      concrete_type: false,
      customer_name: false,
      project_details: false,
      enquiry_number: false,
      project_location: false,
      retention: false,
      workability_flow_slump: false,
      target_strength_28_days: false,
      alkali_content: false,
      sand_passing150: false,
      // sand_passing600:false,
      clear_test10: false,
      clear_test50: false,
      coarse_aggregate_150micpass20: false,
      coarse_aggregate_150micpass10: false,
      cement_content: false,
    },
  }
});

// Trial Result
export const trial_results = atom({
  key: "trial_results",
  default: {
    dosage: "",
    retention: "",
    workability: "",
    air_content: "",
    early_strength: "",
    strength_at_7_days: "",
    strength_at_28_days: "",
  },
});
// Comments
export const comments = atom({
  key: "comments",
  default: {
    cost_factor_comparison: "",
    remarks: "",
    ref_previous_trial_data: "",
    status: "",
    rejection_notes: "",
  },
});
export const user = atom({
  key: "user",
  default: {
    sgid: "",
    name: "",
    lastName: "",
    id: "",
    role: "",
    roleId: "",
    zone: "",
  },
});
export const themeMode = atom({
  key: "themeMode",
  default: localStorage.getItem("theme") || "light",
});
export const userType = atom({
  key: "userType",
  default: 3,
});
export const replaceExisting = atom({
  key: "replaceExisting",
  default: 1,
});
export const selectedVisitObj = atom({
  key: "selectedVisitObj",
  default: {},
});
export const selectedTrialDetails = atom({
  key: "selectedTrialDetails",
  default: [],
});
export const searchValue = atom({
  key: "searchValue",
  default: [],
});
export const portObj = () => {
  return {
    min: "0",
    max: "0"
  };
};
export const RangeFilterData = atom({
  // Cross Fired
  key: 'RangeFilter',
  default: {
    CementContent: portObj(),
    FlyashContent: portObj(),
    GGBFSContent: portObj(),
    MicrosilicaContent: portObj(),
    WCM: portObj(),
    AlkaliContent: portObj(),
    SandPassing600: portObj(),
    SandPassing150: portObj(),
    SandCategory: portObj(),
    ClearTest10: portObj(),
    ClearTest50: portObj(),
    Coarse_Aggregate_150_micron_passing_20_mm: portObj(),
    Coarse_Aggregate_150_micron_passing_10_mm: portObj(),
    Ambient_Temperature: portObj(),
    Ambient_Humidity: portObj(),
  }
});

export const medianArray = atom({
  key: "medianArray",
  default: [],
});
export const upperLimitArray = atom({
  key: "upperLimitArray",
  default: [],
});
export const lowerLimitArray = atom({
  key: "lowerLimitArray",
  default: [],
});

export const searchQueryCount = atom({
  key: "searchQueryCount",
  default: 0,
});
export const disabledTab = atom({
  key: "disabledTab",
  default: false,
});
