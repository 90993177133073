import React, { useEffect, useState } from "react";
import TextBox from "../input/TextBox";
import { Grid } from "@mui/material";
import {
  supporting_info,
  error,
  visitType,
  errorHandleObj,
  optionalerror
} from "../recoil/atom";
import { useRecoilState } from "recoil";
import configParam from "../Config";
import Selectautocomplete from "../input/Selectautocomplete";
import axios from "axios";

const SupportInformation = () => {
  const [visitTypes] = useRecoilState(visitType);
  const [supInfo, setSupInfo] = useRecoilState(supporting_info);
  const trialApprovelData = localStorage.getItem("TrialApprovelData");
  const [sandTypes, setSandTypes] = useState([]);
  const [stepperError, setStepperError] = useRecoilState(error);
  const [optionSteperror, setOptionSteperror] = useRecoilState(optionalerror);
  const trialData = localStorage.getItem("ChrysoVisitData");
  const ApiURL = configParam.API_URL;
  let data =
    visitTypes === "1"
      ? trialData
        ? JSON.parse(trialData)
        : {}
      : trialApprovelData
        ? JSON.parse(trialApprovelData)
        : {};
  const sgid = localStorage.getItem("UserID");
  const getItemNo = configParam.getVisitedItem;
  const getSavedVisitNo = configParam.getSavedTrial;

  useEffect(() => {
    //const rowIndex = getItemNo();
    getSandDetails();
  }, []);

  //LabType
  const getSandDetails = () => {
    let url = ApiURL + "get_sand_category"
    axios.get(url).then((res) => {
      if (res.data.length > 0) {
        setSandTypes(res.data)
      }
    })

    // configParam.RUN_GQL_API(gqlQueries.GET_SAND_CATEGORY, {}).then((data) => {
    //   setSandTypes(data.chryso_sand_category);
    // });
  };


  const updateTempObj = (key, value, min, max) => {
    let tmpObj = { ...supInfo };
    let temError = { ...optionSteperror }
    let obj = { ...temError.client_details }
    if (value < min || value > max) {
      obj[key] = true;
      temError["client_details"] = obj
      setOptionSteperror(temError);
    }
    else {
      setStepperError(false);
      obj[key] = false;
      temError["client_details"] = obj
      setOptionSteperror(temError);

    }
    tmpObj[key] = value;
    setSupInfo(tmpObj);
  };

  useEffect(() => {
    let rowIndex;
    if (visitTypes === "1") {
      rowIndex = getItemNo();
    } else {
      rowIndex = getSavedVisitNo();
    }
    if (data[sgid]) {
      let obj = { supInfo: supInfo };
      let merged = { ...data[sgid][rowIndex ? rowIndex : 0], ...obj };
      data[sgid][rowIndex] = merged;
    } else {
      data[sgid] = { 0: { supInfo: supInfo } };
    }
    if (visitTypes === "1") {
      localStorage.setItem("ChrysoVisitData", JSON.stringify(data));
    } else {
      localStorage.setItem("TrialApprovelData", JSON.stringify(data));
    }
  }, [supInfo]);

  const changesupportIn = (e, min, max) => {
    updateTempObj(e.target.name, e.target.value, min, max);
  };

  return (
    <Grid container rowSpacing={1} style={{ marginTop: 25 }}>
      <Grid container rowSpacing={1} spacing={{
        xs: 0,
        sm: 2,
        lg: 5,
        xl: 12
      }}>
        <Grid item xl={3} md={4} xs={12}>
          <TextBox
            label="W/CM *"
            name="w_cm"
            value={supInfo.w_cm ? supInfo.w_cm : ""}
            // style={{backgroundColor: supInfo.w_cm === "0" ? "lightgray" : "", width: "80%"}}
            inputProps={{ step: 0.01 }}
            onChange={(e) => changesupportIn(e, 0.10, 0.60)}
            helperText={
              stepperError && !supInfo.w_cm ? "Enter a value between 0.10 to 0.60" :
                (optionSteperror.client_details.w_cm ? "Enter a value between 0.10 to 0.60" : "")}

            error={
              stepperError && !supInfo.w_cm ? true :
                (optionSteperror.client_details.w_cm ? true : false)
            }

          />
        </Grid>
        <Grid item xl={3} md={4} xs={12}>
          <TextBox
            label="Alkali content in cement(%)"
            name="alkali_content"
            value={supInfo.alkali_content ? supInfo.alkali_content === "0" ? "" : supInfo.alkali_content : ""}
            style={{ backgroundColor: supInfo.alkali_content === "0" ? "lightgray" : "", width: "80%" }}
            onChange={(e) => changesupportIn(e, 0, 5)}
            helperText={optionSteperror.client_details.alkali_content ? "Enter a value between 0 to 5" : ""}
            error={
              optionSteperror.client_details.alkali_content ? true : false
            }

          />
        </Grid>
        <Grid item xl={3} md={4} xs={12}>
          <TextBox
            label="Sand Passing % in 600 µ *"
            name="sand_passing600"
            value={supInfo.sand_passing600 ? supInfo.sand_passing600 === "0" ? "" : supInfo.sand_passing600 : ""}
            style={{ backgroundColor: supInfo.sand_passing600 === "0" ? "lightgray" : "", width: "80%" }}
            onChange={(e) => changesupportIn(e, 20, 100)}
            helperText={
              stepperError && !supInfo.sand_passing600 ? "Enter a value between 20 to 100" :
                (optionSteperror.client_details.sand_passing600 ? "Enter a value between 20 to 100" : "")}

            error={
              stepperError && !supInfo.sand_passing600 ? true :
                (optionSteperror.client_details.sand_passing600 ? true : false)
            }


          />
        </Grid>
      </Grid>
      <Grid container rowSpacing={1} spacing={{
        xs: 0,
        sm: 2,
        lg: 5,
        xl: 12
      }} style={{ marginTop: 15 }}>
        <Grid item xl={3} md={4} xs={12}>
          <TextBox
            label="Sand Passing % in 150 µ "
            name="sand_passing150"
            value={supInfo.sand_passing150 ? supInfo.sand_passing150 === "0" ? "" : supInfo.sand_passing150 : ""}
            style={{ backgroundColor: supInfo.sand_passing150 === "0" ? "lightgray" : "", width: "80%" }}
            onChange={(e) => changesupportIn(e, 0, 100)}
            helperText={optionSteperror.client_details.sand_passing150 ? "Enter a value between 0 to 100" : ""}
            error={
              optionSteperror.client_details.sand_passing150 ? true : false
            }
          />
        </Grid>
        <Grid item xl={3} md={4} xs={12}>
          <Selectautocomplete
            label="Sand Category *"
            name="sand_category"
            datakey="id"
            dataValue="category"
            value={supInfo.sand_category ? supInfo.sand_category : ""}
            myOptions={sandTypes}
            onChange={(e, value) => {
              updateTempObj("sand_category", value);
            }}
            error={stepperError && !supInfo.sand_category ? true : false}

          />

        </Grid>
        <Grid item xl={3} md={4} xs={12}>
          <TextBox
            label="Clear Test 10"
            name="clear_test10"
            value={supInfo.clear_test10 ? supInfo.clear_test10 === "0" ? "" : supInfo.clear_test10 : ""}
            style={{ backgroundColor: supInfo.clear_test10 === "0" ? "lightgray" : "", width: "80%" }}
            onChange={(e) => changesupportIn(e, 0, 30)}
            helperText={optionSteperror.client_details.clear_test10 ? "Enter a value between 0 to 30" : ""}
            error={
              optionSteperror.client_details.clear_test10 ? true : false
            }
          />
        </Grid>
      </Grid>
      <Grid container rowSpacing={1} spacing={{
        xs: 0,
        sm: 2,
        lg: 5,
        xl: 12
      }} style={{ marginTop: 15 }}>
        <Grid item xl={3} md={4} xs={12}>
          <TextBox
            label="Clear Test 50"
            name="clear_test50"
            value={supInfo.clear_test50 ? supInfo.clear_test50 === "0" ? "" : supInfo.clear_test50 : ""}
            style={{ backgroundColor: supInfo.clear_test50 === "0" ? "lightgray" : "", width: "80%" }}
            onChange={(e) => changesupportIn(e, 0, 30)}
            helperText={optionSteperror.client_details.clear_test50 ? "Enter a value between 0 to 30" : ""}
            error={
              optionSteperror.client_details.clear_test50 ? true : false
            }
          />
        </Grid>
        <Grid item xl={3} md={4} xs={12}>
          <TextBox
            label="Coarse Aggregate 150 micron passing 20mm"
            name="coarse_aggregate_150micpass20"
            value={supInfo.coarse_aggregate_150micpass20 ? supInfo.coarse_aggregate_150micpass20 === "0" ? "" : supInfo.coarse_aggregate_150micpass20 : ""}
            style={{ backgroundColor: supInfo.coarse_aggregate_150micpass20 === "0" ? "lightgray" : "", width: "80%" }}
            onChange={(e) => changesupportIn(e, 0, 20)}
            helperText={optionSteperror.client_details.coarse_aggregate_150micpass20 ? "Enter a value between 0 to 20" : ""}
            error={
              optionSteperror.client_details.coarse_aggregate_150micpass20 ? true : false
            }
          />
        </Grid>
        <Grid item xl={3} md={4} xs={12}>
          <TextBox
            label="Coarse Aggregate 150 micron passing 10mm"
            name="coarse_aggregate_150micpass10"
            value={supInfo.coarse_aggregate_150micpass10 ? supInfo.coarse_aggregate_150micpass10 === "0" ? "" : supInfo.coarse_aggregate_150micpass10 : ""}
            style={{ backgroundColor: supInfo.coarse_aggregate_150micpass10 === "0" ? "lightgray" : "", width: "80%" }}
            onChange={(e) => changesupportIn(e, 0, 20)}
            helperText={optionSteperror.client_details.coarse_aggregate_150micpass10 ? "Enter a value between 0 to 20" : ""}
            error={
              optionSteperror.client_details.coarse_aggregate_150micpass10 ? true : false
            }
          />
        </Grid>
      </Grid>
      <Grid container rowSpacing={1} spacing={{
        xs: 0,
        sm: 2,
        lg: 5,
        xl: 12
      }} style={{ marginTop: 15 }}>
        <Grid item xl={3} md={4} xs={12}>
          <TextBox
            label="Ambient Temperature *"
            name="ambient_temperature"
            value={supInfo.ambient_temperature ? supInfo.ambient_temperature === "0" ? "" : supInfo.ambient_temperature : ""}
            style={{ backgroundColor: supInfo.ambient_temperature === "0" ? "lightgray" : "", width: "80%" }}
            onChange={(e) => changesupportIn(e, -10, 60)}
            helperText={
              stepperError && !supInfo.ambient_temperature ? "Enter a value between -10 to 60" :
                (optionSteperror.client_details.ambient_temperature ? "Enter a value between -10 to 60" : "")}

            error={
              stepperError && !supInfo.ambient_temperature ? true :
                (optionSteperror.client_details.ambient_temperature ? true : false)
            }
          />
        </Grid>
        <Grid item xl={3} md={4} xs={12}>
          <TextBox
            label="Ambient Humidity *"
            name="ambient_humidity"
            value={supInfo.ambient_humidity ? supInfo.ambient_humidity === "0" ? "" : supInfo.ambient_humidity : ""}
            style={{ backgroundColor: supInfo.ambient_humidity === "0" ? "lightgray" : "", width: "80%" }}
            onChange={(e) => changesupportIn(e, 20, 100)}
            helperText={
              stepperError && !supInfo.ambient_humidity ? "Enter a value between 20 to 100" :
                (optionSteperror.client_details.ambient_humidity ? "Enter a value between 20 to 100" : "")}

            error={
              stepperError && !supInfo.ambient_humidity ? true :
                (optionSteperror.client_details.ambient_humidity ? true : false)
            }
          />
        </Grid>
      </Grid>
    </Grid>

  );
};

export default SupportInformation;
