import React, { useState, useEffect } from "react";
import { Chip, Alert, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import { selectedVisitObj } from "../../src/recoil/atom";
import { ReactComponent as Edit } from "../asset/Edit.svg";
import { ReactComponent as Replace } from "../asset/replace_existing.svg";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import PreviewForm from "../PageVisit/PreviewForm";
import { useRecoilState, useRecoilValue } from "recoil";
import configParam from "../Config";
import Button from "@mui/material/Button";
import { useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DownloadIcon from "@mui/icons-material/Download";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { getConvertedJSON } from "../../src/recoil/Selector";
import Skeleton from "@mui/material/Skeleton";
import axios from "axios";
import {
  trial_details,
  client_details,
  concrete_mixdetails,
  supporting_info,
  comments,
  trial_results,
  Admixture_Composition,
  visitType,
  trialNo,
  currentSteper,
  isReplace,
  isReadOnly,
  isExternal
} from "../recoil/atom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import PendingIcon from "@mui/icons-material/Pending";
import CancelIcon from "@mui/icons-material/Cancel";
import AddTaskIcon from "@mui/icons-material/AddTask";
import Tooltip from "@mui/material/Tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import moment from "moment";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SearchInput from "../input/SearchInput";
import { debounce } from 'lodash';

const SavedVisit = () => {
  const [tablePage, setTablePage] = useState({
    page: 0,
    pageSize: 10,

  });
  const [totalcount, setTotalCount] = useState(0);
  const [filterCount, setFilterCount] = useState(0)
  const convertedJSON = useRecoilValue(getConvertedJSON);
  const [previewForm, setPreviewForm] = useState(false);
  const [err, setErr] = useState(false);
  const [msg, setMsg] = useState("");
  const getTrialVisitNo = configParam.TRIAL_VISIT_NO;
  const getVisitNo = configParam.VISIT_NO;
  const [visitResData, setVisitResData] = useState([]);
  const [selectVisitData, setSelectVisitData] =
    useRecoilState(selectedVisitObj);
  const [, setValue] = useRecoilState(currentSteper);
  // const currUser = useRecoilValue(user);
  const userRole = localStorage.getItem("user");
  let currUser = JSON.parse(userRole);

  const [, setTrDet] = useRecoilState(trial_details);
  const [, setReplace] = useRecoilState(isReplace);
  const [, setclientTrDet] = useRecoilState(client_details);
  const [, setcongreteDet] = useRecoilState(concrete_mixdetails);
  const [, setSupInfo] = useRecoilState(supporting_info);
  const [, setTrialRes] = useRecoilState(trial_results);
  const [, setAdmixture] = useRecoilState(Admixture_Composition);
  const [, setComments] = useRecoilState(comments);
  const [visitTypeValue, setVisitType] = useRecoilState(visitType);
  const [, setIsExternal] = useRecoilState(isExternal);
  const [, SetTrialId] = useRecoilState(trialNo);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const userDet = localStorage.getItem("user");
  let roleID = JSON.parse(userDet);

  const sgid = localStorage.getItem("UserID");
  const userinfo = localStorage.getItem("user");
  const userJson = JSON.parse(userinfo)
  const userID = Number(userJson.role_id);
  const [replaceType, setReplaceType] = useState("");
  const [, setReadonly] = useRecoilState(isReadOnly);
  const [, setSelectedVisitDatas] = useState(null);
  const [printData, setPrintData] = useState([]);
  const ApiURL = configParam.API_URL;
  const getSavedVisitNo = configParam.getSavedTrial;
  var printVisitData = [];
  const [searchTerm, setSearchTerm] = useState("");

  const debouncedHandleKeyPress = debounce((value) => {
    getSelectedVisitData(value);
  }, 300);

  const handleKeyPress = (event) => {
    setSearchTerm(event.target.value);
    if (event.key === 'Enter') {
      debouncedHandleKeyPress(event.target.value);
    }
  };
  const handleChange = e => {
    setSearchTerm(e.target.value);
    // setShouldSearch(false);
  };
  const clearInputField = () => {
    setSearchTerm("");
    getSelectedVisitData("empty");
  }

  const navigate = useNavigate();

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current
  });
  const printReport = (index, row) => {
    let url = ApiURL + "getTrialDetails/" + index.id;
    axios
      .get(url)
      .then((res) => {
        if (res.data.length > 0) {
          printVisitData = res.data;
          setPrintData(res.data);
          setPreviewForm(true);
          setSelectedVisitDatas(printVisitData);
          printCover(printVisitData);

          setTimeout(() => {
            handlePrint();
          }, 100);
          // handlePrint();
          // handlePrint(res.data);
        } else {
          setSelectVisitData([]);

        }
      });

  };
  useEffect(() => {
    if (Object.keys(selectVisitData).length > 0) {
      let rowIndex
      if (visitTypeValue === "1") {
        rowIndex = localStorage.getItem("VisitID");
      } else {
        rowIndex = getSavedVisitNo();
      }
      let tempConvertedJSON = convertedJSON;
      let data = {};
      data[sgid] = {
        [rowIndex]: {
          TrialDetails: tempConvertedJSON.TrialDetails,
          ClientDetails: tempConvertedJSON.ClientDetails,
          ConcreteDetails: tempConvertedJSON.ConcreteDetails,
          supInfo: tempConvertedJSON.supInfo,
          Admixture: tempConvertedJSON.Admixture,
          TrialResult: tempConvertedJSON.TrialResult,
          comments: tempConvertedJSON.comments,
        },
      };
      if (visitTypeValue === 1) {
        localStorage.setItem("ChrysoVisitData", JSON.stringify(data));
      } else {
        localStorage.setItem("SavedTrialDetail", JSON.stringify(data));
      }

      setTrDet(tempConvertedJSON.TrialDetails);
      setclientTrDet(tempConvertedJSON.ClientDetails);
      setcongreteDet(tempConvertedJSON.ConcreteDetails);
      setSupInfo(tempConvertedJSON.supInfo);
      setAdmixture(tempConvertedJSON.Admixture);
      setTrialRes(tempConvertedJSON.TrialResult);
      setComments(tempConvertedJSON.comments);

    }
  }, [selectVisitData]);

  const printCover = (data) => {
    handlePrint(data);
    setSelectedVisitDatas(data);
  };
  const [pageSize] = useState(1);
  const [, setTotal] = useState(0);
  const getStatus = (userRole, index) => {
    if (index["value"] === "Awaiting Approval") {
      return {
        icon: <HourglassTopIcon style={{ color: "red" }} />,
        label: index["value"],
        style: {
          borderColor: "red",
        },
      };
    } else if (index["value"] === "Rejected") {
      return {
        icon: <CancelIcon style={{ color: "red" }} />,
        label: index["value"],
        style: {
          borderColor: "red",
        },
      };
    } else if (index["value"] === "Approved") {
      return {
        icon: <CheckCircleIcon style={{ color: "green" }} />,
        label: index["value"],
        style: {
          borderColor: "green",
        },
      };
    } else if (index["value"] === "resubmitted") {
      return {
        icon: <AddTaskIcon style={{ color: "orange" }} />,
        label: "Re submitted",
        style: {
          borderColor: "orange",
        },
      };
    } else if (index["value"] === "pending") {
      return {
        icon: <PendingIcon style={{ color: "orange" }} />,
        label: "Pending",
        style: {
          borderColor: "orange",
        },
      };
    } else if (index["value"] === "ReSubmit") {
      return {
        icon: <AutorenewIcon style={{ color: "blue" }} />,
        label: index["value"],
        style: {
          borderColor: "blue",
        },
      };
    } else {
      <Chip
        size="small"
        label={index["value"]}
        style={{ width: "130px", textTransform: "capitalize" }}
      />;
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "S.No",
      filterable: false,
      width: 80,
      renderCell: (index) =>
        pageSize !== 0 ? parseInt(
          index.api.getRowIndex(index.row.id) +
          1 +
          (parseInt(pageSize * 10) - 10)
        ) : parseInt(
          index.api.getRowIndex(index.row.id) +
          1 +
          (parseInt(pageSize * 10))
        ),
    },
    {
      field: "Action",
      headerName: "Actions",
      sortable: false,
      width: 220,
      disableClickEventBubbling: false,
      disableColumnMenu: true,
      renderCell: (index, row) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {index["row"]["submission_status"] === "Approved" ? (
              <Tooltip title="View">
                <RemoveRedEyeIcon
                  id={index}
                  style={{
                    marginRight: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setReadonly(true);
                    onHandleEdit(index);
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Edit">
                <Edit
                  id={index}
                  style={{
                    marginRight: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setReadonly(false);
                    onHandleEdit(index);
                  }}
                />
              </Tooltip>
            )}

            <Tooltip title="Replace Existing">
              <Replace
                id={index}
                style={{ marginTop: "10px", cursor: "pointer" }}
                onClick={() => {
                  index["row"]["submission_status"] === "Approved"
                    ? onHandleReplace(index, row, "Replace")
                    : setErr(true);
                  setMsg(
                    "Replace existing can be done only for Approved trials"
                  );
                  setTimeout(() => {
                    setErr(false);
                  }, 3000);
                }}
              />
            </Tooltip>

            <Tooltip title="Duplicate">
              <ContentCopyIcon
                id={index}
                style={{ marginLeft: "20px", cursor: "pointer" }}
                onClick={() => {
                  index["row"]["submission_status"] === "Approved"
                    ? onHandleReplace(index, row, "Duplicate")
                    : setErr(true);
                  setMsg("Only Approved trials can be Duplicated");
                  setTimeout(() => {
                    setErr(false);
                  }, 3000);
                }}
              />
            </Tooltip>
            <Tooltip title="external download">
              <DownloadIcon
                style={{ marginLeft: "20px", cursor: "pointer" }}
                onClick={() => {
                  setIsExternal(true)
                  printReport(index, row)
                }}
              />
            </Tooltip>

            {roleID.role_id !== 3 ? (
              <Tooltip title="internal download">
                <DownloadForOfflineRoundedIcon
                  style={{ marginLeft: "20px", cursor: "pointer" }}
                  onClick={() => {
                    setIsExternal(false)
                    printReport(index, row)
                  }}
                />
              </Tooltip>
            ) : null}
          </div>
        );
      },
    },
    {
      field: "trial_number",
      headerName: "Trial No",
      width: 180,
      editable: false,
    },
    {
      field: "tdate",
      headerName: "Trial Date",
      width: 150,
      editable: false,
      renderCell: (params) => {
        const { trial_date } = params.row;
        const fullName = `${trial_date ? moment(trial_date).format("YYYY-MM-DD") : ""}`;
        return <span>{fullName}</span>;
      }
    },

    {
     
      field: "enquiry_number",
      headerName: "Enquiry Number",
      width: 210,
      editable: false,
      hide: currUser.role_id === 3,
    },

    {
      field: "customer_name",
      headerName: "Customer Name",
      width: 210,
      editable: false,
    },

    {
      field: "submittedname",
      headerName: "Submitted By",
      width: 190,
      editable: false,
     
    },
    {
      field: "approvername",
      headerName: "Approved By",
      width: 190,
      editable: false,
    },
    {
      field: "submittedon",
      headerName: "Submitted On",
      width: 150,
      editable: false,
    },

    {
      field: "submission_status",
      headerName: "Status",
      width: 200,
      editable: false,
      renderCell: (status) => {
        return <Chip variant="outlined" {...getStatus(userID, status)} />;
      },
    },
  ];

  const getSelectedVisitData = (data) => {
    setIsLoading(true);
    let userData = JSON.parse(userDet);
    let url = ApiURL + "get_trial_list";
    axios
      .post(url, {
        page: tablePage.page,
        limit: tablePage.pageSize,
        ZoneId: userData.zone_id,
        UserId: userData.role === "user" ? userData.id : "",
        searchValue: data === "empty" ? "" : searchTerm
      })
      .then((res) => {
        if (res.data.data.length > 0) {
          // Remove duplicates based on trial_number
          const uniqueData = res.data.data.reduce((acc, current) => {
            const x = acc.find(item => item.trial_number === current.trial_number);
            if (!x) {
              acc.push(current);
            }
            return acc;
          }, []);
  
          console.log("Filtered Unique Data", uniqueData);
          setVisitResData(uniqueData);
          setFilterCount(res.data.searchCount);
          setIsLoading(false);
        } else {
          setVisitResData([]);
          setTotal(0);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  };
  

  const getOverSavedTrialCount = () => {
    let userData = JSON.parse(userDet);
    let url = ApiURL + 'get_savedTrial_Count';
    let params = {
      ZoneId: userData.zone_id,
      UserId: userData.role === "user" ? userData.id : ""
    };
    axios.get(url, {
      params: params
    }).then((res) => {
      if (res.data) {
        setTotalCount(res.data.count);
      }
    }).catch(error => {
      console.error('Error fetching saved trial count:', error);
    });
  }

  useEffect(() => {
    getSelectedVisitData(0);
    getOverSavedTrialCount();
  }, [tablePage.page, tablePage.pageSize]);


  const getEditDetails = (id) => {
    let url = ApiURL + "getTrialDetails/" + id;
    axios
      .get(url)
      .then((res) => {
        if (res.data.length > 0) {
          setSelectVisitData(res.data[0]);
          console.log("dadadada", res.data[0]);
          const trialVisit = getTrialVisitNo();
          localStorage.setItem("TrialVisitID", trialVisit ? trialVisit : 0);
          navigate("/NewVisit");

        } else {
          setSelectVisitData([]);

        }
      });
    // configParam
    //   .RUN_GQL_API(gqlQueries.GET_SELECTED_VISIT_EDIT_DATA, {
    //     id: id,
    //   })
    //   .then((data) => {
    //     setSelectVisitData(data.chryso_trials[0]);
    //     const trialVisit = getTrialVisitNo();
    //     localStorage.setItem("TrialVisitID", trialVisit ? trialVisit : 0);
    //     navigate("/NewVisit");
    //   });
  };
  const onHandleEdit = (id) => {
    console.log("EditCheckidid", id)
    setVisitType("2");
    setValue(0);
    localStorage.setItem("SavedTrialId", id["row"]["id"]);
    localStorage.setItem("DbVisitID", id["row"]["id"]);
    if (
      userID === 3 &&
      (id.row.submission_status === "" ||
        id.row.submission_status === "Awaiting Approval" ||
        id.row.submission_status === "pending" ||
        id.row.submission_status === "ReSubmit")
    ) {
      setErr(true);
      setMsg("Awaiting approval trials are not accessible");
      setTimeout(() => {
        setErr(false);
      }, 4000);

      return true;
    } else if (
      (userID === 2 || userID === 1) &&
      id.row.submission_status === "Rejected"
    ) {
      setErr(true);
      setMsg("Awaiting approval trials are not accessible");
      setTimeout(() => {
        setErr(false);
      }, 4000);
      return true;
    } else {
      getEditDetails(id["row"]["id"]);
    }
  };
  const onHandleReplace = (id, row, type) => {
    localStorage.setItem("rePlaceID", JSON.stringify(id.row));
    setReplace(1);
    setValue(0);
    setReplaceType(type);
    setOpen(true);
  };

  const confirmToReplace = () => {
    setVisitType("1");
    let id = JSON.parse(localStorage.getItem("rePlaceID"));
    if (replaceType && replaceType === "Replace") {
      let url = ApiURL + "softDelTrial/" + id.id;
      axios
        .get(url)
        .then((res) => {
          if (res.data.length > 0) {
            setSelectVisitData(res.data[0]);
            const trialVisit = getTrialVisitNo();
            localStorage.setItem("TrialVisitID", trialVisit ? trialVisit : 0);

          } else {
            setSelectVisitData([]);

          }
        });
    }
    const currentYear = new Date();
    let trailsId =
      replaceType && replaceType === "Replace"
        ? id.trial_number
        : currUser.name.charAt(0) +
        currentYear.getFullYear() +
        "-" +
        parseInt(currentYear / 1000);
    localStorage.setItem("trialNo", trailsId ? trailsId : "");
    SetTrialId(trailsId);
    if (id.submission_status === "Approved") {
      setVisitType("1");
      let url = ApiURL + "getTrialDetails/" + id.id;
      axios
        .get(url)
        .then((res) => {
          if (res.data.length > 0) {
            let value = res.data[0];
            value["trialno"] = trailsId;
            if (replaceType === 'Duplicate') {
              value["trial_date"] = new Date();
            }
            setSelectVisitData(value);
            const trialVisit = getVisitNo();
            localStorage.setItem("VisitID", trialVisit ? trialVisit : 0);
            navigate("/NewVisit");

          } else {
            setSelectVisitData([]);

          }
        });
    } else {
      setErr(true);
      setMsg("Approved trails only can be Dublicated");
      setTimeout(() => {
        setErr(false);
      }, 4000);
      return true;
    }
  };

  useEffect(() => {
    if (selectVisitData && Object.keys(selectVisitData).length > 0) {
      let tempConvertedJSON = convertedJSON;

      setTrDet(tempConvertedJSON.TrialDetails);
      setclientTrDet(tempConvertedJSON.ClientDetails);
      setcongreteDet(tempConvertedJSON.ConcreteDetails);
      setSupInfo(tempConvertedJSON.supInfo);
      setAdmixture(tempConvertedJSON.Admixture);
      setTrialRes(tempConvertedJSON.TrialResult);
      setComments(tempConvertedJSON.comments);
      setSelectedVisitDatas(selectVisitData);
    }
  }, [selectVisitData]);


  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="skeleton-dashboard" style={{ width: "100%" }}>
          <Grid
            container
            style={{ justifyContent: "space-between", marginTop: "20px" }}
          >
            <Skeleton
              style={{ width: "100%", height: 400, transform: "none" }}
            />
          </Grid>
        </div>
      ) : (
        <React.Fragment>
          {err && (
            <Alert
              style={{ width: "100%" }}
              variant="filled"
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setErr(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {msg}
            </Alert>
          )}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {replaceType === "Replace"
                ? "Replace" + " Existing ?"
                : replaceType === "Duplicate"
                  ? "Duplicate"
                  : null}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure, you want to{" "}
                {replaceType === "Replace"
                  ? "Replace" + " Existing ?"
                  : replaceType === "Duplicate"
                    ? "Duplicate"
                    : null}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>No</Button>
              <Button onClick={confirmToReplace} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <div style={{ marginBottom: 5, marginLeft: "auto", textAlign: "right" }}>
            <SearchInput placeholder="Search..." value={searchTerm} clearInputField={clearInputField} onChange={handleChange} onKeyPress={(event) => handleKeyPress(event, getSelectedVisitData)} />
          </div>
          <Box sx={{ height: 730, width: "100%" }}>
            <DataGrid
              loading={isLoading}
              rows={visitResData && visitResData.length > 0 ? visitResData : []}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: false,
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                },
                hideAllButton: {
                  disabled: true,
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableExportButton={true}
              page={tablePage.page - 1}
              pageSize={tablePage.pageSize}
              rowsPerPageOptions={[10, 20, 30]}
              rowCount={
                searchTerm.length
                  ? filterCount : totalcount
              }

              paginationMode="server"
              onPageChange={(newPage) => setTablePage({ ...tablePage, page: newPage + 1 })}
              onPageSizeChange={(newPageSize) => setTablePage({ ...tablePage, pageSize: newPageSize })}

            />

          </Box>
          {previewForm ? (
            <React.Fragment>
              <div id="cust-print" style={{ display: "none", marginTop: 20 }}>
                <div
                  ref={printRef}
                  style={{
                    margin: "10px",
                    width: "1150px",
                  }}
                >
                  <PreviewForm data={printData} />
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default SavedVisit;
