import React, { useEffect, useRef, useState } from 'react';
import { Slider, TextField, Box, Typography } from '@mui/material';
import MuiInput from "@mui/material/Input";
import { styled } from "@mui/material/styles";
import { useRecoilState } from "recoil";
import {
    RangeFilterData, rangeFilterValue
} from "../../recoil/atom";
import moment from "moment";
import axios from "axios";
import configParam from "../../Config";
const ApiURL = configParam.API_URL;
const RangeFilter = (props) => {
    // console.log(props, "props")
    const minRef = useRef();
    const maxRef = useRef();
    const [filter, setFilter] = useRecoilState(RangeFilterData);
    const [inputminError, setInputminError] = useState(false);
    const [inputmaxError, setInputmaxError] = useState(false);
    const [rangeFilter, setRangeFilter] = useRecoilState(rangeFilterValue);
    const [errorMsg, setErrorMsg] = useState("");

    const minDistance = 10;
    const Input = styled(MuiInput)`
    width: 75px;
    `;
    const [value1, setValue1] = React.useState([0, 0]);
    const [value, setValue] = React.useState([0, 0]);
    const [error, setError] = React.useState("");
    const filterData = JSON.parse(JSON.stringify(filter));
    useEffect(() => {
        minRef.current.value = props.minRef;
        maxRef.current.value = props.maxRef;
        props.handleFilter(props.value, minRef.current.value, maxRef.current.value);
    }, []);

    const marks = [
        {
            value: 0,
            label: '0',
        },
        {
            value: 1,
            label: '1',
        },
        {
            value: 2,
            label: '2',
        },
        {
            value: 3,
            label: '3',
        },
    ];
    function valuetext(value) {
        return `${value}°C`;
    }
    const handleChange1 = (event, newValue, activeThumb) => {
        if (event) {
            if (!Array.isArray(newValue)) {
                return;
            }
            setRange(props.value, Number(Math.round(Math.min(newValue[0], value1[1] - minDistance) / props.range)), Number(Math.round(Math.max(newValue[1], value1[0] + minDistance) / props.range)));
            if (activeThumb === 0) {
                setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
            } else {
                setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
            }
            setTimeout(() => {
                minRef.current.value = Number(Math.round(Math.min(newValue[0], value1[1] - minDistance) / props.range))
                maxRef.current.value = Number(Math.round(Math.max(newValue[1], value1[0] + minDistance) / props.range))
                props.handleFilter(props.value, Number(Math.round(Math.min(newValue[0], value1[1] - minDistance) / props.range)), Number(Math.round(Math.max(newValue[1], value1[0] + minDistance) / props.range)));
            }, 50)
        }


    };
    const setRange = (value, minValue, maxValue) => {

        let CheckBoxOption = JSON.parse(JSON.stringify(filterData));
        minRef.current.value = minValue;
        maxRef.current.value = maxValue;
        // console.log("filterData dffd", filterData);
        // eslint-disable-next-line default-case
        switch (value) {
            case "Cement Content ( Kg)":
                CheckBoxOption["CementContent"]["min"] = minValue;
                CheckBoxOption["CementContent"]["max"] = maxValue;
                break;
            case "Flyash Content (Kg)":
                CheckBoxOption["FlyashContent"]["min"] = minValue;
                CheckBoxOption["FlyashContent"]["max"] = maxValue;
                break;
            case "GGBFS Content (Kg)":
                CheckBoxOption["GGBFSContent"]["min"] = minValue;
                CheckBoxOption["GGBFSContent"]["max"] = maxValue;
                break;
            case "Microsilica  Content (Kg)":
                CheckBoxOption["MicrosilicaContent"]["min"] = minValue;
                CheckBoxOption["MicrosilicaContent"]["max"] = maxValue;
                break;
            case "W/CM (%)":
                CheckBoxOption["WCM"]["min"] = minValue;
                CheckBoxOption["WCM"]["max"] = maxValue;
                break;
            case "Alkali content in cement(%)":
                CheckBoxOption["AlkaliContent"]["min"] = minValue;
                CheckBoxOption["AlkaliContent"]["max"] = maxValue;
                break;
            case "Sand Passing % in 600 µ":
                CheckBoxOption["SandPassing600"]["min"] = minValue;
                CheckBoxOption["SandPassing600"]["max"] = maxValue;
                break;
            case "Sand Passing % in 150 µ":
                CheckBoxOption["SandPassing150"]["min"] = minValue;
                CheckBoxOption["SandPassing150"]["max"] = maxValue;
                break;
            case "Sand Category":
                CheckBoxOption["SandCategory"]["min"] = minValue;
                CheckBoxOption["SandCategory"]["max"] = maxValue;
                break;
            case "Clear Test 10":
                CheckBoxOption["ClearTest10"]["min"] = minValue;
                CheckBoxOption["ClearTest10"]["max"] = maxValue;
                break;
            case "Clear Test 50":
                CheckBoxOption["ClearTest50"]["min"] = minValue;
                CheckBoxOption["ClearTest50"]["max"] = maxValue;
                break;
            case "Coarse Aggregate 150 micron passing 20 mm":
                CheckBoxOption["Coarse_Aggregate_150_micron_passing_20_mm"]["min"] = minValue;
                CheckBoxOption["Coarse_Aggregate_150_micron_passing_20_mm"]["max"] = maxValue;
                break;
            case "Coarse Aggregate 150 micron passing 10 mm":
                CheckBoxOption["Coarse_Aggregate_150_micron_passing_10_mm"]["min"] = minValue;
                CheckBoxOption["Coarse_Aggregate_150_micron_passing_10_mm"]["max"] = maxValue;
                break;
            case "Ambient Temperature (°C)":
                CheckBoxOption["Ambient_Temperature"]["min"] = minValue;
                CheckBoxOption["Ambient_Temperature"]["max"] = maxValue;
                break;
            case "Ambient Humidity (%)":
                CheckBoxOption["Ambient_Humidity"]["min"] = minValue;
                CheckBoxOption["Ambient_Humidity"]["max"] = maxValue;
                break;
        }
        setFilter(CheckBoxOption);
        setRangeFilter(CheckBoxOption);
    }
    useEffect(() => {
    }, [value1]);

    // const handleBlur = (e) => {
    //     if (e) {
    //         let val = [(minRef.current.value * props.range), (maxRef.current.value * props.range)]
    //         setTimeout(() => {
    //             minRef.current.value = Number(Math.round(val[0] / props.range))
    //             maxRef.current.value = Number(Math.round(val[1] / props.range))
    //             props.handleFilter(props.value, Number(Math.round(val[0] / props.range)), Number(Math.round(val[1] / props.range)));
    //         }, 500)

    //         setValue1(val);

    //     }
    // };


    const minmaxOnchange = (e) => {
        if (e.target.name === "min") {
            if (e.target.value < props.min) {
                setInputminError(true);
                setErrorMsg("cannot exceed minimum value")
            }
            else {
                setRange(props.value, Number(minRef.current.value), Number(maxRef.current.value));
                setInputminError(false);
                setErrorMsg("")
            }
        }
        if (e.target.name === "max") {
            if (e.target.value > props.max) {
                setInputmaxError(true);
                setErrorMsg("cannot exceed maximun value")
            }
            else {
                setRange(props.value, Number(minRef.current.value), Number(maxRef.current.value));
                setInputmaxError(false);
                setErrorMsg("")
            }
        }

    }


    const valueLabelFormat = (value, step) => {
        return Number(Math.round(value / props.range));
    }
    return (
        <Box >

            {/* <Slider
                getAriaLabel={() => "Minimum distance"}
                value={value1}
                onChange={handleChange1}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                valueLabelFormat={valueLabelFormat}
                marks={true}
                step={props.range}
                disableSwap
                name={props.value}
                max={props.max ? props.max : 100}
                min={props.min ? props.min : 0}


            /> */}

            <div style={{ display: "flex", width: '100%' }}>
                <div style={{}}>
                    <Typography>Min {"(" + props.minRef + ")"}</Typography>
                    <TextField
                        inputRef={minRef}
                        id="min"
                        size="small"
                        name="min"
                        // label="min"
                        // onBlur={handleBlur}
                        onChange={(e) => minmaxOnchange(e)}
                        sx={{
                            'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                                WebkitAppearance: 'none',
                                margin: 0
                            },
                            'input[type=number]': {
                                MozAppearance: 'textfield'
                            }
                        }}
                        // inputProps={{
                        //     min: 0,
                        //     max: 100,
                        //     type: "number",
                        //     "aria-labelledby": "input-slider",
                        // }}
                        error={inputminError ? true : false}
                    />
                </div>
                <div style={{ paddingLeft: 10 }}>
                    <Typography>Max {"(" + props.maxRef + ")"}</Typography>
                    <TextField
                        inputRef={maxRef}
                        id="max"
                        size="small"
                        name="max"
                        // label="max"
                        // onBlur={handleBlur}
                        onChange={(e) => minmaxOnchange(e)}
                        sx={{
                            'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                                WebkitAppearance: 'none',
                                margin: 0
                            },
                            'input[type=number]': {
                                MozAppearance: 'textfield'
                            }
                        }}
                        // inputProps={{
                        //     min: 0,
                        //     max: 800,
                        //     type: "number",
                        //     "aria-labelledby": "input-slider",

                        // }}
                        error={inputmaxError ? true : false}

                    />
                </div>
            </div>
            <p style={{ color: "red" }}>{errorMsg ? errorMsg : ""}</p>
        </Box>
    );
}
const isRender = (prev, next) => {
    return prev.value !== next.value ||
        prev.maxRef !== next.maxRef ||
        prev.minRef !== next.minRef
        ? false
        : true;
};
export default React.memo(RangeFilter, isRender);

