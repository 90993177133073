import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FormControl, Typography } from '@mui/material';
import { useRecoilState } from "recoil";
import {
  visitType
} from "../recoil/atom";

export default function Selectautocomplete(props) {
  const [selectedValue, setSelectedValues] = useState({});
  const [visitTypes] = useRecoilState(visitType);
  useEffect(() => {
    if (!props.value) {
      setSelectedValues([]);
    } else {
      if (visitTypes === '2' && props.value && !props.value.id) {
        const SelectedValues = props.myOptions.filter((data) => data[props.datakey] === props.value);
        setSelectedValues(SelectedValues[0] ? SelectedValues[0] : {});
      } else {
        if (!props.value.id) {
          let SelectedValues;
          if (typeof props.value === "string") {
            SelectedValues = props.myOptions.filter((data) => data[props.datakey] === props.value);
            setSelectedValues(SelectedValues[0] ? SelectedValues[0] : {});
          } else {
            setSelectedValues(props.value);
          }
        } else {
          let SelectedValues;
          if (typeof props.value === "string") {
            SelectedValues = props.myOptions.filter((data) => data[props.datakey] === props.value);
            setSelectedValues(SelectedValues[0] ? SelectedValues[0] : {});
          } else {
            setSelectedValues(props.value);
          }
        }

      }
    }

  }, [props]);

  return (
    <FormControl style={{ width: "100%" }}>
      <Typography style={{ fontSize: "12px", marginBottom: "5px", fontWeight: 600, lineHeight: 1.75 }}>{props.label}</Typography>
      <Autocomplete
        label="Laboratory Type *"
        disablePortal
        name={props.name}
        id="combo-box-demo"
        options={props.myOptions}
        value={selectedValue}
        size='small'
        getOptionLabel={(option) => option[props.dataValue] ? option[props.dataValue] : ''}
        // getOptionSelected={(option, SelectedValues) => {
        //   return option[props.datakey] === SelectedValues;
        // }}
        onChange={props.onChange}
        renderInput={(params) => props.mandatory ? <TextField {...params} variant="outlined" /> : <TextField {...params} variant="outlined" error={props.error}
        />
        }
      />

    </FormControl>

  );
}


